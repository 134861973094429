import React, { useEffect, useState, useRef } from 'react';
import { Platform, StatusBar } from 'react-native';
import { StackActions, useNavigation } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { createStackNavigator } from '@react-navigation/stack';
import { useTheme, Spinner, Center } from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import { FontAwesome5, FontAwesome } from '@expo/vector-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications';
import * as TaskManager from 'expo-task-manager';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import * as Network from 'expo-network';
import * as Updates from 'expo-updates';
import Constants from 'expo-constants';

import Home from './Home.js';
import Settings from './Settings.js';
import SignUpScreen from './SignUpScreen.js';
import WelcomeScreen from './WelcomeScreen.js';
import SignInScreen from './SignInScreen.js';
import RevealScreen from './RevealScreen.js';
import HighScoresScreen from './HighScoresScreen.js';
import AuthorScreen from './AuthorScreen.js';
import ForgotPassword from './ForgotPassword.js';
import NoInternetScreen from './NoInternetScreen.js';
import { setAvatarKey, setUserToken } from './redux/reducers/AuthState';
import { setShareState } from './redux/reducers/ShareState';
import { setGameFontState } from './redux/reducers/GameFontState.js';
import { setRevealConfirmState } from './redux/reducers/RevealConfirmState';
import { setInterestState } from './redux/reducers/InterestState';
import { setScoreState } from './redux/reducers/ScoreState';
import { setBadgeState } from './redux/reducers/BadgeState.js';
import ProfileLink from './ProfileLink.js';
import ProfileScreen from './ProfileScreen.js';
import AvatarSelection from './AvatarSelectionScreen.js';
import QuoteScreen from './QuoteScreen.js';
import SearchScreen from './SearchScreen.js';
import SearchLink from './SearchLink.js';
import TweakGameStandalone from './common/TweakGameStandalone.js';
import {
  subscribeToScore,
  updateUserCategoryInterest,
  setBadgeFromLastSeenState,
  updateUserNxnStatus,
  updateNxnToken,
  updateLastUsedTimestamp,
  clearLastSeenNxn,
} from './db/Query';
import { setPopularityState } from './redux/reducers/PopularityState.js';
import {
  setAllState,
  setQod,
  setTcLive,
  setTcUp,
} from './redux/reducers/NxnState';
import { auth } from './firebase';
import { sendAnalyticsEventAsync } from './analytics';

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: true,
  }),
});

const FIREBASE_UID_KEY = 'firebase_uid_key';
const BACKGROUND_NOTIFICATION_TASK = 'BACKGROUND-NOTIFICATION-TASK';
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();

function GameStack() {
  return (
    <Stack.Navigator
      initialRouteName="Games"
      screenOptions={{
        headerStyle: {
          backgroundColor: '#818cf8', // indigo.500
          height: 60,
        },
        headerStatusBarHeight: 0,
        headerBackTitleVisible: false,
        headerTintColor: '#fff',
        headerRight: () => <ProfileLink />,
      }}
    >
      <Stack.Screen
        name="Games"
        component={Home}
        options={{
          title: '',
          headerLeft: () => <SearchLink />,
        }}
      />
      <Stack.Screen
        name="Reveal"
        component={RevealScreen}
        options={({ route }) => ({
          title: route.params.screenTitle,
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Profile',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="AvatarSelection"
        component={AvatarSelection}
        options={{
          headerRight: () => <ProfileLink />,
          title: 'Select Avatar',
          headerTitleAlign: 'left',
        }}
      />
      <Stack.Screen
        name="AuthorScreen"
        component={AuthorScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Author',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={({ route }) => ({
          title: 'Search Authors',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="TweakGameStandalone"
        component={TweakGameStandalone}
        options={({ route }) => ({
          title: 'Tweak this',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
    </Stack.Navigator>
  );
}

function QuoteModeStack() {
  return (
    <Stack.Navigator
      initialRouteName="Quote"
      screenOptions={{
        headerStyle: {
          backgroundColor: '#818cf8', // indigo.500
          height: 60,
        },
        headerStatusBarHeight: 0,
        headerBackTitleVisible: false,
        headerTintColor: '#fff',
        headerRight: () => <ProfileLink />,
      }}
    >
      <Stack.Screen
        name="Quote"
        component={QuoteScreen}
        options={{
          title: '',
          headerLeft: () => <SearchLink />,
        }}
      />
      <Stack.Screen
        name="Reveal"
        component={RevealScreen}
        options={({ route }) => ({
          title: route.params.screenTitle,
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Profile',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="AvatarSelection"
        component={AvatarSelection}
        options={{
          headerRight: () => <ProfileLink />,
          title: 'Select Avatar',
          headerTitleAlign: 'left',
        }}
      />
      <Stack.Screen
        name="AuthorScreen"
        component={AuthorScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Author',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={({ route }) => ({
          title: 'Search Authors',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="TweakGameStandalone"
        component={TweakGameStandalone}
        options={({ route }) => ({
          title: 'Tweak this',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
    </Stack.Navigator>
  );
}

function ProfileStack() {
  return (
    <Stack.Navigator
      initialRouteName="Profile"
      screenOptions={{
        headerStyle: {
          backgroundColor: '#6366f1', // indigo.500
        },
        headerBackTitleVisible: false,
        headerTintColor: '#fff',
      }}
    >
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: 'Profile',
          headerTitleAlign: 'left',
          headerLeft: () => <SearchLink />,
        }}
      />
      <Stack.Screen
        name="Reveal"
        component={RevealScreen}
        options={({ route }) => ({
          title: route.params.screenTitle,
          headerTitleAlign: 'left',
        })}
      />
      <Stack.Screen
        name="AvatarSelection"
        component={AvatarSelection}
        options={{
          title: 'Select Avatar',
          headerTitleAlign: 'left',
          headerRight: () => <ProfileLink />,
        }}
      />
      <Stack.Screen
        name="AuthorScreen"
        component={AuthorScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Author',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={({ route }) => ({
          title: 'Search Authors',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="TweakGameStandalone"
        component={TweakGameStandalone}
        options={({ route }) => ({
          title: 'Tweak this',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
    </Stack.Navigator>
  );
}

function LeaderStack() {
  return (
    <Stack.Navigator
      initialRouteName="HighScores"
      screenOptions={{
        headerStyle: {
          backgroundColor: '#6366f1', // indigo.500
        },
        headerBackTitleVisible: false,
        headerTintColor: '#fff',
        headerRight: () => <ProfileLink />,
      }}
    >
      <Stack.Screen
        name="HighScores"
        component={HighScoresScreen}
        options={{
          title: 'Leaderboard',
          headerTitleAlign: 'left',
          headerLeft: () => <SearchLink />,
        }}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Profile',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="Reveal"
        component={RevealScreen}
        options={({ route }) => ({
          title: route.params.screenTitle,
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="AuthorScreen"
        component={AuthorScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Author',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={({ route }) => ({
          title: 'Search Authors',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="TweakGameStandalone"
        component={TweakGameStandalone}
        options={({ route }) => ({
          title: 'Tweak this',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
    </Stack.Navigator>
  );
}

function SettingsStack() {
  return (
    <Stack.Navigator
      initialRouteName="Settings"
      screenOptions={{
        headerStyle: {
          backgroundColor: '#6366f1', // indigo.500
        },
        headerBackTitleVisible: false,
        headerTintColor: '#fff',
        headerRight: () => <ProfileLink />,
      }}
    >
      <Stack.Screen
        name="Settings"
        component={Settings}
        options={{
          title: 'Settings',
          headerTitleAlign: 'left',
          headerLeft: () => <SearchLink />,
        }}
      />
      <Stack.Screen
        name="Profile"
        component={ProfileScreen}
        options={{
          title: 'Profile',
          headerTitleAlign: 'left',
          headerRight: () => null,
        }}
      />
      <Stack.Screen
        name="AvatarSelection"
        component={AvatarSelection}
        options={{
          title: 'Select Avatar',
          headerTitleAlign: 'left',
          headerRight: () => <ProfileLink />,
        }}
      />
      <Stack.Screen
        name="Reveal"
        component={RevealScreen}
        options={({ route }) => ({
          title: route.params.screenTitle,
          headerTitleAlign: 'left',
        })}
      />
      <Stack.Screen
        name="AuthorScreen"
        component={AuthorScreen}
        options={({ route }) => ({
          title: route.params.screenTitle || 'Author',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="SearchScreen"
        component={SearchScreen}
        options={({ route }) => ({
          title: 'Search Authors',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
      <Stack.Screen
        name="Sign Up"
        component={SignUpScreen}
        options={{
          headerRight: () => null,
          title: 'Sign Up',
          headerTitleAlign: 'left',
        }}
      />
      <Stack.Screen
        name="Sign In"
        component={SignInScreen}
        options={{
          headerRight: () => null,
          title: 'Sign In',
          headerTitleAlign: 'left',
        }}
      />
      <Stack.Screen
        name="Forgot"
        component={ForgotPassword}
        options={{
          headerRight: () => null,
          title: 'Forgot password',
          headerTitleAlign: 'left',
        }}
      />
      <Stack.Screen
        name="TweakGameStandalone"
        component={TweakGameStandalone}
        options={({ route }) => ({
          title: 'Tweak this',
          headerTitleAlign: 'left',
          headerRight: () => null,
        })}
      />
    </Stack.Navigator>
  );
}

function MyTabs() {
  const Tab = createMaterialTopTabNavigator();
  const dispatch = useDispatch();

  return (
    <Tab.Navigator
      screenOptions={{
        tabBarLabelStyle: { fontSize: 16 },
        tabBarStyle: { backgroundColor: '#6366f1' },
        tabBarInactiveTintColor: 'black',
        tabBarActiveTintColor: 'white',
        tabBarIndicatorStyle: { backgroundColor: 'white' },
      }}
      style={{
        paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 10,
        backgroundColor: '#6366f1',
      }}
    >
      <Tab.Screen name="Quotes" component={QuoteModeStack} />
      <Tab.Screen name="Game" component={GameStack} />
    </Tab.Navigator>
  );
}

export default function RootScreen(props) {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const badgeState = useSelector((state) => state.badgeState.value);
  const userFromState = useSelector((state) => state.authState.userToken);

  const navigation = useNavigation();

  const [expoPushToken, setExpoPushToken] = useState('');
  const [notification, setNotification] = useState({});
  const [nxnOpened, setNxnOpened] = useState(false);
  const notificationListener = useRef();
  const responseListener = useRef();
  const [online, setOnline] = useState(false);
  const [haveCheckedNetwork, setHaveCheckedNetwork] = useState(false);
  const [firebaseAuthChecked, setFirebaseAuthChecked] = useState(false);
  const [nxnTokenUpdated, setNxnTokenUpdated] = useState(false);
  const [lutsUpdated, setLutsUpdated] = useState(false);

  const user = useSelector((state) => state.authState.userToken);
  const { qod, tclive, tcup } = useSelector((state) => state.nxnState);

  useEffect(() => {
    actOnNxn();
  }, [nxnOpened]);
  useEffect(() => {
    actOnNxn();
  }, [notification]);

  const actOnNxn = () => {
    if (nxnOpened && notification) {
      setNxnOpened(false);
      const { type, param } = notification.request.content.data;
      sendAnalyticsEventAsync('nxn_opened', { type: type });
      if (type === 'qod') {
        navigation.dispatch(
          StackActions.push('Reveal', {
            quoteID: param,
            partialData: true,
            screenTitle: 'Quote of the day',
          })
        );
      } else if (type === 'tweaklive') {
        navigation.dispatch(
          StackActions.push('Reveal', {
            quoteID: param,
            partialData: true,
            screenTitle: 'Your Tweak is live here!',
          })
        );
      }
      setNotification(null);
      if (user) {
        clearLastSeenNxn(user.fbUID);
      }
    }
  };

  useEffect(() => {
    console.log('ROOT: RUNNING MAIN useeffect ');
    onFetchUpdateAsync();
    checkAndSetNetwork();
    const firebaseAuthUnsubFun = auth.onAuthStateChanged(function (user) {
      // just that we have checked
      setFirebaseAuthChecked(true);
    });

    // localUser is stored locally and has the following json
    // { fbUID: firebaseUID, nickname: nickname, type: "anon"|"email"}
    const bootstrapAsync = async () => {
      //const user = JSON.parse(await AsyncStorage.getItem(FIREBASE_UID_KEY));
      const serialisedState = JSON.parse(
        await AsyncStorage.getItem('@goqState')
      );
      /*
      {
        "shareState":{"value":true},
        "authState":{
          "isLoading":true,"isSignout":false,
          "userToken":{"fbUID":"HzaMAmKMeMXMAJyIsRbWLBvo1iz2","nickname":"Nasir","type":"anon"}
        },
        "gameModeState":{"value":"GAME"},
        "revealConfirmState": {"value": "GAME"}
      }
      */
      // NOTE: Not using batch API because when we move to React 18 batching will be automatic
      // https://react-redux.js.org/api/batch
      if (serialisedState) {
        const user = serialisedState.authState.userToken;
        const avatarKey = serialisedState.authState.avatarKey || 'a0';
        const shareState = serialisedState.shareState;
        const revealConfirmState = serialisedState.revealConfirmState;
        const interestState = serialisedState.interestState;
        const popularityState = serialisedState.popularityState || 50;
        const nxnState = serialisedState.nxnState;
        const gameFontState = serialisedState.gameFontState;

        if (user && user.fbUID) {
          dispatch(setUserToken(user));
          dispatch(setAvatarKey(avatarKey));
        } else {
          // not signed in user
          dispatch(setUserToken({ fbUID: null }));
        }
        if (shareState && shareState.hasOwnProperty('value')) {
          dispatch(setShareState(shareState.value));
        }
        if (gameFontState && gameFontState.hasOwnProperty('value')) {
          dispatch(setGameFontState(gameFontState.value));
        }
        if (popularityState && popularityState.hasOwnProperty('value')) {
          dispatch(setPopularityState(popularityState.value));
        }

        if (revealConfirmState && revealConfirmState.hasOwnProperty('value')) {
          dispatch(setRevealConfirmState(revealConfirmState.value));
        }
        if (interestState) {
          dispatch(setInterestState(interestState.value));
          if (user && user.fbUID) {
            // this was a weird cache read only bug from Firestore
            //await updateUserCategoryInterest(user.fbUID, interestState.value);
          }
        }
        if (nxnState && user && user.fbUID) {
          dispatch(setAllState(nxnState.all));
          dispatch(setQod(nxnState.qod));
          dispatch(setTcLive(nxnState.tclive));
          dispatch(setTcUp(nxnState.tcup));
          if (auth.currentUser) updateUserNxnStatus(user.fbUID, nxnState);
        } else {
          dispatch(setAllState(true));
          dispatch(setQod(true));
          dispatch(setTcLive(true));
          dispatch(setTcUp(true));
        }
      } else {
        dispatch(setUserToken({ fbUID: null }));
        // no need to dispatch default share or game state because that comes from the reducers
      }
    };

    bootstrapAsync();

    if (Device.isDevice && Device.brand) {
      notificationListener.current =
        Notifications.addNotificationReceivedListener((notification) => {
          setNotification(notification);
          console.log('Notification Received');
        });

      responseListener.current =
        Notifications.addNotificationResponseReceivedListener((response) => {
          setNotification(response.notification);
          console.log('Notification interacted with');
          setNxnOpened(true);
        });

      TaskManager.defineTask(
        BACKGROUND_NOTIFICATION_TASK,
        ({ data, error, executionInfo }) => {
          if (data && data.notification) {
            setNotification(data.notification);
          }
        }
      );

      Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK);
    }
    return firebaseAuthUnsubFun;
  }, []);

  async function checkAndSetNetwork() {
    // {
    //   type: NetworkStateType.CELLULAR,
    //   isConnected: true,
    //   isInternetReachable: true,
    // }
    try {
      //console.log('************** ENTERING to await for NETWORK');
      const networkState = await Network.getNetworkStateAsync();
      //console.log('************** EXITING to await for NETWORK');
      setHaveCheckedNetwork(true);
      setOnline(networkState.isConnected);
      // console.log(
      //   'In Callback Have checked network is ',
      //   haveCheckedNetwork,
      //   ' and online is ',
      //   online
      // );
    } catch (error) {
      console.error(error);
    }
  }

  async function onFetchUpdateAsync() {
    try {
      const update = await Updates.checkForUpdateAsync();

      if (update.isAvailable) {
        await Updates.fetchUpdateAsync();
        await Updates.reloadAsync();
      }
    } catch (error) {
      // You can also add an alert() to see the error message in case of an error when fetching updates.
      console.error(`Error fetching latest Expo update: ${error}`);
    }
  }

  async function registerForPushNotificationsAsync() {
    let token = null;
    try {
      // isDevice is "web" for web, while brand is null. For now we are only going to have notifications on devices
      if (Device.isDevice && Device.brand) {
        if (Platform.OS === 'android') {
          await Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
          });
        }
        const { status: existingStatus, canAskAgain } =
          await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        console.log('Existing status of Nxn grant ', existingStatus);
        if (existingStatus !== 'granted' && canAskAgain) {
          try {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
          } catch (e) {
            console.error(e);
          }
        }
        if (finalStatus !== 'granted') {
          console.error('Failed to get push token for push notification!');
          return;
        }
        let response = await Notifications.getExpoPushTokenAsync({
          projectId: Constants.expoConfig.extra.eas.projectId,
        });
        token = response.data;
        console.log(token);
      } else {
        console.log('Must use physical device for Push Notifications');
      }
    } catch (error) {
      console.error(error);
    }
    return token;
  }

  useEffect(() => {
    if (userFromState && userFromState.fbUID) {
      setBadgeFromLastSeenState(userFromState.fbUID, (s) =>
        dispatch(setBadgeState(s))
      );
      return subscribeToScore(userFromState.fbUID, (doc) => {
        if (doc.exists) {
          dispatch(setScoreState(doc.data().score));
        }
      });
    } else {
      return;
    }
  }, [userFromState.fbUID]);

  useEffect(() => {
    const updateTokenAndLuts = async () => {
      if (firebaseAuthChecked) {
        if (user && user.fbUID && auth.currentUser) {
          if (qod || tclive || tcup) {
            registerForPushNotificationsAsync().then(async (token) => {
              console.log('now setting token and device name in db ', token);
              if (token) {
                setExpoPushToken(token);
                await updateNxnToken(
                  user.fbUID,
                  token,
                  Device.deviceName,
                  setNxnTokenUpdated
                );
              } else {
                await updateNxnToken(
                  user.fbUID,
                  null,
                  Device.deviceName,
                  setNxnTokenUpdated
                );
              }
            });
          } else {
            await updateNxnToken(
              user.fbUID,
              null,
              Device.deviceName,
              setNxnTokenUpdated
            );
          }
          await updateLastUsedTimestamp(user.fbUID, Date.now(), setLutsUpdated);
        } else {
          // no auth user just flag that these flags were updated to move forward
          setNxnTokenUpdated(true);
          setLutsUpdated(true);
        }
      }
    };
    updateTokenAndLuts();
  }, [firebaseAuthChecked, qod, tclive, tcup]);

  console.log('RUNNING ROOT AGAIN');
  // console.log(
  //   'Have checked network is ',
  //   haveCheckedNetwork,
  //   ' and online is ',
  //   online
  // );
  //if (haveCheckedNetwork && !online) {
  if (haveCheckedNetwork && !online) {
    return (
      <Stack.Navigator
        screenOptions={{
          headerStyle: {
            backgroundColor: '#6366f1', // indigo.500
          },
          headerBackTitleVisible: false,
          headerTintColor: '#fff',
        }}
      >
        <Stack.Screen name="No Network" component={NoInternetScreen} />
      </Stack.Navigator>
    );
  } else if (haveCheckedNetwork && online) {
    // If we did not wait until lust or nxnToken updates we were running into
    // a weird Firestore race condition where partial data was returned.
    if (!firebaseAuthChecked || !lutsUpdated || !nxnTokenUpdated) {
      return (
        <Center>
          <Spinner alignSelf="center" size="lg" />
        </Center>
      );
    }
    if (user && user.fbUID && auth.currentUser) {
      return (
        <Tab.Navigator
          initialRouteName="HomeStack"
          screenOptions={{
            tabBarActiveTintColor: colors.indigo['500'],
            tabBarInactiveTintColor: colors.gray['400'],
            tabBarLabelStyle: { fontSize: 12 },
            tabBarLabelPosition: 'below-icon',
          }}
        >
          <Tab.Screen
            name="HomeStack"
            component={MyTabs}
            options={{
              headerShown: false,
              tabBarLabel: 'Home',
              tabBarIcon: ({ color, size }) => (
                <FontAwesome5
                  name="home"
                  size={24}
                  color={color}
                  alignSelf="center"
                />
              ),
            }}
          />
          <Tab.Screen
            name="LeaderStack"
            component={LeaderStack}
            options={{
              headerShown: false,
              tabBarLabel: 'Leaderboard',
              tabBarIcon: ({ color, size }) => (
                <FontAwesome5
                  name="medal"
                  size={24}
                  color={color}
                  alignSelf="center"
                />
              ),
            }}
          />
          <Tab.Screen
            name="ProfileStack"
            component={ProfileStack}
            options={{
              headerShown: false,
              tabBarLabel: 'Profile',
              tabBarIcon: ({ color, size }) => (
                <FontAwesome5
                  name="user-alt"
                  size={24}
                  color={color}
                  alignSelf="center"
                />
              ),
              tabBarBadge: badgeState ? '1' : null,
              tabBarBadgeStyle: {
                top: Platform.OS === 'ios' ? 0 : 9,
                minWidth: 14,
                maxHeight: 14,
                borderRadius: 7,
                fontSize: 10,
                lineHeight: 13,
                alignSelf: undefined,
              },
            }}
          />
          <Tab.Screen
            name="SettingsStack"
            component={SettingsStack}
            options={{
              headerShown: false,
              tabBarLabel: 'Settings',
              tabBarIcon: ({ color, size }) => {
                return (
                  <FontAwesome
                    name="gear"
                    size={24}
                    color={color}
                    alignSelf="center"
                  />
                );
              },
            }}
          />
        </Tab.Navigator>
      );
    } else {
      return (
        <Stack.Navigator
          screenOptions={{
            headerStyle: {
              backgroundColor: '#6366f1', // indigo.500
            },
            headerBackTitleVisible: false,
            headerTintColor: '#fff',
          }}
        >
          <Stack.Screen name="Welcome" component={WelcomeScreen} />
          <Stack.Screen name="Sign Up" component={SignUpScreen} />
          <Stack.Screen name="Sign In" component={SignInScreen} />
          <Stack.Screen name="Forgot" component={ForgotPassword} />
        </Stack.Navigator>
      );
    }
  }
}

export { FIREBASE_UID_KEY };
