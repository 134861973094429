import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Toast,
  useTheme,
  Actionsheet,
  Icon,
  useToast,
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

import { onShare } from './ShareWidget';
import Quote from '../Quote';
import LinkWithPopupTip from '../LinkWithPopupTip';
import { addTweakForReview } from '../db/Query';
import { sendAnalyticsEventAsync } from '../analytics';

const QuoteAction = ({
  initQuote,
  resultComposite,
  setResultComposite,
  user,
  mode /* mode can be "game" or "tweakonly" */,
}) => {
  const [showASheet, setShowASheet] = useState(false);
  const [tweakSubmitted, setTweakSubmitted] = useState(false);
  const [forceReset, setForceReset] = useState(0);
  const { userQuote } = resultComposite;
  const toast = useToast();
  const avatarKey = useSelector((state) => state.authState.avatarKey);
  const { colors } = useTheme();

  useEffect(() => {
    const { showResult, showSuccessImage } = resultComposite;
    setTweakSubmitted(false);
    if (showResult && !showSuccessImage) {
      sendAnalyticsEventAsync('actionsheet_shown_on_wrong_guess');
      setShowASheet(true);
    } else {
      setShowASheet(false);
    }
  }, [resultComposite]);

  const clearResult = () => {
    setResultComposite({
      ...resultComposite,
      showSuccessImage: false,
      showResult: false,
      userQuote: '',
    });
  };

  const submitTweakFun = () => {
    addTweakForReview(
      initQuote.id,
      user.fbUID,
      user.nickname,
      avatarKey,
      userQuote
    );
    Toast.show({
      title: 'Submitted Tweak for review',
      status: 'success',
      duration: 4000,
      description:
        'We will review your submission and it may show up in fun tweaks',
      placement: 'top',
      style: {
        backgroundColor: colors.indigo['200'],
        zIndex: 2,
        elevation: 2,
      },
    });
    setTweakSubmitted(true);
    sendAnalyticsEventAsync('tweak_submitted_for_review');
  };

  return (
    <>
      <Quote
        initQuote={initQuote.data()}
        quoteId={initQuote.id}
        resultComposite={resultComposite}
        setResultComposite={setResultComposite}
        userid={user.fbUID}
        forceReset={forceReset}
        clearResult={clearResult}
        mode={mode}
      />
      <Actionsheet
        isOpen={showASheet}
        onClose={() => setShowASheet(false)}
        size="full"
      >
        <Actionsheet.Content bgColor="amber.400">
          {mode === 'game' && (
            <Box w="100%" h={60} px={4} justifyContent="center">
              <Text
                style={{ fontFamily: 'Lato_400Regular' }}
                fontWeight={500}
                color="indigo.700"
                bold
              >
                Not exactly! but you can..
              </Text>
            </Box>
          )}
          <Actionsheet.Item
            bgColor="amber.400"
            startIcon={
              <Icon as={<MaterialIcons name="share" />} color="black" mr={3} />
            }
            onPress={() => {
              onShare(
                {
                  message: `"${userQuote}" - \n\nSee more quotes at https://gameofquotes.app`,
                  url: 'gameofquotes.app',
                  title: 'Checkout my tweak',
                },
                { subject: 'Checkout my tweak' },
                toast
              );
              sendAnalyticsEventAsync('shared_tweak_from_actionsheet');
            }}
          >
            <Text
              style={{ fontFamily: 'Lato_400Regular' }}
              fontWeight={500}
              color="black"
              bold
            >
              Share
            </Text>
          </Actionsheet.Item>
          {!tweakSubmitted && (
            <Actionsheet.Item
              bgColor="amber.400"
              startIcon={
                <Icon as={<MaterialIcons name="send" />} color="black" mr={3} />
              }
              onPress={submitTweakFun}
            >
              <LinkWithPopupTip
                mainText="Submit Tweak"
                headerText="Submit Tweak"
                helpText="While this is not the exact quote, this could still be an interesting take. Submit your 'tweak' for review, if accepted it will show in tweaks!"
                underline={false}
                screenName=" "
                textColor="black"
                actionFun={() => {}}
              />
            </Actionsheet.Item>
          )}
          {mode === 'game' && (
            <Actionsheet.Item
              bgColor="amber.400"
              onPress={() => {
                setForceReset(forceReset + 1);
                clearResult();
                setShowASheet(false);
              }}
              startIcon={
                <Icon as={<MaterialIcons name="edit" />} color="black" mr={3} />
              }
            >
              <Text
                style={{ fontFamily: 'Lato_400Regular' }}
                fontWeight={500}
                color="black"
                bold
              >
                Try Again
              </Text>
            </Actionsheet.Item>
          )}
          <Actionsheet.Item
            bgColor="amber.400"
            onPress={() => setShowASheet(false)}
            startIcon={
              <Icon as={<MaterialIcons name="close" />} color="black" mr={3} />
            }
          >
            <Text
              style={{ fontFamily: 'Lato_400Regular' }}
              fontWeight={500}
              color="black"
              bold
            >
              Close
            </Text>
          </Actionsheet.Item>
        </Actionsheet.Content>
      </Actionsheet>
    </>
  );
};

export default QuoteAction;
