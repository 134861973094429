import { createSlice } from '@reduxjs/toolkit';

// Popularity State is a number from 0-100 that maps into
// popularity index of quotes.
// Max quote popularity:  0.15566615566615566  100 -> 0.15
// Min quote popularity:  0.00004000004000004  0 -> 00004000004000004

export const popularityStateSlice = createSlice({
  name: 'popularityState',
  initialState: {
    value: 50,
  },
  reducers: {
    setPopularityState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setPopularityState } = popularityStateSlice.actions;

export default popularityStateSlice.reducer;
