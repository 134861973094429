exports.a0 = require('./plain_man_avatar.png');
exports.a10 = require('./plain_woman_avatar.png');
exports.a1 = require('./boy_redhead_av.png');
exports.a7 = require('./boy_blackhead_av.png');
exports.a8 = require('./boy_blonde.png');
exports.a4 = require('./boy_redhead_av.png');
exports.a5 = require('./dark_man_beard.png');
exports.a6 = require('./girl_auburn.png');
exports.a2 = require('./girl_black.png');
exports.a3 = require('./girl_blonde.png');
exports.a9 = require('./girl_redhead.png');
exports.a11 = require('./happy_beard_guy.png');
exports.a12 = require('./happy_brown_haired_girl.png');
exports.a13 = require('./happy_black_girl.png');
exports.a14 = require('./happy_mustache_guy.png');
exports.a15 = require('./happy_blond_brown_guy.png');
exports.a16 = require('./happy_redhead_girl.png');
exports.a17 = require('./happy_blond_girl_wglasses.png');