const firebaseConfig = {
  apiKey: 'AIzaSyAiClCHxGA4YXPEtmKBnDnWHN55yLtBDFg',
  authDomain: 'gameofquotes-c5672.firebaseapp.com',
  projectId: 'gameofquotes-c5672',
  storageBucket: 'gameofquotes-c5672.appspot.com',
  messagingSenderId: '459635465612',
  appId: '1:459635465612:web:f6143f36a77171a7c24aa9',
  measurementId: 'G-T2HD2BNP8F',
};

export default firebaseConfig;
