import * as React from 'react';
import { useState } from 'react';
import {
  NativeBaseProvider,
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Text,
  KeyboardAvoidingView,
  ScrollView,
  Spinner,
} from 'native-base';
import { Platform } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonActions } from '@react-navigation/native';

import { setUserToken } from './redux/reducers/AuthState';
import {
  signUpIfNicknameAvailable,
  signUpAndLink,
  updateSignInStatus,
} from './db/Query';
import { FIREBASE_UID_KEY } from './RootScreen';

export default function SignUpScreen({ route, navigation }) {
  const { userExisted } = route.params;
  const user = useSelector((state) => state.authState.userToken);
  const interests = useSelector((state) => state.interestState.value);
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [nickName, setNickName] = useState(
    user && user.fbUID ? user.nickname : ''
  );
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  const [error, setError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const submitHandler = () => {
    setError(null);
    console.log(email, nickName, password, cpassword);
    if (!email || !password || !nickName) {
      setError('Some fields left blank, please check');
      return;
    }
    if (!validateEmail(email)) {
      setError('Email is not valid, please try again');
      return;
    }
    if (password != cpassword) {
      setError('Passwords do not match, please try again');
      return;
    }
    setShowSpinner(true);
    if (user.fbUID) {
      // link and upgrade
      signUpAndLink(email, password, nickName, callbackFun);
    } else {
      signUpIfNicknameAvailable(
        email,
        password,
        nickName,
        interests,
        callbackFun
      );
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const errorText = (err) => (
    <Text fontSize="sm" color="danger.800">
      {err}
    </Text>
  );

  const callbackFun = (result, user, errmsg) => {
    if (result === 0) {
      saveLocal(user);
      setError(null);
      dispatch(setUserToken(user));
      updateSignInStatus(user.fbUID, true);
      if (userExisted) {
        navigation.goBack();
        navigation.dispatch(
          CommonActions.navigate({
            name: 'Quotes',
          })
        );
      }
    } else {
      setError('Cannot create user. ' + errmsg);
    }
    setShowSpinner(false);
  };

  const saveLocal = async (user) => {
    try {
      await AsyncStorage.setItem(FIREBASE_UID_KEY, JSON.stringify(user));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NativeBaseProvider>
      {showSpinner && <Spinner size="lg" alignSelf="center" />}
      <KeyboardAvoidingView
        h={{
          base: '400px',
          lg: 'auto',
        }}
        flex={1}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
        keyboardVerticalOffset={Platform.OS === 'ios' ? 50 : 70}
      >
        <ScrollView>
          <Box safeArea flex={1} p="2" w="90%" mx="auto" py="8">
            <Heading size="lg" color="coolGray.800" fontWeight="600">
              Welcome to GOQ!
            </Heading>
            <Heading mt="1" color="coolGray.600" fontWeight="medium" size="xs">
              Sign up to save your stats
            </Heading>

            <VStack space={3} mt="5">
              <FormControl>
                <FormControl.Label
                  _text={{
                    color: 'muted.700',
                    fontSize: 'xs',
                    fontWeight: 500,
                  }}
                >
                  Email
                </FormControl.Label>
                <Input onChangeText={setEmail} value={email} />
              </FormControl>
              <FormControl>
                <FormControl.Label
                  _text={{
                    color: 'muted.700',
                    fontSize: 'xs',
                    fontWeight: 500,
                  }}
                >
                  {!!user.nickname ? 'Using your Nickname' : 'Pick a Nickname'}
                </FormControl.Label>
                <Input
                  value={nickName}
                  onChangeText={setNickName}
                  isDisabled={!!user.nickname}
                />
              </FormControl>
              <FormControl>
                <FormControl.Label
                  _text={{
                    color: 'muted.700',
                    fontSize: 'xs',
                    fontWeight: 500,
                  }}
                >
                  Password
                </FormControl.Label>
                <Input
                  type="password"
                  value={password}
                  onChangeText={setPassword}
                />
              </FormControl>
              <FormControl>
                <FormControl.Label
                  _text={{
                    color: 'muted.700',
                    fontSize: 'xs',
                    fontWeight: 500,
                  }}
                >
                  Confirm Password
                </FormControl.Label>
                <Input
                  type="password"
                  value={cpassword}
                  onChangeText={setCpassword}
                />
              </FormControl>
              <Button
                mt="2"
                maxW="80%"
                alignSelf="flex-end"
                colorScheme="indigo"
                _text={{ color: 'white' }}
                onPress={submitHandler}
              >
                Sign Up
              </Button>
              {error && errorText(error)}
            </VStack>
          </Box>
        </ScrollView>
      </KeyboardAvoidingView>
    </NativeBaseProvider>
  );
}
