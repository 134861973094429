import React, { useEffect, useState } from 'react';
import { LogBox, Platform } from 'react-native';
import {
  Link,
  Box,
  AspectRatio,
  Stack,
  Center,
  HStack,
  Image,
  Text,
  Spinner,
  FlatList,
  Pressable,
} from 'native-base';
import wiki from 'wikijs';
import { useNavigation } from '@react-navigation/native';
import md5 from 'blueimp-md5';

import {
  getFirstNQuotesByAuthor,
  getNextNQuotes,
  getAuthorImageURI,
} from './db/Query';
import QuoteDetails from './QuoteDetails';

const getFirstPageExtract = (jsonResponse) => {
  // You should probably add some validathin here to make sure pages exists
  const pages = jsonResponse.query.pages;
  const pageIds = Object.keys(pages);
  // Here we only take the first response since we know there is only one.
  const firstPageId = pageIds.length ? pageIds[0] : null;
  return firstPageId ? pages[firstPageId].extract : null;
};

const limit = 5;

const AuthorScreen = ({ route }) => {
  const { authorName } = route.params;
  const [authorContent, setAuthorContent] = useState('');
  const [quotes, setQuotes] = useState([]);
  const [authorImageURI, setAuthorImageURI] = useState();
  const addQuotes = (newQuotes) => {
    setQuotes([...quotes, ...newQuotes]);
    setLoading(false);
  };
  const [lastSeenRef, setLastSeenRef] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigation = useNavigation();
  const authorHash = md5(authorName.replace(/\s/g, '').toLowerCase());

  const renderItem = ({ item }) => {
    return (
      <Pressable
        onPress={() => {
          navigation.push('Reveal', {
            quoteID: item.id,
            partialData: true,
            screenTitle: 'Quote',
          });
        }}
      >
        <QuoteDetails
          quoteID={item.id}
          quote={item.quote}
          by={item.by}
          original={true}
          likeCount={item.likeCount}
          size="md"
          hideAuthorName={true}
        />
      </Pressable>
    );
  };

  const getAuthorFromWikipedia = async () => {
    if (authorName === 'GOQ Staff') {
      setAuthorContent('This is your friendly Game Of Quotes Staff');
      return;
    }
    const maxLength = 600;
    wiki({ apiUrl: 'https://en.wikipedia.org/w/api.php' })
      .find(authorName)
      .then((page) => page.summary())
      .then((summary) => {
        //trim the string to the maximum length
        let trimmedString = summary.substring(0, maxLength);
        //re-trim if we are in the middle of a word
        trimmedString = trimmedString.substring(
          0,
          Math.min(trimmedString.length, trimmedString.lastIndexOf(' '))
        );
        if (trimmedString.length === 0) {
          setAuthorContent("Couldn't find latest information on " + authorName);
        }
        setAuthorContent(trimmedString + '...');
      });
    //console.log(extract);
    //setAuthorContent(extract);
  };

  const getAuthorFromWikipediaNative = async () => {
    if (authorName === 'GOQ Staff') {
      setAuthorContent('This is your friendly Game Of Quotes Staff');
    } else {
      const wikipediaUrl =
        'https://en.wikipedia.org/w/api.php?action=query&prop=extracts&explaintext&exchars=800&format=json&titles=' +
        encodeURI(authorName);
      console.log('Wikipedia URL is ', wikipediaUrl);
      const response = await fetch(wikipediaUrl);
      const jsonContent = await response.json();
      //console.log(jsonContent);
      const extract = getFirstPageExtract(jsonContent);
      //console.log(extract);
      setAuthorContent(
        extract || "Couldn't find latest information on " + authorName
      );
    }
  };

  useEffect(() => {
    if (Platform.OS == 'android' || Platform.OS == 'ios') {
      LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
      getAuthorFromWikipediaNative();
    } else {
      getAuthorFromWikipedia();
    }
    getFirstNQuotesByAuthor(authorName, limit, setQuotes, setLastSeenRef);
    if (authorName !== 'GOQ Staff') {
      getAuthorImageURI(authorHash, setAuthorImageURI);
    }
  }, []);

  const getListHeader = () => {
    return (
      <Box alignItems="center">
        <Box
          mt={4}
          mb={4}
          maxW="90%"
          rounded="lg"
          overflow="hidden"
          borderColor="coolGray.200"
          borderWidth="1"
          _dark={{
            borderColor: 'coolGray.600',
            backgroundColor: 'gray.700',
          }}
          _web={{
            shadow: 2,
            borderWidth: 0,
          }}
          _light={{
            backgroundColor: 'gray.50',
          }}
        >
          <Box>
            {!!!authorImageURI && authorName !== 'GOQ Staff' && (
              <Spinner size="lg" mt={4} />
            )}
            {authorName !== 'GOQ Staff' && authorImageURI && (
              <AspectRatio
                ratio={{
                  base: 3 / 4,
                  md: 9 / 10,
                }}
                w="100%"
              >
                <Image
                  source={{
                    uri: authorImageURI,
                  }}
                  alt={'Picture of ' + authorName}
                  resizeMethod="scale"
                  w="100%"
                />
              </AspectRatio>
            )}
            {authorName === 'GOQ Staff' && (
              <AspectRatio w="100%">
                <Image
                  source={require('./assets/splash.png')}
                  alt={'Picture of ' + authorName}
                  size={'full'}
                  alignSelf="center"
                />
              </AspectRatio>
            )}
            <Center
              bg="indigo.800"
              _dark={{
                bg: 'indigo.400',
              }}
              _text={{
                color: 'warmGray.50',
                fontWeight: '700',
                fontSize: 'sm',
              }}
              position="absolute"
              bottom="0"
              px="3"
              py="1.5"
            >
              {authorName}
            </Center>
          </Box>
          <Stack p="4" space={3}>
            {authorContent.length > 0 && (
              <Text fontWeight="400">{authorContent}</Text>
            )}
            {authorContent.length === 0 && <Spinner size="lg" />}
            <HStack
              alignItems="center"
              space={4}
              justifyContent="space-between"
            >
              <HStack alignItems="center">
                <Text
                  color="coolGray.600"
                  _dark={{
                    color: 'warmGray.200',
                  }}
                  fontWeight="400"
                >
                  See{' '}
                  <Link
                    href={
                      'https://en.m.wikipedia.org/w/index.php?search=' +
                      encodeURI(authorName) +
                      '&title=Special:Search'
                    }
                    isExternal
                    _text={{
                      color: 'blue.600',
                    }}
                  >
                    <Text color="blue.600" underline fontWeight="400">
                      Wikipedia
                    </Text>
                  </Link>{' '}
                  for more details
                </Text>
              </HStack>
            </HStack>
          </Stack>
        </Box>
      </Box>
    );
  };

  const getListFooter = () => {
    if (!loading) {
      return null;
    }
    return <Spinner mx={4} size="lg" />;
  };

  return (
    <Box
      width="100%"
      maxWidth="100%"
      alignItems="center"
      alignSelf="center"
      m={2}
      flex={1}
      mb={4}
    >
      <FlatList
        showsHorizontalScrollIndicator={false}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={getListHeader}
        ListFooterComponent={getListFooter}
        data={quotes}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        width="100%"
        initialNumToRender={limit}
        onEndReached={({ distanceFromEnd }) => {
          if (distanceFromEnd < 0) return;
          if (lastSeenRef) {
            setLoading(true);
            if (!loading) {
              getNextNQuotes(
                authorName,
                limit,
                addQuotes,
                lastSeenRef,
                setLastSeenRef
              );
            }
          }
        }}
        onEndReachedThreshold={0.4}
      />
    </Box>
  );
};

export default AuthorScreen;
