import React, { useState, useEffect } from 'react';
import {
  Box,
  FlatList,
  Toast,
  Text,
  Image,
  Skeleton,
  VStack,
  useTheme,
  Link,
  Button,
  Icon,
} from 'native-base';
import { useNavigation } from '@react-navigation/native';
import { MaterialIcons } from '@expo/vector-icons';

import SpoofQuoteDetails from './SpoofQuoteDetail';
import SpoofOrderingWidget from './SpoofOrderingWidget';
import { addToUserScore } from './db/Query';
import Scores from './db/ScoreConstants';

import {
  getUserAttemptsForQuote,
  getUserLikeStatusOfSpoofQuotes,
  setUserLikeStatusOfSpoofQuote,
  deleteUserTweakFromQuote,
  deleteUserTweakFromUser,
} from './db/Query';

/** 
const spoofData (merged) = [
  {
    id: '1',
    quote: 'I said something',
    by: { authId: id, authNickname: nickname, authAvatarKey},
    likeFun: () => {},
    dislikeFun: () => {},
    likeStatus: 1,
    likeCount: NN,
    dislikeCount: KK,
    createTime: <server timestamp>
  },
];
**/

const renderItem = ({ item }) => {
  return <SpoofQuoteDetails {...item} />;
};

const SpoofList = ({ quoteID, userID }) => {
  /*
   * spoofData array is
   * [ {id (spoofID), by (spoofAuthObj), quote(quoteText), likeCount, dislikeCount, createTime}, ..]
   */
  const [spoofData, setSpoofData] = useState([]);

  // Is false if we have not yet confirmed with DB that there are or not any spoofs
  const [checkedSpoofData, setCheckedSpoofData] = useState(false);

  // if sortSpoofByPopular==true then use higher likeCount in tweak
  // if not then it means that you sort by createTime(latest first)
  const [sortSpoofByPopular, setSortSpoofByPopular] = useState(true);

  /*
   * mySpoofLikeList is (oldLike may or may not be present)
   * [ { spoofID, 1, oldLike: 0}, { spoofID, -1}, ...]
   */
  const [mySpoofLikeList, setMySpoofLikeList] = useState([]);

  const { colors } = useTheme();

  const navigation = useNavigation();

  /*
   * mergedSpoofData is
   * [{spoofID, spoofAuthorID, quoteText, likedStatus, likeFun, dislikeFun, likeCount, dislikeCount, createTime}, ..]
   */
  const [mergedSpoofData, setMergedSpoofData] = useState([]);

  const updateMySpoofLikeList = (userID, creatorID, spoofID, newVal) => {
    if (mySpoofLikeList.find((ms) => ms.id == spoofID)) {
      setMySpoofLikeList(
        mySpoofLikeList.map((e) => {
          if (e.id === spoofID) {
            let oldLike = e.like;
            return { ...e, like: newVal, oldLike };
          } else {
            return e;
          }
        })
      );
    } else {
      setMySpoofLikeList([
        ...mySpoofLikeList,
        { id: spoofID, like: newVal, oldLike: 0 },
      ]);
      // this is where a new spoof/tweak is first interacted with.
      addToUserScore(userID, Scores.LIKED_DISLIKED_TWEAK);
      // Note that if someone lislikes creator gets dinged, but undoing it alone will not undo the ding
      // same is true with likes.
      if (newVal === 1) addToUserScore(creatorID, Scores.YOUR_TWEAK_LIKED);
      if (newVal === -1) addToUserScore(creatorID, Scores.YOUR_TWEAK_DISLIKED);
    }
  };

  const likeFunTemplate = (userID, spoofID, currentLikeState, creator) => {
    switch (currentLikeState) {
      case 1:
        updateMySpoofLikeList(userID, creator.authId, spoofID, 0);
        setUserLikeStatusOfSpoofQuote(
          userID,
          spoofID,
          0,
          quoteID,
          creator.authId,
          currentLikeState
        );
        break;
      case -1:
      case 0:
        updateMySpoofLikeList(userID, creator.authId, spoofID, 1);
        setUserLikeStatusOfSpoofQuote(
          userID,
          spoofID,
          1,
          quoteID,
          creator.authId,
          currentLikeState
        );
    }
  };

  const dislikeFunTemplate = (userID, spoofID, currentLikeState, creator) => {
    switch (currentLikeState) {
      case -1:
        updateMySpoofLikeList(userID, creator.authId, spoofID, 0);
        setUserLikeStatusOfSpoofQuote(
          userID,
          spoofID,
          0,
          quoteID,
          creator.authId,
          currentLikeState
        );
        break;
      case 1:
      case 0:
        updateMySpoofLikeList(userID, creator.authId, spoofID, -1);
        setUserLikeStatusOfSpoofQuote(
          userID,
          spoofID,
          -1,
          quoteID,
          creator.authId,
          currentLikeState
        );
    }
  };

  // wrapper such that we can set that we indeed checked with DB
  const setSpoofDataCallback = (sd) => {
    setCheckedSpoofData(true);
    if (sd.length > 0) {
      setSpoofData(sd);
    }
    console.log('In setSpoofDataCallback sd length is ', sd.length);
  };

  useEffect(() => {
    getUserAttemptsForQuote(quoteID, setSpoofDataCallback);
    return () => setSpoofData([]);
  }, []);

  useEffect(() => {
    if (spoofData.length > 0) {
      getUserLikeStatusOfSpoofQuotes(userID, setMySpoofLikeList);
    }
  }, [spoofData]);

  // Note here we are just showing the immediate result of like/dislike
  // though we are syncing the total count in the backend but we are not
  // updating the screen based on database.
  // This is an optimization such that we do not keep subscriptions but
  // still show "realtime" like dislike changes on the screen
  useEffect(() => {
    let mergedArr = [];
    let interactedSpoof;
    spoofData.forEach((sd) => {
      const csd = sd;
      // self author
      if (userID == csd.by.authId) {
        mergedArr.push({
          ...csd,
          likeFun: () => {
            Toast.show({
              title: 'Cannot upvote!',
              status: 'warning',
              description: 'Sorry, you cannot like your own tweak',
              style: {
                backgroundColor: '#f7ccc8',
              },
            });
          },
          dislikeFun: () => {
            Toast.show({
              title: 'Cannot downvote',
              status: 'warning',
              description: 'Did you seriously try to downvote your own tweak!',
              style: {
                backgroundColor: colors.indigo['200'],
              },
            });
          },
          deleteFun: () => {
            setSpoofData(spoofData.filter((s) => s.id != csd.id));
            deleteUserTweakFromQuote(quoteID, userID, csd.id);
            deleteUserTweakFromUser(userID, csd.id);
            Toast.show({
              title: 'Tweak deleted',
              status: 'info',
              style: {
                backgroundColor: '#f7ccc8',
              },
            });
          },
        });
      } else {
        interactedSpoof = mySpoofLikeList.find((e) => e.id === sd.id);
        if (interactedSpoof) {
          const ispoof = interactedSpoof;
          const oldLike = ispoof.oldLike === undefined ? -2 : ispoof.oldLike;
          let newLikeCount = csd.likeCount;
          let newDislikeCount = csd.dislikeCount;

          switch (oldLike) {
            case 0:
              if (ispoof.like > 0) {
                newLikeCount++;
              } else if (ispoof.like < 0) {
                newDislikeCount++;
              }
              break;
            case -1:
              if (ispoof.like > 0) {
                newLikeCount++;
                newDislikeCount--;
              } else if (ispoof.like == 0) {
                newDislikeCount--;
              }
              break;
            case 1:
              if (ispoof.like < 0) {
                newLikeCount--;
                newDislikeCount++;
              } else if (ispoof.like == 0) {
                newLikeCount--;
              }
              break;
          }
          mergedArr.push({
            ...csd,
            likeCount: newLikeCount,
            dislikeCount: newDislikeCount,
            likeStatus: ispoof.like,
            likeFun: () => likeFunTemplate(userID, csd.id, ispoof.like, csd.by),
            dislikeFun: () =>
              dislikeFunTemplate(userID, csd.id, ispoof.like, csd.by),
          });
        } else {
          mergedArr.push({
            ...csd,
            likeStatus: 0,
            likeFun: () => likeFunTemplate(userID, csd.id, 0, csd.by),
            dislikeFun: () => dislikeFunTemplate(userID, csd.id, 0, csd.by),
          });
        }
      }
    });

    // if sortSpoofByPpoular==true then use higher likeCount, if not then it means that you sort by createTime (latest first)
    if (sortSpoofByPopular) {
      mergedArr.sort(
        (a, b) => b.likeCount - b.dislikeCount - a.likeCount + a.dislikeCount
      );
    } else {
      mergedArr.sort((a, b) => b.createTime - a.createTime);
    }
    setMergedSpoofData(mergedArr);
    return () => setMergedSpoofData([]);
  }, [mySpoofLikeList, sortSpoofByPopular]);

  const TweakButton = () => {
    return (
      <>
        <Button
          size="md"
          width="50%"
          bg="amber.400"
          borderRadius={20}
          _text={{
            color: 'indigo.700',
            fontWeight: 'bold',
            fontSize: 'md',
          }}
          variant="solid"
          onPress={() => {
            navigation.navigate('TweakGameStandalone', { quoteID: quoteID });
          }}
          endIcon={
            <Icon
              as={<MaterialIcons name="send" />}
              color="indigo.700"
              size="md"
              mr={3}
            />
          }
        >
          Tweak this
        </Button>

        <Text fontSize="md" color="indigo.600" mx={4}>
          to create your own tweaks
        </Text>
      </>
    );
  };

  return (
    <>
      {spoofData.length > 0 && checkedSpoofData && (
        <SpoofOrderingWidget
          sortSpoofByPopular={sortSpoofByPopular}
          setSortSpoofByPopular={setSortSpoofByPopular}
        />
      )}
      <Box
        width="100%"
        maxWidth="100%"
        alignItems="center"
        alignSelf="center"
        m={2}
      >
        {spoofData.length > 0 && <TweakButton />}
        {spoofData.length > 0 && checkedSpoofData && (
          <FlatList
            data={mergedSpoofData}
            renderItem={renderItem}
            keyExtractor={(item) => item.id}
            width="100%"
          />
        )}

        {!checkedSpoofData && spoofData.length === 0 && (
          <>
            <Skeleton.Text px="4" />
            <Skeleton.Text px="4" py="2" />
          </>
        )}

        {checkedSpoofData && spoofData.length === 0 && (
          <>
            <VStack alignItems="center" mt="2">
              <Text>No tweaks for this quote so far!</Text>
              <Image
                source={require('./assets/shrug.png')}
                alt="Shrug"
                size={'lg'}
                alignSelf="center"
              />
            </VStack>
            <TweakButton />
          </>
        )}
      </Box>
    </>
  );
};

export default SpoofList;
