import * as React from 'react';
import { useState, useEffect, useCallback, useContext, useRef } from 'react';
import { useWindowDimensions, View, Platform } from 'react-native';
import {
  Box,
  HStack,
  Text,
  Spinner,
  ScrollView,
  CheckIcon,
  CloseIcon,
  Button,
  Modal,
  useDisclose,
  Center,
  Checkbox,
  VStack,
  StatusBar,
  useTheme,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import { useTransition, animated, config } from 'react-spring';

import useKeyboard from './common/UseKeyboard';
import { setRevealConfirmState } from './redux/reducers/RevealConfirmState';
import { SplashContext, GlobalXStateMachineContext } from './App.js';
import QuoteAction from './common/QuoteAction';
import {
  quoteQuery,
  userSeenQuery,
  updateQuoteSeen,
  getLastUnseenNxn,
  clearLastSeenNxn,
  getQuoteDocbyID,
} from './db/Query.js';
import NextButtonPanel from './NextButtonPanel.js';
import SlideNxn from './common/SlideNxn';
import { sendAnalyticsEventAsync } from './analytics';
import ScreenSizeModal from './common/ScreenSizeModal';
import FontControl from './common/FontControl';

const NEXT_QUOTE_ACTION = 'go to the next quote ?';
const REVEAL_ANSWER_ACTION = 'see the answer ?';

const getRandomWrongText = () => {
  const wrongText = [
    'Oops not correct!',
    'Not quite!',
    'Thats not it!',
    'Good try!',
  ];
  return wrongText[Math.floor(Math.random() * wrongText.length)];
};

const randomWrongText = getRandomWrongText();

const resultImage = (correct, quoteData) => {
  if (correct) {
    return (
      <VStack alignItems="center" m={2}>
        <HStack justifyContent="center" alignItems="center" space={2} mt={4}>
          <CheckIcon size="5" mt="0.5" color="emerald.600" />
          <Text
            style={{ fontFamily: 'Lato_400Regular' }}
            fontWeight={800}
            fontStyle="bold"
            color="emerald.600"
            fontSize="lg"
          >
            Correct Answer!
          </Text>
        </HStack>
        <Text
          style={{ fontFamily: 'Lato_400Regular' }}
          fontWeight={500}
          color="indigo.800"
          fontSize="md"
          mt={2}
        >
          Quote by : {quoteData.by}
        </Text>
        <Text
          style={{ fontFamily: 'Lato_400Regular' }}
          fontWeight={500}
          color="indigo.800"
          fontSize="md"
          mt={2}
        >
          {quoteData.details}
        </Text>
      </VStack>
    );
  } else {
    return (
      <HStack justifyContent="center" alignItems="center" space={2} mt={4}>
        <CloseIcon size="5" mt="0.5" color="error.800" />
        <Text
          style={{ fontFamily: 'Lato_400Regular' }}
          fontWeight={800}
          fontStyle="bold"
          color="error.800"
          fontSize="lg"
        >
          {randomWrongText}
        </Text>
      </HStack>
    );
  }
};

export default function Home({ navigation, route }) {
  const user = useSelector((state) => state.authState.userToken);
  const popularityValue = useSelector((state) => state.popularityState.value);
  const [initQuote, setInitQuote] = useState(null);
  const [quotesSeen, setQuotesSeen] = useState([]);
  const { height, scale, width } = useWindowDimensions();
  const [resultComposite, setResultComposite] = useState({
    showResult: false,
    showSuccessImage: false,
    userQuote: '',
  });
  // For the next / reveal confirmation modal
  const { isOpen, onOpen, onClose } = useDisclose();
  const [appIsReady, setAppIsReady] = useState(false);
  const [attemptedAction, setAttemptedAction] = useState('');
  const [userUncheckedConfirmBox, setUserUncheckedConfirmBox] = useState(false);
  const [lastUnseenNxn, setLastUnseenNxn] = useState(null);
  const [showNotif, setShowNotif] = useState(false);
  const scrollViewRef = useRef(null);
  const [lowEndLayout, setLowEndLayout] = useState({ y: 0 });
  const dispatch = useDispatch();
  const showConfirmOnReveal = useSelector(
    (state) => state.revealConfirmState.value
  );
  const userInterests = useSelector((state) => state.interestState.value);

  const { send } = useContext(GlobalXStateMachineContext).scoreService;
  const smSend = send;
  const { colors } = useTheme();

  const keyboardShowing = useKeyboard();
  const transitions = useTransition(!keyboardShowing, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    delay: 200,
    config: config.molasses,
  });
  const SBox = animated(Box);

  useEffect(() => {
    if (attemptedAction != '') {
      if (!showConfirmOnReveal || resultComposite.showResult) {
        proceedToAction();
      } else {
        onOpen();
      }
    }
  }, [attemptedAction]);

  useEffect(() => {
    if (scrollViewRef && initQuote) {
      scrollViewRef.current.scrollTo({ y: lowEndLayout.y, animated: true });
    }
  }, [lowEndLayout]);

  useEffect(() => {
    async function prepare() {
      try {
        await userSeenQuery(user.fbUID, quotesSeen, setQuotesSeen);
        getLastUnseenNxn(user.fbUID, setLastUnseenNxn);
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }
    async function useGivenQuoteId() {
      try {
        const { quoteID } = route.params;
        getQuoteDocbyID(quoteID, setInitQuote);
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        setAppIsReady(true);
      }
    }
    if (route && route.params && route.params.quoteID) {
      useGivenQuoteId();
    } else {
      prepare();
    }
    return () => setQuotesSeen(null);
  }, []);

  useEffect(() => {
    if (quotesSeen != null) {
      quoteQuery([...quotesSeen], userInterests, popularityValue, setInitQuote);
    }
    // we are not updating the quote seen database entry here to avoid one extra DB write
  }, [quotesSeen]);

  useEffect(() => {
    if (lastUnseenNxn) {
      setShowNotif(true);
      clearLastSeenNxn(user.fbUID);
      sendAnalyticsEventAsync('inapp_nxn_shown_home');
    }
  }, [lastUnseenNxn]);

  const { showResult, showSuccessImage } = resultComposite;

  useEffect(() => {
    if (showSuccessImage) {
      smSend({ type: 'CORRECT', userid: user.fbUID, quote_id: initQuote.id });
      sendAnalyticsEventAsync('quote_guessed_correctly');
    } else {
      sendAnalyticsEventAsync('quote_guessed_wrongly');
    }
  }, [showSuccessImage]);

  const splashContext = useContext(SplashContext);

  const onLayoutRootView = useCallback(async () => {
    if (appIsReady) {
      // This tells the splash screen to hide immediately! If we call this after
      // `setAppIsReady`, then we may see a blank screen while the app is
      // loading its initial state and rendering its first pixels. So instead,
      // we hide the splash screen once we know the root view has already
      // performed layout.
      splashContext.setAppFinallyReady(true);
    }
  }, [appIsReady]);

  const proceedToAction = () => {
    onClose();
    if (attemptedAction === NEXT_QUOTE_ACTION) {
      //smSend('RESET');  //already called from quote when it changes
      queryAgain();
      sendAnalyticsEventAsync('next_quote_game');
    } else if (attemptedAction === REVEAL_ANSWER_ACTION) {
      smSend({ type: 'REVEAL', quote_id: initQuote.id });
      revealAnswer();
      sendAnalyticsEventAsync('reveal_quote_game');
    }
    setAttemptedAction('');
    if (userUncheckedConfirmBox) {
      dispatch(setRevealConfirmState(false));
      setUserUncheckedConfirmBox(false);
    }
  };

  const cancelAction = () => {
    onClose();
    setAttemptedAction('');
    if (userUncheckedConfirmBox) {
      dispatch(setRevealConfirmState(false));
      setUserUncheckedConfirmBox(false);
    }
  };

  const queryAgain = () => {
    setLowEndLayout({ y: 0 });
    setResultComposite({
      ...resultComposite,
      showResult: false,
      showSuccessImage: false,
      userQuote: '',
    });
    // first update the database and then set it locally, this would
    // trigger the next lookup from the effect.
    updateQuoteSeen(user.fbUID, [...quotesSeen, initQuote.id]);
    setQuotesSeen([...quotesSeen, initQuote.id]);
    setLowEndLayout({ y: 0 });
  };

  const revealAnswer = () => {
    const { quote, by, details, reference, likes } = initQuote.data();
    navigation.push('Reveal', {
      quoteID: initQuote.id,
      likes,
      quote,
      by,
      details,
      reference,
      screenTitle: 'Reveal',
    });
  };

  const NextButtonsWithAnimation = () => {
    return transitions(
      (styles, item) =>
        item && (
          <SBox
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            style={styles}
          >
            <NextButtonPanel
              reQuery={() => {
                setAttemptedAction(NEXT_QUOTE_ACTION);
              }}
              reveal={() => {
                setAttemptedAction(REVEAL_ANSWER_ACTION);
              }}
            />
          </SBox>
        )
    );
  };

  if (!!!initQuote) {
    return (
      <Center safeArea>
        <Spinner alignSelf="center" size="lg" />
      </Center>
    );
  }
  return (
    <Box
      safeArea
      alignSelf="center"
      maxW="90%"
      maxH="95%"
      onLayout={onLayoutRootView}
    >
      <StatusBar
        barStyle="light-content"
        backgroundColor="#6366f1"
        translucent
      />
      <FontControl />
      <ScrollView
        height={height * 0.9}
        showsVerticalScrollIndicator={false}
        ref={scrollViewRef}
        mt={Platform.OS === 'android' ? 0 : 5}
      >
        {initQuote && (
          <QuoteAction
            initQuote={initQuote}
            resultComposite={resultComposite}
            setResultComposite={setResultComposite}
            user={user}
            mode="game"
          />
        )}
        {showResult && resultImage(showSuccessImage, initQuote.data())}
        {showNotif && (
          <SlideNxn
            showNotif={showNotif}
            setShowNotif={setShowNotif}
            lastUnseenNxn={lastUnseenNxn}
          />
        )}

        {showResult && (
          <View
            width={0}
            height={0}
            onLayout={(event) => {
              const pos = event.nativeEvent.layout;
              if (pos.y + 250 >= height) {
                setLowEndLayout({ ...pos, y: parseInt((height - 250) / 1.5) });
              }
            }}
          />
        )}
        {!showResult && (
          <View
            width={0}
            height={0}
            onLayout={(event) => {
              setLowEndLayout({ y: 0 });
            }}
          />
        )}
        <Box h={800} />
      </ScrollView>

      {NextButtonsWithAnimation()}
      {showConfirmOnReveal && (
        <Modal isOpen={isOpen} onClose={cancelAction}>
          <Modal.Content>
            <Modal.CloseButton />
            <Modal.Header fontSize="4xl" fontWeight="bold">
              {attemptedAction === REVEAL_ANSWER_ACTION
                ? 'Reveal Answer'
                : 'Next Quote'}
            </Modal.Header>
            <Modal.Body>
              <Text>
                You haven't fully attempted to complete the quote. Do you want
                to give up and {attemptedAction}
              </Text>
              <Checkbox
                mt={3}
                isChecked={!userUncheckedConfirmBox}
                onChange={(v) => {
                  setUserUncheckedConfirmBox(!userUncheckedConfirmBox);
                }}
                colorScheme="indigo"
              >
                <Text>Always ask me to confirm</Text>
              </Checkbox>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="unstyled" mr="1" onPress={cancelAction}>
                Cancel
              </Button>
              <Button colorScheme="indigo" onPress={proceedToAction}>
                Yes, Show me
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      )}
    </Box>
  );
}
