import { createSlice } from '@reduxjs/toolkit';

export const revealConfirmStateSlice = createSlice({
  name: 'shareState',
  initialState: {
    value: true,
  },
  reducers: {
    setRevealConfirmState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setRevealConfirmState } = revealConfirmStateSlice.actions;

export default revealConfirmStateSlice.reducer;
