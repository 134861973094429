import { createSlice } from '@reduxjs/toolkit';

// score is a special state. It is maintained locally but not updated from anywhere except in a
// Firestore subscription in Root.js.
// Add changes to score for increment or decrement is done by updating the database directly.
// This state is created to have all the screens that need to show the score can do so wihout
// querying or maintaining subscriptions.
export const scoreStateSlice = createSlice({
  name: 'scoreState',
  initialState: {
    value: 10,
  },
  reducers: {
    setScoreState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setScoreState } = scoreStateSlice.actions;

export default scoreStateSlice.reducer;
