import * as React from 'react';
import { useState } from 'react';
import {
  NativeBaseProvider,
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Text,
  Link,
} from 'native-base';
import { auth } from './firebase';
import { sendPasswordResetEmail } from 'firebase/auth';

export default function ForgotPassword({ route, navigation }) {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [emailSent, setEmailSent] = useState(false);

  const submitHandler = () => {
    setError(null);
    console.log(email);
    if (!email) {
      setError('Email cannot be blank');
      return;
    }
    sendPasswordResetEmail(auth, email)
      .then(() => {
        // account found and email sent
        callbackFun(0, null);
      })
      .catch((error) => {
        console.log(error);
        callbackFun(-1, error.message);
      });
  };

  const errorText = (err) => (
    <Text fontSize="sm" color="danger.800">
      {err}
    </Text>
  );

  const callbackFun = (result, errmsg) => {
    if (result === 0) {
      setError(null);
      setEmailSent(true);
    } else {
      setError(errmsg);
    }
  };

  return (
    <NativeBaseProvider>
      <Box safeArea flex={1} p="2" w="90%" mx="auto" py="8">
        <Heading size="lg" color="coolGray.800" fontWeight="600">
          Request password reset
        </Heading>
        <Heading mt="1" color="coolGray.600" fontWeight="medium" size="xs">
          If you already have an email account then you can request a password
          reset.
        </Heading>

        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label
              _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}
            >
              Email
            </FormControl.Label>
            <Input onChangeText={setEmail} value={email} />
          </FormControl>

          <Button
            mt="2"
            maxW="80%"
            alignSelf="flex-end"
            colorScheme="indigo"
            _text={{ color: 'white' }}
            onPress={submitHandler}
          >
            Send Reset Email
          </Button>
          {error && errorText(error)}
          {emailSent && (
            <Link
              onPress={() =>
                navigation.navigate('Sign In', { userExisted: false })
              }
            >
              Check your email (including spam folder) with reset instructions
              and then click here.
            </Link>
          )}
        </VStack>
      </Box>
    </NativeBaseProvider>
  );
}
