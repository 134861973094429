import React, { useEffect } from 'react';
import { Text, Box, useTheme, Stack, Badge, Icon } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { useSpring, animated, config } from 'react-spring';
import { useNavigation } from '@react-navigation/native';
import { useSelector } from 'react-redux';

import QuoteActionPanel from './QuoteActionPanel';

const QuoteDetails = ({
  quoteID,
  quote,
  by,
  original,
  likeCount,
  size,
  hideAuthorName,
}) => {
  const SText = animated(Text);
  const [tprops, api] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const navigation = useNavigation();
  const gameFontSize = useSelector((state) => state.gameFontState.value);
  useEffect(() => {
    api.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
    });
  }, [quote]);

  const { colors } = useTheme();
  return (
    <Box
      bg="white"
      shadow={2}
      rounded="lg"
      width="90%"
      maxWidth="90%"
      alignItems="center"
      alignSelf="center"
      m={5}
    >
      <Stack space={4} p={[4, 4, 8]} justifyContent="center" width="100%">
        <Icon
          alignSelf="center"
          color="warmGray.500"
          size={size == 'md' ? 4 : 8}
          as={<FontAwesome name="quote-left" />}
        />
        <SText
          fontSize={gameFontSize}
          p={2}
          style={{ ...tprops, fontFamily: 'Lato_400Regular_Italic' }}
          fontWeight={500}
          fontStyle="italic"
          color="warmGray.500"
        >
          {quote}
        </SText>

        {!!!hideAuthorName && (
          <Text
            position="absolute"
            left={1}
            bottom={2}
            style={{ fontFamily: 'Lato_400Regular' }}
            fontSize="md"
            fontWeight={500}
            color="darkBlue.500"
            maxW="60%"
            zIndex={1}
            onPress={() => {
              navigation.push('AuthorScreen', {
                authorName: by,
                screenTitle: by,
              });
            }}
          >
            {by}
          </Text>
        )}
        <Badge
          position="absolute"
          top={2}
          right={2}
          variant={'subtle'}
          colorScheme={original ? 'success' : 'danger'}
        >
          {original ? 'original' : 'tweak'}
        </Badge>

        <QuoteActionPanel
          width="35%"
          position="absolute"
          bottom={2}
          right={0}
          initLikeCount={likeCount}
          quoteID={quoteID}
          quote={quote}
          by={by}
          quoteText={`"${quote}" - ${by}\n\nSee more quotes at https://gameofquotes.app`}
          quoteURL={'gameofquotes.app'}
        />
      </Stack>
    </Box>
  );
};

export default QuoteDetails;
