import { assign, send } from 'xstate';

import { addToUserScore } from '../Query';
import Scores from '../ScoreConstants';

const stateDef = {
  id: 'score',
  initial: 'quote_question',
  context: {
    retries: 0,
    answered: false,
    revealed: false,
    revealed_quote_id: '',
    liked_quote_id: '',
  },
  states: {
    quote_question: {
      on: {
        CORRECT: {
          target: 'answered',
          actions: ['score'],
        },
        WRONG: {
          target: 'wrong',
          actions: ['consolation_score'],
        },
        REVEAL: {
          target: 'revealed',
          actions: ['set_revealed_id'],
        },
        // Enter the QuoteScreen
        QUOTE_MODE_NEXT: {
          target: 'revealed',
          actions: ['set_revealed_id', 'consolation_score'], //+1 on just viewing
        },
        RESET: {
          target: 'quote_question',
          actions: ['reset', 'consolation_score'],
        },
      },
    },
    answered: {
      exit: assign({ answered: true }), //exit because otherwise this was set before action
      on: {
        CORRECT: 'answered',
        WRONG: 'wrong',
        REVEAL: {
          target: 'revealed',
          actions: ['set_revealed_id'],
        },
        // Enter the QuoteScreen
        QUOTE_MODE_NEXT: {
          target: 'revealed',
          actions: ['set_revealed_id', 'consolation_score'], //+1 on just viewing
        },
        RESET: {
          target: 'quote_question',
          actions: ['reset', 'consolation_score'],
        },
      },
    },
    revealed: {
      entry: assign({ revealed: true }),
      on: {
        QUOTE: 'quote_question',
        CORRECT: {
          target: 'answered',
          actions: ['score'],
        },
        // There is no state change on back from Reveal, so if Reveal is pressed again
        // we just update the quote_id
        REVEAL: {
          target: 'revealed',
          actions: ['set_revealed_id'],
        },
        // "Next" from the QuoteScreen
        QUOTE_MODE_NEXT: {
          target: 'revealed',
          actions: ['set_revealed_id', 'consolation_score'], //+1 on just viewing
        },
        LIKED_QUOTE: {
          target: 'revealed',
          actions: ['quote_like_score', 'send_internal_set_liked_quote'], //+2 on like but once
        },
        INTERNAL_SET_LIKED_QUOTE: {
          target: 'revealed',
          actions: ['set_liked_quote'],
        },
        WRONG: 'wrong',
        RESET: {
          target: 'quote_question',
          actions: ['reset', 'consolation_score'],
        },
      },
    },
    wrong: {
      entry: assign({
        retries: (context, event) => context.retries + 1,
      }),
      on: {
        CORRECT: {
          target: 'answered',
          actions: ['score', 'reset_retries'],
        },
        REVEAL: {
          target: 'revealed',
          actions: ['set_revealed_id'],
        },
        // Enter the QuoteScreen
        QUOTE_MODE_NEXT: {
          target: 'revealed',
          actions: ['set_revealed_id', 'consolation_score'], //+1 on just viewing
        },
        WRONG: 'wrong',
        RESET: {
          target: 'quote_question',
          actions: ['reset', 'consolation_score'],
        },
      },
    },
  },
};

// we will send event like so
// send({ type: CORRECT, userid: fbUID}) this is the event object
const actionDef = {
  actions: {
    score: (context, event) => {
      if (
        context.revealed &&
        context.revealed_quote_id == event.quote_id &&
        !context.answered
      ) {
        addToUserScore(event.userid, Scores.REVEAL_CORRECT);
      } else if (!context.answered) {
        addToUserScore(event.userid, Scores.CORRECT_QUOTE);
      }
    },
    consolation_score: (context, event) => {
      addToUserScore(event.userid, Scores.CONSOLATION);
    },
    quote_like_score: (context, event) => {
      if (
        !context.liked_quote_id ||
        context.liked_quote_id != event.liked_quote_id
      ) {
        addToUserScore(event.userid, Scores.LIKED_QUOTE);
      }
    },
    send_internal_set_liked_quote: send((context, event) => ({
      type: 'INTERNAL_SET_LIKED_QUOTE',
      liked_quote_id: event.liked_quote_id,
    })),
    set_liked_quote: assign({
      liked_quote_id: (context, event) => event.liked_quote_id,
    }),
    reset: assign({
      retries: 0,
      answered: false,
      //revealed: false,
      //revealed_quote_id: '',
      // we remember the last revealed even within resets.
      // this helps in mode toggle memory.
    }),
    increment_retries: assign({
      retries: (context, event) => context.retries + 1,
    }),
    reset_retries: assign({
      retries: 0,
    }),
    set_revealed_id: assign({
      revealed_quote_id: (context, event) => event.quote_id,
    }),
  },
};

export { stateDef, actionDef };
