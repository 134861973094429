import React, { useContext, useCallback, useState } from 'react';
import {
  NativeBaseProvider,
  Box,
  Heading,
  HStack,
  Icon,
  Button,
  Text,
  StatusBar,
  Row,
  FlatList,
  Column,
} from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import * as Updates from 'expo-updates';

import { SplashContext } from './App.js';
import NoNetworkQuote from './NoNetworkQuote.js';
import nointernetquotes from './nointernetquote.js';

export default function NoInternetScreen({ navigation }) {
  const splashContext = useContext(SplashContext);

  const onLayoutNoInternetView = useCallback(async () => {
    // hide the splash screen as soon as the layout is done
    splashContext.setAppFinallyReady(true);
  }, []);

  const quotes = nointernetquotes;
  const renderItem = ({ item }) => {
    return <NoNetworkQuote quote={item.quote} by={item.by} size="md" />;
  };

  return (
    <NativeBaseProvider>
      <Box
        safeArea
        flex={1}
        p="2"
        w="90%"
        mx="auto"
        py="8"
        onLayout={onLayoutNoInternetView}
      >
        <StatusBar barStyle="light-content" backgroundColor="#6366f1" />

        <Column justifyContent="space-around" alignItems="center">
          <Heading
            size="sm"
            color="coolGray.800"
            fontWeight={500}
            fontFamily="heading"
            alignItems="center"
          >
            You seem to be offline!
          </Heading>
          <Button
            size="sm"
            bg="indigo.500"
            _text={{ color: 'white', fontFamily: 'Lato_700Bold' }}
            startIcon={<Icon as={AntDesign} name="disconnect" size="sm" />}
            onPress={async () => {
              await Updates.reloadAsync();
            }}
            my={2}
          >
            {' Try reconnecting '}
          </Button>
        </Column>
        <Text
          fontSize="sm"
          color="coolGray.500"
          alignItems="center"
          my={2}
          width="100%"
        >
          But don't worry we got you covered. Browse some of the quotes below
          while your connectivity is restored
        </Text>

        <FlatList
          showsHorizontalScrollIndicator={false}
          showsVerticalScrollIndicator={false}
          data={quotes}
          renderItem={renderItem}
          keyExtractor={(item) => item.id}
          width="100%"
        />
      </Box>
    </NativeBaseProvider>
  );
}
