import React from 'react';
import { Row, Heading, Text, Button, Divider, HStack } from 'native-base';

import LinkWithPopupTip from './LinkWithPopupTip';

const SpoofOrderingWidget = ({ sortSpoofByPopular, setSortSpoofByPopular }) => {
  return (
    <HStack
      justifyContent="center"
      m={2}
      width="90%"
      alignSelf="center"
      alignItems="center"
    >
      <Row
        justifyContent="flex-start"
        mr={1}
        flex={1}
        alignItems="center"
        minH={4}
      >
        <LinkWithPopupTip
          mainText="Fun Tweaks"
          headerText="Fun Tweaks"
          helpText={`You can create your own Tweaks to the popular quotes, filling in blanks and completing quotes`}
          actionFun={() => {}}
          underline={false}
        />
      </Row>
      <Row
        minH={2}
        alignItems="center"
        justifyContent="flex-end"
        mr={1}
        flex={2}
      >
        <Text fontSize="xs" bold>
          Sort by:
        </Text>
        <Button
          size="sm"
          variant="link"
          onPress={() => setSortSpoofByPopular(true)}
        >
          <Text
            fontSize="xs"
            bold={sortSpoofByPopular}
            highlight={sortSpoofByPopular}
            color="indigo.600"
          >
            {' Rating '}
          </Text>
        </Button>
        <Divider
          bg="indigo.600"
          mx="1"
          width={1}
          height={4}
          orientation="vertical"
        />
        <Button
          size="sm"
          variant="link"
          onPress={() => setSortSpoofByPopular(false)}
        >
          <Text
            fontSize="xs"
            highlight={!sortSpoofByPopular}
            bold={!sortSpoofByPopular}
            color="indigo.600"
          >
            {' Latest '}
          </Text>
        </Button>
      </Row>
    </HStack>
  );
};

export default SpoofOrderingWidget;
