import { createSlice } from '@reduxjs/toolkit';

export const gameFontStateSlice = createSlice({
  name: 'gameFontState',
  initialState: {
    value: '2xl',
  },
  reducers: {
    setGameFontState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGameFontState } = gameFontStateSlice.actions;

export default gameFontStateSlice.reducer;
