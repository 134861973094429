import React, { useEffect, useState } from 'react';
import { LogBox, Platform } from 'react-native';
import { ScrollView, Box, Divider, Text, Button } from 'native-base';

import { useSelector } from 'react-redux';

import QuoteDetails from './QuoteDetails';
import QuoteDetailedInfo from './QuoteDetailedInfo';

import SpoofList from './SpoofList';
import TopSpoofView from './TopSpoofView';
import { getQuotebyID } from './db/Query';

const RevealScreen = ({ route }) => {
  const user = useSelector((state) => state.authState.userToken);
  const [quoteData, setQuoteData] = useState(route.params);
  // if sortPopular==true then use higher likeCount in tweak
  // if not then it means that you sort by createTime(latest first)
  const [seeAllTweaks, setSeeAllTweaks] = useState(false);

  // { quoteID, quote, by, details, reference, likes, partialData } = route.params;
  useEffect(() => {
    if (quoteData.partialData) {
      getQuotebyID(quoteData.quoteID, setQuoteData);
      console.log('Reveal Screen made query and got more data');
    }
  }, [quoteData]);
  
  const { quoteID, quote, by, details, reference, likes, topSpoof } = quoteData;

  useEffect(() => {
    if (Platform.OS == 'android' || Platform.OS == 'ios')
      LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, []);

  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      {!seeAllTweaks && (
        <Box alignItems="center" alignSelf="center" m={5}></Box>
      )}
      <QuoteDetails
        quoteID={quoteID}
        quote={quote}
        by={by}
        original={true}
        likeCount={likes}
      />
      {(details || reference) && (
        <QuoteDetailedInfo details={details} reference={reference} />
      )}
      {!(details || reference) && (
        <Box alignItems="center" alignSelf="center" mt={5}></Box>
      )}

      {seeAllTweaks && (
        <SpoofList key={quoteID} quoteID={quoteID} userID={user.fbUID} />
      )}
      {!seeAllTweaks && topSpoof && <TopSpoofView quote={topSpoof} />}
      <Divider mx={8} my={2} alignSelf="center" />
      {!seeAllTweaks && (
        <Button size="lg" variant="link" onPress={() => setSeeAllTweaks(true)}>
          <Text fontSize="md" color="indigo.600">
            {' See all fun Tweaks '}
          </Text>
        </Button>
      )}
    </ScrollView>
  );
};

export default RevealScreen;
