import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  HStack,
  VStack,
  Avatar,
  Text,
  Pressable,
  Spacer,
  Switch,
  Icon,
} from 'native-base';
import { useSpring, animated, config } from 'react-spring';
import { MaterialIcons } from '@expo/vector-icons';

import { setGameModeState } from './redux/reducers/GameModeState';
import { avatarMap } from './App';

export default function ProfileLink() {
  const user = useSelector((state) => state.authState.userToken);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const score = useSelector((state) => state.scoreState.value) || 10;
  const [oldScore, setOldScore] = useState(score);
  const SText = animated(Text);

  const { number } = useSpring({
    from: { number: oldScore },
    number: score,
    delay: 200,
    config: config.molasses,
  });

  // the cleanup function is called before the new value is applied (not just on unload)
  useEffect(() => {
    return () => setOldScore(score);
  }, [score]);

  const avatarKey = useSelector((state) => state.authState.avatarKey);

  return (
    <HStack mr={2} justifyContent="center" alignItems="center">
      <Spacer />
      <VStack alignItems="center" w={20}>
        <Text m={0} color="white" fontSize="xs">
          Score
        </Text>
        <SText color="white" fontSize="sm">
          {number.to((n) => n.toFixed(0))}
        </SText>
      </VStack>
      <Spacer size={2} />
      <Pressable onPress={() => navigation.push('Profile', {})} ml={3}>
        <Avatar
          mx={1}
          bg="indigo.600"
          alignSelf="center"
          size="sm"
          source={avatarMap[avatarKey]}
          borderColor="indigo.600"
          borderWidth={0.5}
          key={avatarKey}
        ></Avatar>
      </Pressable>
    </HStack>
  );
}
