import React from 'react';
import { Share } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';

import { Icon, useToast, Pressable, Text } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';

import { onShare } from './ShareWidget';

const MyShare = (props) => {
  const showShare = useSelector((state) => state.shareState.value);
  const user = useSelector((state) => state.authState.userToken);
  const dispatch = useDispatch();
  const toast = useToast();

  const { subject, message, url, textOnly } = props;

  if (showShare) {
    if (!!textOnly) {
      return (
        <Pressable
          zIndex={1}
          elevation={1}
          onPress={() =>
            onShare(
              {
                message: message,
                url: url || 'gameofquotes.app',
                title: subject,
              },
              { subject: subject },
              toast,
              user
            )
          }
        >
          <Text
            style={{ fontFamily: 'Lato_400Regular' }}
            fontWeight={500}
            color="indigo.600"
            bold
          >
            Share
          </Text>
        </Pressable>
      );
    }
    return (
      <Icon
        onPress={() =>
          onShare(
            {
              message: message,
              url: url || 'gameofquotes.app',
              title: subject,
            },
            { subject: subject },
            toast,
            user
          )
        }
        m={1}
        as={<FontAwesome name="share-alt" />}
        color="darkBlue.600"
        size="md"
      />
    );
  } else {
    return null;
  }
};

export default MyShare;
