import React from 'react';
import { Row, Image, Pressable, Text, Button, Icon } from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import { Lato_100Thin, Lato_700Bold } from '@expo-google-fonts/lato';

const NextButtonPanel = ({ reQuery, reveal }) => {
  return (
    <Row justifyContent="space-around">
      <Button
        size="md"
        variant="outline"
        _text={{ color: 'indigo.500', fontFamily: 'Lato_700Bold' }}
        onPress={reveal}
      >
        {' Show Answer '}
      </Button>

      <Button
        size="md"
        bg="indigo.500"
        _text={{ color: 'white', fontFamily: 'Lato_700Bold' }}
        endIcon={<Icon as={AntDesign} name="caretright" size="sm" />}
        onPress={reQuery}
      >
        {'  Next Quote '}
      </Button>
    </Row>
  );
};

export default NextButtonPanel;
