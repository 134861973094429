import React, { useEffect, useState, useCallback, useContext } from 'react';
import { LogBox, Platform } from 'react-native';
import {
  ScrollView,
  Divider,
  Spinner,
  Button,
  Icon,
  Row,
  StatusBar,
  Text,
  Box,
} from 'native-base';
import { AntDesign } from '@expo/vector-icons';
import { useSelector } from 'react-redux';

import QuoteDetails from './QuoteDetails';
import QuoteDetailedInfo from './QuoteDetailedInfo';
import TopSpoofView from './TopSpoofView';
import {
  quoteQuery,
  userSeenQuery,
  updateQuoteSeen,
  getLastUnseenNxn,
  clearLastSeenNxn,
  getShowAdsFlags,
} from './db/Query.js';
import SpoofList from './SpoofList';
import { SplashContext, GlobalXStateMachineContext } from './App.js';
import SlideNxn from './common/SlideNxn';
import { sendAnalyticsEventAsync } from './analytics';
import ScreenSizeModal from './common/ScreenSizeModal';
import FontControl from './common/FontControl';
import GoogleWebAds from './Ads/GoogleWebAds';

const QuoteScreen = ({ route }) => {
  const user = useSelector((state) => state.authState.userToken);
  const popularityValue = useSelector((state) => state.popularityState.value);
  const [initQuote, setInitQuote] = useState({ id: '0' }); // to trigger on id updates
  const [quotesSeen, setQuotesSeen] = useState(null);
  const [lastUnseenNxn, setLastUnseenNxn] = useState(null);
  const [showNotif, setShowNotif] = useState(false);
  const [seeAllTweaks, setSeeAllTweaks] = useState(false);
  const splashContext = useContext(SplashContext);
  const userInterests = useSelector((state) => state.interestState.value);
  const [showAdsFlags, setShowAdsFlags] = useState({
    web: false,
    mobile: false,
  });

  const { send } = useContext(GlobalXStateMachineContext).scoreService;
  const smSend = send;

  const { quote, by, details, reference, likes, topSpoof } =
    initQuote.id != 0 ? initQuote.data() : {};

  const quote_id = initQuote.id;

  useEffect(() => {
    if (Platform.OS == 'android' || Platform.OS == 'ios') {
      LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
    }
    async function prepare() {
      try {
        await userSeenQuery(user.fbUID, quotesSeen, setQuotesSeen);
        getLastUnseenNxn(user.fbUID, setLastUnseenNxn);
        getShowAdsFlags(setShowAdsFlags);
      } catch (e) {
        console.warn(e);
      } finally {
        // Tell the application to render
        splashContext.setAppFinallyReady(true);
      }
    }
    prepare();
  }, []);

  useEffect(() => {
    if (lastUnseenNxn) {
      setShowNotif(true);
      clearLastSeenNxn(user.fbUID);
      sendAnalyticsEventAsync('inapp_nxn_shown_quote');
    }
  }, [lastUnseenNxn]);

  // using a id:0 as initial state so that we can trigger on id changes.
  useEffect(() => {
    if (quote_id != '0') {
      smSend({ type: 'QUOTE_MODE_NEXT', quote_id, userid: user.fbUID });
      sendAnalyticsEventAsync('next_quote_quote');
    }
  }, [quote_id]);

  useEffect(() => {
    if (quotesSeen != null) {
      quoteQuery([...quotesSeen], userInterests, popularityValue, setInitQuote);
    }
    // we are not updating the quote seen database entry here to avoid one extra DB write
    //return () => setInitQuote({ id: '0' });
  }, [quotesSeen]);

  const showNext = () => {
    // first update the database and then set it locally
    updateQuoteSeen(user.fbUID, [...quotesSeen, initQuote.id]);
    setQuotesSeen([...quotesSeen, initQuote.id]);
    setSeeAllTweaks(false);
  };

  if (!initQuote) {
    return <Spinner size="lg" />;
  }

  const stateOfScreen = { qs: quotesSeen, iq: initQuote.id };
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <StatusBar
        barStyle="light-content"
        backgroundColor="#6366f1"
        translucent
      />
      <FontControl />

      <Box
        alignItems="center"
        alignSelf="center"
        m={seeAllTweaks ? 2 : 5}
      ></Box>

      <QuoteDetails
        quoteID={initQuote.id}
        quote={quote}
        by={by}
        original={true}
        likeCount={likes}
      />
      {showNotif && (
        <SlideNxn
          showNotif={showNotif}
          setShowNotif={setShowNotif}
          lastUnseenNxn={lastUnseenNxn}
        />
      )}

      <Row justifyContent="space-around" alignItems="center">
        <Button
          size="md"
          bg="indigo.500"
          _text={{ color: 'white', fontFamily: 'Lato_700Bold' }}
          endIcon={<Icon as={AntDesign} name="caretright" size="sm" />}
          onPress={showNext}
        >
          {'   Next  '}
        </Button>
      </Row>
      {Platform.OS === 'web' && showAdsFlags.web && (
        <GoogleWebAds slot="9532683238" googleAdId="ca-pub-2275181520818760" />
      )}
      {(details || reference) && (
        <QuoteDetailedInfo details={details} reference={reference} />
      )}
      {!(details || reference) && (
        <Box alignItems="center" alignSelf="center" mt={5}></Box>
      )}
      {seeAllTweaks && (
        <SpoofList
          key={initQuote.id}
          quoteID={initQuote.id}
          userID={user.fbUID}
        />
      )}
      {!seeAllTweaks && topSpoof && <TopSpoofView quote={topSpoof} />}
      <Divider mx={8} my={2} alignSelf="center" />
      {!seeAllTweaks && (
        <Button size="lg" variant="link" onPress={() => setSeeAllTweaks(true)}>
          <Text fontSize="md" color="indigo.600">
            {' See all fun Tweaks '}
          </Text>
        </Button>
      )}
      <ScreenSizeModal />
    </ScrollView>
  );
};

export default QuoteScreen;
