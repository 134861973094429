import React from 'react';
import {
  HStack,
  Popover,
  Text,
  Link,
  QuestionIcon,
  Box,
  Icon,
  Pressable,
  Button,
} from 'native-base';
import { FontAwesome5 } from '@expo/vector-icons';

export default function LinkWithPopupTip({
  mainText,
  actionFun,
  helpText,
  headerText,
  underline = true,
  textColor = 'indigo.600',
}) {
  return (
    <HStack flex={1} justifyContent="flex-start" alignItems="center">
      {mainText && (
        <Link onPress={actionFun}>
          <Text
            style={{ fontFamily: 'Lato_400Regular' }}
            fontWeight={500}
            color={textColor}
            underline={underline}
            bold
          >
            {mainText}
          </Text>
        </Link>
      )}
      <Box mx={5}>
        <Popover
          trigger={(triggerProps) => {
            return (
              <Pressable {...triggerProps}>
                <Icon
                  as={<FontAwesome5 name="question-circle" />}
                  size={6}
                  color={textColor}
                />
              </Pressable>
            );
          }}
        >
          <Popover.Content w="56">
            <Popover.Arrow />
            <Popover.CloseButton />
            <Popover.Header>{headerText || 'Sign In'}</Popover.Header>
            <Popover.Body>{helpText}</Popover.Body>
          </Popover.Content>
        </Popover>
      </Box>
    </HStack>
  );
}
