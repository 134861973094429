import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import {
  Center,
  Container,
  Heading,
  Text,
  Box,
  Radio,
  Checkbox,
  useTheme,
  ScrollView,
  Row,
  HStack,
  AlertDialog,
  Button,
  Switch,
  Slider,
} from 'native-base';
import { firebaseApp, auth } from './firebase';
import { signOut } from 'firebase/auth';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { setUserToken, setAvatarKey } from './redux/reducers/AuthState';
import { setGameModeState } from './redux/reducers/GameModeState';
import { setRevealConfirmState } from './redux/reducers/RevealConfirmState';
import { setInterestState } from './redux/reducers/InterestState';
import { setPopularityState } from './redux/reducers/PopularityState';
import {
  setAllState,
  setQod,
  setTcLive,
  setTcUp,
} from './redux/reducers/NxnState';
import {
  updateUserCategoryInterest,
  updateUserNxnStatus,
  updateSignInStatus,
} from './db/Query';
import LinkWithPopupTip from './LinkWithPopupTip';
import { FIREBASE_UID_KEY } from './RootScreen';

export default function Settings() {
  const { colors } = useTheme();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const groupValues = useSelector((state) => state.interestState.value);
  const user = useSelector((state) => state.authState.userToken);
  const popularityValue = useSelector((state) => state.popularityState.value);
  const allNxn = useSelector((state) => state.nxnState.all);
  const qodNxn = useSelector((state) => state.nxnState.qod);
  const tcliveNxn = useSelector((state) => state.nxnState.tclive);
  const tcupNxn = useSelector((state) => state.nxnState.tcup);
  const [isOpen, setIsOpen] = useState(false);

  const showConfirmOnReveal = useSelector(
    (state) => state.revealConfirmState.value
  );

  const onClose = () => setIsOpen(false);

  let updateNxnTimeoutID = null;

  useEffect(() => {
    if (updateNxnTimeoutID) clearTimeout(updateNxnTimeoutID);
    if (user && user.fbUID) {
      updateNxnTimeoutID = setTimeout(() => {
        updateUserNxnStatus(user.fbUID, {
          all: allNxn,
          qod: qodNxn,
          tclive: tcliveNxn,
          tcup: tcupNxn,
        });
      }, 5000);
    }
  }, [allNxn, qodNxn, tcliveNxn, tcupNxn]);

  useEffect(() => {
    if (qodNxn && tcliveNxn && tcupNxn) dispatch(setAllState(true));
    if (!qodNxn && !tcliveNxn && !tcupNxn) dispatch(setAllState(false));
  }, [qodNxn, tcliveNxn, tcupNxn]);

  return (
    <ScrollView showsHorizontalScrollIndicator={false}>
      <Center my={2}>
        <Container w="100%">
          <Box
            borderColor={colors.gray['300']}
            borderRadius={5}
            borderWidth={1}
            w="90%"
            mt={2}
            p={4}
          >
            <Heading size="md" my={2}>
              Account Settings
            </Heading>
            {user.type === 'anon' && (
              <>
                <Text>
                  {' '}
                  You do not seem to have an account on this device. If you have
                  an account already you can Sign In or Sign Up to save your
                  progress and use it on multiple devices.
                </Text>
                <HStack
                  justifyContent="space-around"
                  alignSelf="center"
                  w="100%"
                  mt={2}
                >
                  <LinkWithPopupTip
                    mainText="Sign In"
                    helpText="If you are already enjoying GOQ and have an account from another device you can now sign in here"
                    actionFun={() => setIsOpen(!isOpen)}
                  />
                  <LinkWithPopupTip
                    mainText="Sign Up"
                    helpText="While you do not need to create an account to use GOQ, you may create an account to enjoy GOQ on multiple devices or change devices"
                    actionFun={() =>
                      navigation.navigate('Sign Up', { userExisted: true })
                    }
                  />
                </HStack>
              </>
            )}
            {user.type === 'email' && (
              <>
                <Text>{`You are signed in with ${user.email}.\n\n`}</Text>
                <HStack
                  justifyContent="space-around"
                  alignSelf="center"
                  w="100%"
                  mt={2}
                >
                  <LinkWithPopupTip
                    mainText="Sign Out"
                    helpText={`You are signed in with ${user.email}. \nSign Out and then you can Sign In again, your progress is safe with us.`}
                    actionFun={async () => {
                      await updateSignInStatus(user.fbUID, false);
                      dispatch(setAvatarKey(''));
                      dispatch(setUserToken({ fbUID: null }));
                      signOut(auth).finally(() => {
                        AsyncStorage.removeItem(FIREBASE_UID_KEY);
                      });
                    }}
                  />
                </HStack>
              </>
            )}
          </Box>

          <Box
            borderColor={colors.gray['300']}
            borderRadius={5}
            borderWidth={1}
            w="90%"
            mt={2}
            p={4}
          >
            <HStack>
              <Heading size="md" my={2}>
                Quote Popularity
              </Heading>
              <LinkWithPopupTip
                mainText=""
                headerText="Popularity slider"
                helpText={`You can select how popular or arcane Quotes you want to see. Slide to the right to get only super popular Quotes, slide left to see all kind of quotes`}
                actionFun={() => {}}
              />
            </HStack>
            <Slider
              defaultValue={popularityValue}
              colorScheme="indigo"
              onChangeEnd={(v) => dispatch(setPopularityState(v))}
            >
              <Slider.Track>
                <Slider.FilledTrack />
              </Slider.Track>
              <Slider.Thumb />
            </Slider>
            <HStack justifyContent="space-between">
              <Text fontSize="xs">low</Text>
              <Text fontSize="xs">high</Text>
            </HStack>
          </Box>
          <Box
            borderColor={colors.gray['300']}
            borderRadius={5}
            borderWidth={1}
            w="90%"
            mt={2}
            p={4}
          >
            <Heading size="md" my={2}>
              Categories
            </Heading>
            <Checkbox.Group
              onChange={(v) => {
                updateUserCategoryInterest(user.fbUID, v);
                dispatch(setInterestState(v));
              }}
              value={groupValues}
              accessibilityLabel="choose numbers"
            >
              <Checkbox value="tag_inspirational" my={2} colorScheme="indigo">
                <Text>Inspirational</Text>
              </Checkbox>
              <Checkbox value="tag_insightful" my={2} colorScheme="indigo">
                <Text>Insightful</Text>
              </Checkbox>
              <Checkbox value="tag_loveromance" my={2} colorScheme="indigo">
                <Text>Love and Romance</Text>
              </Checkbox>
              <Checkbox value="tag_contemporary" my={2} colorScheme="indigo">
                <Text>Contemporary</Text>
              </Checkbox>
              <Checkbox value="tag_political" my={2} colorScheme="indigo">
                <Text>Political</Text>
              </Checkbox>
              <Checkbox value="tag_health_related" my={2} colorScheme="indigo">
                <Text>Health Related</Text>
              </Checkbox>
              <Checkbox value="tag_funny" my={2} colorScheme="indigo">
                <Text>Funny</Text>
              </Checkbox>
              <Checkbox value="tag_classic" my={2} colorScheme="indigo">
                <Text>Classic</Text>
              </Checkbox>
              <Checkbox value="tag_spiritual" my={2} colorScheme="indigo">
                <Text>Spiritual</Text>
              </Checkbox>
              <Checkbox value="tag_satire" my={2} colorScheme="indigo">
                <Text>Satire</Text>
              </Checkbox>
              <Checkbox value="tag_motivational" my={2} colorScheme="indigo">
                <Text>Motivational</Text>
              </Checkbox>
            </Checkbox.Group>
          </Box>
          <Box
            borderColor={colors.gray['300']}
            borderRadius={5}
            borderWidth={1}
            w="90%"
            mt={2}
            p={4}
          >
            <Heading size="md" my={2}>
              Notification Settings
            </Heading>
            <Row justifyContent="flex-start" alignItems="center" mb={2}>
              <Switch
                key={23}
                onValueChange={(c) => {
                  dispatch(setAllState(c));
                }}
                value={allNxn}
                isChecked={allNxn}
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={allNxn ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3d3d3d"
                size="md"
              />
              <Text mx={2}>All Notifications </Text>
            </Row>
            <Box
              h={1}
              borderBottomColor={colors.gray['300']}
              borderBottomWidth={1}
              mb={2}
            />
            <Row justifyContent="flex-start" alignItems="center" ml={4} mb={2}>
              <Switch
                key={24}
                onValueChange={(c) => {
                  dispatch(setQod(c));
                }}
                isChecked={qodNxn}
                value={qodNxn}
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={qodNxn ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3d3d3d"
                size="md"
              />
              <Text fontSize="xs" mx={2}>
                Quote of the day{' '}
              </Text>
            </Row>
            <Row justifyContent="flex-start" alignItems="center" ml={4} mb={2}>
              <Switch
                key={24}
                onValueChange={(c) => {
                  dispatch(setTcLive(c));
                }}
                value={tcliveNxn}
                isChecked={tcliveNxn}
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={tcliveNxn ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3d3d3d"
                size="md"
              />
              <Text fontSize="xs" mx={2}>
                tweak/comment is live{' '}
              </Text>
            </Row>
            <Row justifyContent="flex-start" alignItems="center" ml={4} mb={2}>
              <Switch
                key={24}
                onValueChange={(c) => {
                  dispatch(setTcUp(c));
                }}
                value={tcupNxn}
                isChecked={tcupNxn}
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={tcupNxn ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3d3d3d"
                size="md"
              />
              <Text fontSize="xs" mx={2}>
                tweak/comment upvoted{' '}
              </Text>
            </Row>
          </Box>

          <Box
            borderColor={colors.gray['300']}
            borderRadius={5}
            borderWidth={1}
            w="90%"
            mt={2}
            p={4}
          >
            <Row justifyContent="center" alignItems="center">
              <Switch
                key={22}
                onValueChange={(c) => dispatch(setRevealConfirmState(c))}
                value={showConfirmOnReveal}
                isChecked={showConfirmOnReveal}
                trackColor={{ false: '#767577', true: '#81b0ff' }}
                thumbColor={showConfirmOnReveal ? '#f5dd4b' : '#f4f3f4'}
                ios_backgroundColor="#3d3d3d"
                size="md"
              />
              <Text mx={2}>Confirm on Reveal/Next </Text>
            </Row>
          </Box>
        </Container>
        <AlertDialog isOpen={isOpen} onClose={onClose}>
          <AlertDialog.Content>
            <AlertDialog.CloseButton />
            <AlertDialog.Header>Sign In</AlertDialog.Header>
            <AlertDialog.Body>
              You seem to have some data on this device. You may want to Sign Up
              to save this, if you Sign In to your other account this local data
              can not be recovered.
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button.Group space={2}>
                <Button
                  variant="unstyled"
                  colorScheme="coolGray"
                  onPress={onClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme="danger"
                  onPress={() => {
                    navigation.navigate('Sign In', { userExisted: true });
                    onClose();
                  }}
                >
                  Continue
                </Button>
              </Button.Group>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      </Center>
    </ScrollView>
  );
}
