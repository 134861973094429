import React, { useState } from 'react';
import { Text, Box, Link, Stack, useTheme } from 'native-base';
import { LayoutAnimation, TouchableWithoutFeedback } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import ExpandableView from './ExpandableView';

const QuoteDetailedInfo = ({ details, reference }) => {
  const [detailsExpanded, setDetailsExpanded] = useState(false);
  const { colors } = useTheme();
  return (
    <Box
      bg="white"
      shadow={2}
      rounded="lg"
      maxWidth="90%"
      width="90%"
      alignItems="center"
      alignSelf="center"
      m={5}
    >
      <Stack space={2} p={[2, 2, 4]} justifyContent="center" width="100%">
        <ExpandableView
          style={{
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flex: 1,
          }}
          expanded={detailsExpanded}
          size={18}
          color={colors.gray['400']}
          onTapped={(v) => {
            LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
            setDetailsExpanded(v);
          }}
        >
          <Box
            maxWidth="90%"
            alignItems="center"
            alignSelf="center"
            width="90%"
          >
            <TouchableWithoutFeedback
              onPress={() => {
                LayoutAnimation.configureNext(LayoutAnimation.Presets.linear);
                setDetailsExpanded(!detailsExpanded);
              }}
            >
              <Text
                fontSize={'md'}
                p={1}
                px={2}
                style={{ fontFamily: 'Lato_400Regular' }}
                fontWeight={600}
                fontStyle="normal"
                color="warmGray.500"
              >
                Explanation by AI
              </Text>
            </TouchableWithoutFeedback>
          </Box>
        </ExpandableView>
        {detailsExpanded && (
          <Text
            fontSize={'md'}
            p={5}
            style={{ fontFamily: 'Lato_400Regular' }}
            fontWeight={400}
            fontStyle="normal"
            color="warmGray.500"
          >
            {details}
          </Text>
        )}

        {reference && (
          <Link
            _text={{
              color: 'blue.800',
              fontSize: 'sm',
            }}
            href={reference}
            isExternal
            mt={4}
          >
            {reference}
          </Link>
        )}
      </Stack>
    </Box>
  );
};

export default QuoteDetailedInfo;
