import {
  Slide,
  Box,
  Stack,
  Text,
  Heading,
  IconButton,
  CloseIcon,
  useSafeArea,
} from 'native-base';
import { Pressable } from 'react-native';
import { StackActions, useNavigation } from '@react-navigation/native';

import { sendAnalyticsEventAsync } from '../analytics';

const SlideNxn = ({ showNotif, setShowNotif, lastUnseenNxn }) => {
  const navigation = useNavigation();
  const messageText = lastUnseenNxn ? lastUnseenNxn.text : '';
  const safeAreaProps = useSafeArea({
    safeAreaTop: true,
  });
  return (
    <Slide in={showNotif && !!lastUnseenNxn} placement="top" duration={1000}>
      <Box
        p="4"
        marginY={2}
        marginX={2}
        rounded="xl"
        bg="amber.400"
        {...safeAreaProps}
        flexDirection="row"
        alignItems="center"
      >
        <Box flex={5} justifyContent="center">
          <Pressable
            onPress={() => {
              setShowNotif(false);
              if (lastUnseenNxn.type === 'qod') {
                navigation.dispatch(
                  StackActions.push('Reveal', {
                    quoteID: lastUnseenNxn.id,
                    partialData: true,
                    screenTitle: 'Quote of the day',
                  })
                );
              } else if (lastUnseenNxn.type === 'tweaklive') {
                navigation.dispatch(
                  StackActions.push('Reveal', {
                    quoteID: lastUnseenNxn.id,
                    partialData: true,
                    screenTitle: 'See your Tweak here!',
                  })
                );
              }
              sendAnalyticsEventAsync('inapp_nxn_tapped', {
                type: lastUnseenNxn.type,
              });
            }}
          >
            <Box
              _text={{
                color: 'white',
              }}
              justifyContent="center"
              bg="amber.400"
            >
              <Stack space={2}>
                <Heading size="sm" ml="-1" color="black">
                  {lastUnseenNxn && lastUnseenNxn.type === 'qod'
                    ? 'Quote of the day'
                    : 'Your tweak is live!'}
                </Heading>
                <Text
                  fontSize="sm"
                  color="black"
                  fontWeight="500"
                  ml="-0.5"
                  mt="-1"
                  italic
                >
                  {messageText}
                </Text>
              </Stack>
            </Box>
          </Pressable>
        </Box>
        <Box
          bg="amber.400"
          flex={1}
          alignItems="flex-end"
          justifyContent="center"
        >
          <IconButton
            onPress={() => setShowNotif(false)}
            icon={<CloseIcon size="sm" color="red.600" />}
          />
        </Box>
      </Box>
    </Slide>
  );
};

export default SlideNxn;
