import firebaseConfig from './firebase-config.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
//import 'firebase/compat/auth';

import { getAuth } from 'firebase/auth';

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore(firebaseApp);
try {
  firestore.enablePersistence();
} catch (e) {
  console.error(e);
}
const auth = getAuth(firebaseApp);

export { firebase, firebaseApp, firestore, auth };
