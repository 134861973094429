import 'react-native-gesture-handler';
import React, { useState, createContext, useEffect } from 'react';
import { Provider } from 'react-redux';

import { NavigationContainer } from '@react-navigation/native';
import { useFonts } from 'expo-font';
import { IndieFlower_400Regular as IndieFlower } from '@expo-google-fonts/indie-flower';
import {
  Lato_100Thin,
  Lato_100Thin_Italic,
  Lato_300Light,
  Lato_300Light_Italic,
  Lato_400Regular,
  Lato_400Regular_Italic,
  Lato_700Bold,
  Lato_700Bold_Italic,
  Lato_900Black,
  Lato_900Black_Italic,
} from '@expo-google-fonts/lato';
import { NativeBaseProvider } from 'native-base';
import * as SplashScreen from 'expo-splash-screen';
import { useInterpret } from '@xstate/react';
import * as Sentry from 'sentry-expo';
import SentryConfig from './sentry-config';
import { createMachine } from 'xstate';

import { stateDef, actionDef } from './db/state_machine/XStateMachineDef';
import store from './redux/store';
import theme from './theme.js';
import RootScreen from './RootScreen.js';

const avatars = require('./assets/avatars');
const avatarMap = {
  a0: avatars.a0,
  a1: avatars.a1,
  a2: avatars.a2,
  a3: avatars.a3,
  a4: avatars.a4,
  a5: avatars.a5,
  a6: avatars.a6,
  a7: avatars.a7,
  a8: avatars.a8,
  a9: avatars.a9,
  a10: avatars.a10,
  a11: avatars.a11,
  a12: avatars.a12,
  a13: avatars.a13,
  a14: avatars.a14,
  a15: avatars.a15,
  a16: avatars.a16,
  a17: avatars.a17,
};

const config = {
  dependencies: {
    // For Expo projects (Bare or managed workflow)
    'linear-gradient': require('expo-linear-gradient').LinearGradient,
    // For non expo projects
    // 'linear-gradient': require('react-native-linear-gradient').default,
  },
};

const SplashContext = createContext();
const GlobalXStateMachineContext = createContext({});
const scoreStateMachine = createMachine(stateDef, actionDef);

export default function App() {
  const [appFinallyReady, setAppFinallyReady] = useState(false);
  const scoreService = useInterpret(scoreStateMachine);

  console.log('APP CALLLLLED!! ');
  useEffect(() => {
    async function hideSplash() {
      if (appFinallyReady) {
        await SplashScreen.hideAsync();
      }
    }
    hideSplash();
  }, [appFinallyReady]);

  useEffect(() => {
    console.log('APP useEffect called again');
    async function showSplash() {
      if (!appFinallyReady) {
        await SplashScreen.preventAutoHideAsync();
      }
    }
    showSplash();
  }, []);

  Sentry.init(SentryConfig);

  const [fontsLoaded, error] = useFonts({
    IndieFlower,
    Lato_100Thin,
    Lato_100Thin_Italic,
    Lato_300Light,
    Lato_300Light_Italic,
    Lato_400Regular,
    Lato_400Regular_Italic,
    Lato_700Bold,
    Lato_700Bold_Italic,
    Lato_900Black,
    Lato_900Black_Italic,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <Provider store={store}>
      <NavigationContainer>
        <NativeBaseProvider theme={theme} config={config}>
          <SplashContext.Provider value={{ setAppFinallyReady }}>
            <GlobalXStateMachineContext.Provider value={{ scoreService }}>
              <RootScreen />
            </GlobalXStateMachineContext.Provider>
          </SplashContext.Provider>
        </NativeBaseProvider>
      </NavigationContainer>
    </Provider>
  );
}

export { SplashContext, GlobalXStateMachineContext, avatarMap };
