import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Image,
  FlatList,
  Spinner,
  Heading,
  Flex,
} from 'native-base';

import { getApprovedTweaksByUser } from './db/Query';
import { Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function OUP({ userID, nickname }) {
  const navigation = useNavigation();

  const renderItem = ({ item }) => {
    if (!!!item.tweak) {
      return null;
    }

    return (
      <Pressable
        onPress={() => {
          navigation.push('Reveal', {
            quoteID: item.quoteID,
            partialData: true,
            screenTitle: 'Quote',
          });
        }}
      >
        <Box
          bg="gray.200"
          shadow={3}
          rounded="lg"
          width="90%"
          maxWidth="90%"
          alignItems="center"
          alignSelf="center"
          m={2}
        >
          <Text fontSize="md" italic p={1} mt={2}>
            {item.tweak}
          </Text>
        </Box>
      </Pressable>
    );
  };

  const [tweaks, setTweaks] = useState(null);

  useEffect(() => {
    getApprovedTweaksByUser(userID, setTweaks);
  }, []);
  // Tweaks, here
  // ID: refers to the tweak ID(which will be used in both main collections)
  // quoteID: is the original quote of which this tweak about
  // { ID, quoteID }

  if (!tweaks) {
    return <Spinner size="lg" />;
  }

  const listHeader = () => {
    return (
      <Heading size="md" m="2">
        Tweaks by {nickname}
      </Heading>
    );
  };
  //const tweaks = [];
  if (tweaks.length === 0) {
    return (
      <>
        <Text my={5}>Sorry, {nickname} doesn't have any tweaks now!</Text>
        <Image
          source={require('./assets/shrug.png')}
          alt="Shrug"
          size={'lg'}
          alignSelf="center"
        />
      </>
    );
  } else {
    return (
      <Flex alignSelf="center" h={'96'}>
        <FlatList
          data={tweaks}
          renderItem={renderItem}
          keyExtractor={(item) => item.ID}
          scrollEnabled={true}
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={listHeader}
          style={{ flexGrow: 0 }}
          contentContainerStyle={{ flexGrow: 1 }}
        />
      </Flex>
    );
  }
}
