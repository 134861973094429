import React from 'react';
import {
  Text,
  Box,
  useTheme,
  Stack,
  Badge,
  Avatar,
  VStack,
  Pressable,
} from 'native-base';
import { useNavigation } from '@react-navigation/native';

import SpoofQuoteActionPanel from './SpoofQuoteActionPanel';
import { avatarMap } from './App';

const SpoofQuoteDetails = ({
  quote,
  by,
  likeFun, // add 1
  dislikeFun, // subtract 1
  likeStatus, // -1, 0 ,1
  likeCount,
  dislikeCount,
  deleteFun,
}) => {
  const { colors } = useTheme();
  const navigation = useNavigation();
  return (
    <Box
      bg="coolGray.100"
      shadow={2}
      width="90%"
      maxWidth="90%"
      alignItems="center"
      alignSelf="center"
      my={1}
    >
      <Stack space={4} p={[4, 4, 4]} justifyContent="center" width="100%">
        <Text
          fontSize={'md'}
          p={2}
          mt={2}
          style={{ fontFamily: 'Lato_400Regular_Italic' }}
          fontWeight={400}
          fontStyle="italic"
          color="warmGray.500"
        >
          {quote}
        </Text>

        <VStack
          position="absolute"
          left={2}
          bottom={2}
          maxW="45%"
          alignItems="flex-start"
        >
          <Pressable
            onPress={() =>
              navigation.push('Profile', {
                userID: by.authId,
                screenTitle: by.authNickname,
              })
            }
            hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
          >
            <Avatar
              bg="indigo.600"
              size="xs"
              source={avatarMap[by.authAvatarKey]}
              borderColor="indigo.600"
              borderWidth={0.1}
              key={by.authAvatarKey}
              alignSelf="center"
            />
          </Pressable>
          <Pressable
            onPress={() =>
              navigation.push('Profile', {
                userID: by.authId,
                screenTitle: by.authNickname,
              })
            }
            hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
          >
            <Text
              style={{ fontFamily: 'Lato_400Regular' }}
              fontWeight={400}
              fontSize="sm"
              color="darkBlue.500"
              w="100%"
              alignSelf="center"
            >
              {by.authNickname}
            </Text>
          </Pressable>
        </VStack>

        <Badge
          position="absolute"
          top={2}
          right={2}
          variant="subtle"
          colorScheme="warning"
        >
          tweak
        </Badge>

        <SpoofQuoteActionPanel
          width={['50%', '40%', '35%']}
          position="absolute"
          bottom={2}
          right={0}
          likeFun={likeFun}
          dislikeFun={dislikeFun}
          likeStatus={likeStatus}
          likeCount={likeCount}
          deleteFun={deleteFun}
          dislikeCount={dislikeCount}
          quoteText={`"${quote}" - (remixed) by ${by.authNickname}\n\nSee more remixes and original quotes at https://gameofquotes.app`}
          quoteURL={'gameofquotes.app'}
        />
      </Stack>
    </Box>
  );
};

export default SpoofQuoteDetails;
