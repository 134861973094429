import { firebase } from '../firebase';

const increment = firebase.firestore.FieldValue.increment(1);
const decrement = firebase.firestore.FieldValue.increment(-1);

const increment10 = firebase.firestore.FieldValue.increment(10);
const increment5 = firebase.firestore.FieldValue.increment(5);
const increment3 = firebase.firestore.FieldValue.increment(3);
const increment2 = firebase.firestore.FieldValue.increment(2);

export default {
  DEFAULT_SCORE: 10,
  SEE_QUOTE_GAME_MODE: increment,
  SEE_QUOTE_QUOTE_MODE: increment,
  LIKED_QUOTE: increment2,
  LIKED_DISLIKED_TWEAK: increment,
  CORRECT_QUOTE: increment10,
  WRONG_QUOTE: increment,
  CONSOLATION: increment,
  REVEAL_CORRECT: increment,
  TWEAK_ACCEPTED: increment10,
  SHARE: increment5,
  YOUR_TWEAK_LIKED: increment5,
  YOUR_TWEAK_DISLIKED: decrement,
};

// This is used not for scores but count of likes/dislikes
export { increment, decrement };
