import React from 'react';
import { ScrollView, Avatar, Pressable, VStack, HStack } from 'native-base';
import { useDispatch, useSelector } from 'react-redux';

import { setAvatarKey } from './redux/reducers/AuthState';
import { avatarMap } from './App';
import { updateAvatar } from './db/Query';

const AvComponent = ({ avatarKey }) => {
  const dispatch = useDispatch();
  const selectedKey = useSelector((state) => state.authState.avatarKey);
  const user = useSelector((state) => state.authState.userToken);
  return (
    <Pressable
      onPress={() => {
        dispatch(setAvatarKey(avatarKey));
        updateAvatar(user.fbUID, avatarKey);
      }}
    >
      <Avatar
        mx={4}
        bg="indigo.600"
        alignSelf="center"
        size="xl"
        source={avatarMap[avatarKey]}
        borderColor="indigo.600"
        borderWidth={selectedKey === avatarKey ? 3 : 0.5}
      />
    </Pressable>
  );
};

const AvatarSelection = (props) => {
  return (
    <ScrollView showsVerticalScrollIndicator={false}>
      <VStack my={2}>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a0" />
          <AvComponent avatarKey="a10" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a1" />
          <AvComponent avatarKey="a2" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a3" />
          <AvComponent avatarKey="a4" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a5" />
          <AvComponent avatarKey="a6" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a7" />
          <AvComponent avatarKey="a8" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a9" />
          <AvComponent avatarKey="a11" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a12" />
          <AvComponent avatarKey="a13" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a14" />
          <AvComponent avatarKey="a15" />
        </HStack>
        <HStack justifyContent="space-evenly" my={4}>
          <AvComponent avatarKey="a16" />
          <AvComponent avatarKey="a17" />
        </HStack>
      </VStack>
    </ScrollView>
  );
};

export default AvatarSelection;
