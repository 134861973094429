import React, { useContext, useCallback, useState } from 'react';
import {
  NativeBaseProvider,
  Box,
  Heading,
  VStack,
  HStack,
  FormControl,
  Input,
  Button,
  Text,
  StatusBar,
} from 'native-base';
import { useDispatch, useSelector } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';

import { SplashContext } from './App.js';
import { setUserToken, setAvatarKey } from './redux/reducers/AuthState';
import LinkWithPopupTip from './LinkWithPopupTip';
import { signInAnonymouslyIfNicknameAvailable } from './db/Query';
import { FIREBASE_UID_KEY } from './RootScreen';

const generateUsername = () => {
  return 'random-name';
};

export default function WelcomeScreen({ navigation }) {
  const dispatch = useDispatch();
  const [nickName, setNickName] = useState(generateUsername());
  const [nickNameError, setNickNameError] = useState('default-before-trying');
  const interests = useSelector((state) => state.interestState.value);

  const saveLocal = async (user) => {
    try {
      await AsyncStorage.setItem(FIREBASE_UID_KEY, JSON.stringify(user));
    } catch (error) {
      console.error(error);
    }
  };

  const nickNameFun = (result, user, avatarKey) => {
    if (result) {
      saveLocal(user);
      console.log('New anonymous user careted ', user);
      setNickNameError('no-error');
      dispatch(setUserToken(user));
      dispatch(setAvatarKey(avatarKey));
    } else setNickNameError('error');
  };

  const showNickNameText = () => {
    if (nickNameError == 'error') {
      return (
        <Text fontSize="sm" color="danger.800">
          This nickname is already taken, please try again.
        </Text>
      );
    } else if (nickNameError == 'looking') {
      return (
        <Text fontSize="sm" color="success.800">
          Looking up availability for {nickName}, hold on...!
        </Text>
      );
    } else if (nickNameError == 'no-error') {
      // this is almost a no-op because there is a global state change due to dispatch
      // and this will result in the Root Screen getting changed. So the Welcome may
      // just show for a fraction of a second.
      return (
        <Text fontSize="sm" color="success.800">
          Welcome {nickName} !
        </Text>
      );
    } else if (nickNameError == 'default-before-trying') {
      return (
        <Text fontSize="xs" color="gray.600">
          *use suggested or choose your own nickname
        </Text>
      );
    } else {
      return <Text fontSize="sm"> </Text>;
    }
  };
  const splashContext = useContext(SplashContext);

  const onLayoutWelcomeView = useCallback(async () => {
    // hide the splash screen as soon as the layout is done
    splashContext.setAppFinallyReady(true);
  }, []);

  return (
    <NativeBaseProvider>
      <Box
        safeArea
        flex={1}
        p="2"
        w="90%"
        mx="auto"
        py="8"
        onLayout={onLayoutWelcomeView}
      >
        <StatusBar barStyle="light-content" backgroundColor="#6366f1" />
        <Heading
          size="lg"
          color="coolGray.800"
          fontWeight={500}
          fontFamily="heading"
        >
          Welcome to Game of Quotes!
        </Heading>
        <Heading
          mt="1"
          color="coolGray.600"
          fontWeight="medium"
          size="xs"
          fontFamily="body"
        >
          Just so that we can say hello, choose a nickname to proceed
        </Heading>

        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label
              _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}
            >
              Nickname
            </FormControl.Label>
            <Input
              maxLength={20}
              value={nickName}
              onChangeText={setNickName}
              onFocus={() => setNickNameError('not-set')}
            />
          </FormControl>
          {showNickNameText()}
          <Button
            mt="2"
            maxW="80%"
            alignSelf="flex-end"
            colorScheme="indigo"
            _text={{ color: 'white' }}
            onPress={() => {
              setNickNameError('looking');
              signInAnonymouslyIfNicknameAvailable(
                nickName.trim(),
                interests,
                nickNameFun
              );
            }}
          >
            Proceed
          </Button>
        </VStack>
        <Text fontFamily="body" mt="10">
          Or optionally you may ...
        </Text>
        <HStack
          justifyContent="space-around"
          alignSelf="center"
          mt="10"
          w="100%"
        >
          <LinkWithPopupTip
            mainText="Sign In"
            helpText="If you are already enjoying GOQ and have an account from another device you can now sign in here"
            actionFun={() =>
              navigation.navigate('Sign In', { userExisted: false })
            }
          />
          <LinkWithPopupTip
            mainText="Sign Up"
            helpText="While you do not need to create an account to use GOQ, you may create an account to enjoy GOQ on multiple devices or change devices"
            actionFun={() =>
              navigation.navigate('Sign Up', { userExisted: false })
            }
          />
        </HStack>
      </Box>
    </NativeBaseProvider>
  );
}
