//https://docs.expo.dev/workflow/glossary-of-terms/#platform-extensions
import { firebase } from './firebase';
import 'firebase/compat/analytics';
const webAnalytics = firebase.analytics();

export async function sendAnalyticsEventAsync(message) {
  webAnalytics.logEvent(message);
}

/**
 * @param name Event name must not conflict with any Reserved Events.
 * @param params Parameters to be sent and displayed with the event.
 * @param options Additional options that can be passed. Web only.
 logEvent(
    name: string,
    params?: { [key: string]: any },
    options?: AnalyticsCallOptions,
  ): Promise<void>;
 */