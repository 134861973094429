import React from 'react';
import { Share } from 'react-native';
import { addToUserScore } from '../db/Query';
import Scores from '../db/ScoreConstants';

const onShare = async (content, options, toast, user) => {
  try {
    const result = await Share.share(content, options);
    if (!!result && !!result.action) {
      if (result.action === Share.sharedAction) {
        // shared
        addToUserScore(user.fbUID, Scores.SHARE);
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    }
  } catch (error) {
    //dispatch(setShareState(false));
    toast.show({
      title: 'Unable to share!',
      status: 'error',
      description: 'Sorry, it seems your browser does not support sharing',
      backgroundColor: 'indigo.200',
    });
  }
};

export { onShare };
