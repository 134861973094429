import * as React from 'react';
import { useState } from 'react';
import {
  NativeBaseProvider,
  Box,
  Heading,
  VStack,
  FormControl,
  Input,
  Button,
  Text,
  Link,
  KeyboardAvoidingView,
  Spinner,
} from 'native-base';
import { useDispatch } from 'react-redux';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { CommonActions } from '@react-navigation/native';

import { setUserToken, setAvatarKey } from './redux/reducers/AuthState';
import { setInterestState } from './redux/reducers/InterestState';
import { FIREBASE_UID_KEY } from './RootScreen';
import { firebaseApp, auth } from './firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { getUserDetailsFromDb, updateSignInStatus } from './db/Query';

export default function SignInScreen({ route, navigation }) {
  const { userExisted } = route.params;
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const submitHandler = () => {
    setShowSpinner(true);
    setError(null);
    console.log(email, password);
    if (!email || !password) {
      setError('Some fields left blank, please check');
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then(async ({ user }) => {
        //console.log('Signed in with ', user);
        // Signed in with email. Promise returns UserCredentials. user is firebase.User
        // Now get user details from DB
        await getUserDetailsFromDb(user.uid, callbackFun);
        // Need to do this here in an await block due to some Firestore race condition
        await updateSignInStatus(user.uid, true);
      })
      .catch((error) => {
        console.log(error);
        callbackFun(-3, null, null, null, 'Unable to sign in ' + error.message);
      })
      .finally(() => setShowSpinner(false));
  };

  const errorText = (err) => (
    <Text fontSize="sm" color="danger.800">
      {err}
    </Text>
  );

  const callbackFun = (result, user, avatarKey, interests, errmsg) => {
    if (result === 0) {
      //console.log('Interests received in callback ', interests);
      saveLocal(user);
      setError(null);
      dispatch(setAvatarKey(avatarKey));
      dispatch(setInterestState(interests));
      dispatch(setUserToken(user));
      if (userExisted) {
        navigation.goBack();
        navigation.dispatch(
          CommonActions.navigate({
            name: 'Quotes',
          })
        );
      }
    } else {
      setError('Cannot sign in. ' + errmsg);
    }
  };

  const saveLocal = async (user) => {
    try {
      await AsyncStorage.setItem(FIREBASE_UID_KEY, JSON.stringify(user));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <NativeBaseProvider>
      {showSpinner && <Spinner size="lg" alignSelf="center" />}
      <KeyboardAvoidingView safeArea flex={1} p="2" w="90%" mx="auto" py="8">
        <Heading size="lg" color="coolGray.800" fontWeight="600">
          Welcome back!
        </Heading>
        <Heading mt="1" color="coolGray.600" fontWeight="medium" size="xs">
          If you already have an account then sign in to continue!
        </Heading>

        <VStack space={3} mt="5">
          <FormControl>
            <FormControl.Label
              _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}
            >
              Email
            </FormControl.Label>
            <Input onChangeText={setEmail} value={email} />
          </FormControl>
          <FormControl>
            <FormControl.Label
              _text={{ color: 'muted.700', fontSize: 'xs', fontWeight: 500 }}
            >
              Password
            </FormControl.Label>
            <Input
              type="password"
              onChangeText={setPassword}
              value={password}
            />
          </FormControl>
          <Link onPress={() => navigation.navigate('Forgot')}>
            Forgot password?
          </Link>
          <Button
            mt="2"
            maxW="80%"
            alignSelf="flex-end"
            colorScheme="indigo"
            _text={{ color: 'white' }}
            onPress={submitHandler}
          >
            Sign In
          </Button>
          {error && errorText(error)}
        </VStack>
      </KeyboardAvoidingView>
    </NativeBaseProvider>
  );
}
