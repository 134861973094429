import React, { useState, useEffect, useCallback } from 'react';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
  FlatList,
  Text,
  Center,
  Pressable,
  Row,
  Avatar,
  VStack,
  ArrowUpIcon,
  ArrowDownIcon,
  Box,
  Skeleton,
} from 'native-base';
import { useSelector } from 'react-redux';
import { firestore } from './firebase';

import { getLeaderboard } from './db/Query';
import { avatarMap } from './App';
import LinkWithPopupTip from './LinkWithPopupTip';

export default function HighScoresScreen() {
  const navigation = useNavigation();
  const [result, setResult] = useState({ lb: [], ts: 0 });
  const [combinedResult, setCombinedResult] = useState([]);
  const [minutesAgo, setMinutesAgo] = useState(30);
  const thisUserId = useSelector((state) => state.authState.userToken).fbUID;
  const thisAvatarKey = useSelector((state) => state.authState.avatarKey);
  const thisScore = useSelector((state) => state.scoreState.value);

  useFocusEffect(
    useCallback(() => {
      getLeaderboard(setResult);
    }, [thisScore])
  );

  useEffect(() => {
    const { lb, ts } = result;
    if (lb.length > 0) {
      let index = lb.findIndex((e) => e.userId === thisUserId);
      if (index >= 0) {
        lb[index].nickname = 'You';
        setCombinedResult(lb.sort((a, b) => b.score > a.score));
      } else {
        setCombinedResult(
          [
            ...lb,
            {
              userId: thisUserId,
              nickname: 'You',
              score: thisScore,
              avatarKey: thisAvatarKey,
              rank: null,
              direction: 'up',
            },
          ].sort((a, b) => b.score > a.score)
        );
      }
      const diffMs = Math.abs(ts.seconds * 1000 - new Date().getTime());
      setMinutesAgo(Math.floor(diffMs / 1000 / 60));
    }
  }, [result.ts]);

  const nth = (n) => {
    return n + (['st', 'nd', 'rd'][((((n + 90) % 100) - 10) % 10) - 1] || 'th');
  };

  const renderItem = ({ item }) => {
    return (
      <Center width="100%" flex={1}>
        <Pressable
          flex={1}
          width="100%"
          onPress={() => {
            navigation.push('Profile', {
              userID: item.userId,
              avatarKey: item.avatarKey,
              screenTitle: item.nickname,
            });
          }}
        >
          <Row
            bg="gray.200"
            alignSelf="center"
            flex={1}
            width="100%"
            //justifyContent="space-evenly"
            my={1}
            rounded="2xl"
            borderColor="indigo.600"
            borderWidth={item.userId === thisUserId ? 1 : 0}
          >
            <VStack
              justifyContent="center"
              alignItems="center"
              mx={1}
              width="15%"
            >
              {item.rank && (
                <Text fontSize="sm" bold>
                  {nth(item.rank)}
                </Text>
              )}
              {item.direction === 'up' && (
                <ArrowUpIcon color="green.600" size={3} />
              )}
              {item.direction === 'down' && (
                <ArrowDownIcon color="red.600" size={3} />
              )}
            </VStack>

            <Avatar
              mx={4}
              my={2}
              bg="indigo.600"
              alignSelf="center"
              size="md"
              source={avatarMap[item.avatarKey]}
              key={item.avatarKey}
            />

            <Text
              fontSize="md"
              p={1}
              alignSelf="center"
              bold
              color="indigo.700"
            >
              {item.nickname}
            </Text>
            <Row justifyContent="flex-end" flex={1} mx={2}>
              <Text fontSize="sm" p={1} alignSelf="center" color="red.600">
                {item.score}
              </Text>
            </Row>
          </Row>
        </Pressable>
      </Center>
    );
  };

  const combinedResult1 = [
    {
      userId: 'HzaMAmKMeMXMAJyIsRbWLBvo1iz2',
      nickname: 'Nasir',
      score: 2314,
      avatarKey: 'a1',
      rank: 1,
      direction: 'up',
    },
    {
      userId: 'OSnUgYjXzNNhe9cIYKDTwiV8ZoA3',
      nickname: 'Neena',
      score: 1234,
      avatarKey: 'a2',
      rank: 2,
      direction: 'down',
    },
    {
      userId: 'QsZaIR4hMbPPRbCUvN9hMgdJkO73',
      nickname: 'Ashir',
      score: 3421,
      avatarKey: 'a3',
      rank: 3,
      direction: 'up',
    },
    {
      userId: thisUserId,
      nickname: 'YOU',
      score: thisScore,
      avatarKey: thisAvatarKey,
      rank: 7,
      direction: 'up',
    },
  ];

  if (combinedResult.length === 0) {
    console.log('combined result is empty');
    return (
      <VStack w="90%" alignItems="center" mx={4}>
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
        <Skeleton h={10} w="100%" m={2} my={4} rounded="md" />
      </VStack>
    );
  } else {
    return (
      <Center m={2} flex={1}>
        <Row m={2} my={1} width="90%" justifyContent="space-between">
          <Box alignSelf="flex-start">
            <LinkWithPopupTip
              mainText=""
              actionFun={() => {}}
              helpText="Top scores show up here.  You can add to your score in many ways 
                 e.g. complete a quote in Game mode, suggest a tweak to a quote, like or share a quote/tweak, or just interact with quotes"
              headerText="Top scores"
              underline={false}
            />
          </Box>
          <VStack alignSelf="flex-end">
            <Text
              borderColor="indigo.600"
              borderWidth={1}
              borderRadius={5}
              p={1}
              alignSelf="flex-end"
            >
              Updated {minutesAgo} minutes ago
            </Text>
            <Text
              italic
              color="gray.500"
              p={1}
              fontSize="sm"
              alignSelf="flex-end"
            >
              Updated hourly
            </Text>
          </VStack>
        </Row>
        <FlatList
          width="90%"
          maxWidth="90%"
          mt={4}
          data={combinedResult}
          renderItem={renderItem}
          keyExtractor={(item) => item.userId}
          scrollEnabled={true}
          showsVerticalScrollIndicator={false}
        />
      </Center>
    );
  }
}
