import React, { useState, useEffect, userRef } from 'react';
import { useSelector } from 'react-redux';
import { useWindowDimensions, Platform } from 'react-native';
import {
  Box,
  HStack,
  Text,
  Spinner,
  ScrollView,
  CheckIcon,
  Button,
  Center,
  KeyboardAvoidingView,
  VStack,
  StatusBar,
  Flex,
  Divider,
  TextArea,
  Toast,
  Actionsheet,
  Icon,
  useToast,
  useTheme,
} from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

import { onShare } from './ShareWidget';
import LinkWithPopupTip from '../LinkWithPopupTip';
import QuoteAction from './QuoteAction';
import { getQuoteDocbyID, addTweakForReview } from '../db/Query';
import { sendAnalyticsEventAsync } from '../analytics';

const resultImage = (correct) => {
  if (correct) {
    return (
      <VStack alignItems="center" m={2}>
        <HStack justifyContent="center" alignItems="center" space={2} mt={4}>
          <CheckIcon size="5" mt="0.5" color="emerald.600" />
          <Text
            style={{ fontFamily: 'Lato_400Regular' }}
            fontWeight={800}
            fontStyle="bold"
            color="emerald.600"
            fontSize="lg"
          >
            This is not a Tweak! This is what the author said.
          </Text>
        </HStack>
      </VStack>
    );
  }
};

const TweakGameStandalone = ({ route }) => {
  const selections = ['Fill Blank', 'Free Form'];
  const [selected, setSelected] = useState(0);
  const [initQuote, setInitQuote] = useState(null);
  useEffect(() => {
    async function useGivenQuoteId() {
      try {
        const { quoteID } = route.params;
        getQuoteDocbyID(quoteID, setInitQuote);
      } catch (e) {
        console.warn(e);
      }
    }
    if (route && route.params && route.params.quoteID) {
      useGivenQuoteId();
    }
  }, []);
  const wrapHText = (i) => (
    <Button
      colorScheme="indigo"
      variant={selected == i ? 'solid' : 'outline'}
      onPress={() => setSelected(i)}
      rounded="md"
      alignItems="center"
      minW={16}
      minH={10}
    >
      {selections[i]}
    </Button>
  );
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
      alignSelf="center"
    >
      <Flex
        direction="row"
        minH={10}
        p="2"
        mb={2}
        mt={2}
        justifyContent="center"
      >
        {wrapHText(0)}
        <Divider bg="indigo.600" thickness="4" mx="8" orientation="vertical" />
        {wrapHText(1)}
      </Flex>
      {selected === 0 && <TweakFibScreen initQuote={initQuote} />}
      {selected === 1 && <TweakFreeScreen initQuote={initQuote} />}
    </KeyboardAvoidingView>
  );
};

const TweakFibScreen = ({ initQuote }) => {
  const user = useSelector((state) => state.authState.userToken);
  const [resultComposite, setResultComposite] = useState({
    showResult: false,
    showSuccessImage: false,
    userQuote: '',
  });

  const { height } = useWindowDimensions();
  const { showResult, showSuccessImage } = resultComposite;

  if (!!!initQuote) {
    return (
      <Center safeArea>
        <Spinner alignSelf="center" size="lg" />
      </Center>
    );
  }

  return (
    <Box safeArea alignSelf="center" maxW="90%" maxH="95%">
      <StatusBar
        barStyle="light-content"
        backgroundColor="#6366f1"
        translucent
      />
      <ScrollView
        height={height * 0.9}
        showsVerticalScrollIndicator={false}
        mb={16}
      >
        {initQuote && (
          <QuoteAction
            initQuote={initQuote}
            resultComposite={resultComposite}
            setResultComposite={setResultComposite}
            user={user}
            mode="tweakonly"
          />
        )}
        {showResult && resultImage(showSuccessImage)}
      </ScrollView>
    </Box>
  );
};

const TweakFreeScreen = ({ initQuote }) => {
  const [showASheet, setShowASheet] = useState(false);
  const [tweakSubmitted, setTweakSubmitted] = useState(false);
  const user = useSelector((state) => state.authState.userToken);
  const avatarKey = useSelector((state) => state.authState.avatarKey);
  const [charRemaining, setCharRemaining] = useState(0);
  const [maxLength, setMaxLength] = useState(0);
  const { height } = useWindowDimensions();
  const [value, setValue] = useState('');
  const { colors } = useTheme();
  const toast = useToast();

  useEffect(() => {
    if (initQuote) {
      setMaxLength(initQuote.data().quote.length + 20);
      setCharRemaining(initQuote.data().quote.length + 20);
    }
  }, [initQuote]);

  const textTruncate = function (str, length) {
    if (length == null) {
      length = 200;
    }
    if (str.length > length) {
      return str.substring(0, length);
    } else {
      return str;
    }
  };
  const handleChange = (text) => {
    const fixedText = textTruncate(text, maxLength);
    setValue(fixedText);
    setCharRemaining(maxLength - fixedText.length);
  };

  const submitTweakFun = () => {
    if (value && value.length > 0) {
      addTweakForReview(
        initQuote.id,
        user.fbUID,
        user.nickname,
        avatarKey,
        value
      );
      Toast.show({
        title: 'Submitted Tweak for review',
        status: 'success',
        duration: 4000,
        description:
          'We will review your submission and it may show up in fun tweaks',
        placement: 'top',
        style: {
          backgroundColor: colors.indigo['200'],
          zIndex: 2,
          elevation: 2,
        },
      });
      setTweakSubmitted(true);
      sendAnalyticsEventAsync('tweak_submitted_for_review');
    }
  };

  if (!!!initQuote) {
    return (
      <Center safeArea>
        <Spinner alignSelf="center" size="lg" />
      </Center>
    );
  }
  return (
    <Box
      safeArea
      alignSelf="center"
      w="90%"
      maxH="95%"
      alignItems="center"
      justifyContent="center"
    >
      <StatusBar
        barStyle="light-content"
        backgroundColor="#6366f1"
        translucent
      />
      <ScrollView
        height={height * 0.9}
        showsVerticalScrollIndicator={false}
        w="95%"
        mb={16}
      >
        {initQuote && (
          <Box alignItems="center" mt={2} mb={16}>
            <Text
              style={{ fontFamily: 'IndieFlower' }}
              fontWeight={100}
              fontStyle="normal"
              fontSize="2xl"
            >
              {initQuote.data().quote}
            </Text>
            <Box alignItems="center" mt={2} w="100%">
              <TextArea
                value={value}
                w="100%"
                onChangeText={handleChange}
                placeholder="Your free form Tweak"
                numberOfLines={4}
              />
            </Box>
            <Text
              fontSize="sm"
              fontStyle="italic"
              color="gray.400"
              alignSelf="flex-end"
            >
              Characters remaining : {charRemaining}
            </Text>
            <Button
              alignSelf="flex-end"
              bg="amber.400"
              _text={{
                color: 'indigo.700',
                fontWeight: 'bold',
                fontSize: 'md',
              }}
              mt={4}
              onPress={() => {
                if (value && value.length > 0) {
                  setShowASheet(true);
                  setTweakSubmitted(false);
                } else {
                  Toast.show({
                    title: 'Nothing to save',
                    status: 'error',
                    duration: 2000,
                    placement: 'bottom',
                    style: {
                      backgroundColor: colors.indigo['200'],
                      zIndex: 2,
                      elevation: 2,
                    },
                  });
                }
              }}
              endIcon={
                <Icon
                  as={<MaterialIcons name="save" />}
                  color="indigo.700"
                  size="md"
                  mr={1}
                />
              }
            >
              Save
            </Button>
            <Actionsheet
              isOpen={showASheet}
              onClose={() => setShowASheet(false)}
              size="full"
            >
              <Actionsheet.Content bgColor="amber.400">
                <Actionsheet.Item
                  bgColor="amber.400"
                  startIcon={
                    <Icon
                      as={<MaterialIcons name="share" />}
                      color="black"
                      mr={3}
                    />
                  }
                  onPress={() => {
                    if (value && value.length > 0) {
                      onShare(
                        {
                          message: `"${value}" - \n\nSee more quotes at https://gameofquotes.app`,
                          url: 'gameofquotes.app',
                          title: 'Checkout my tweak',
                        },
                        { subject: 'Checkout my tweak' },
                        toast
                      );
                      sendAnalyticsEventAsync(
                        'shared_tweak_from_free_standalone'
                      );
                    }
                  }}
                >
                  <Text
                    style={{ fontFamily: 'Lato_400Regular' }}
                    fontWeight={500}
                    color="black"
                    bold
                  >
                    Share
                  </Text>
                </Actionsheet.Item>
                {!tweakSubmitted && (
                  <Actionsheet.Item
                    bgColor="amber.400"
                    startIcon={
                      <Icon
                        as={<MaterialIcons name="send" />}
                        color="black"
                        mr={3}
                      />
                    }
                    onPress={submitTweakFun}
                  >
                    <LinkWithPopupTip
                      mainText="Submit Tweak"
                      headerText="Submit Tweak"
                      helpText="Submit your 'tweak' for review, if accepted it will show in tweaks!"
                      underline={false}
                      screenName=" "
                      textColor="black"
                      actionFun={() => {}}
                    />
                  </Actionsheet.Item>
                )}
                <Actionsheet.Item
                  bgColor="amber.400"
                  onPress={() => setShowASheet(false)}
                  startIcon={
                    <Icon
                      as={<MaterialIcons name="close" />}
                      color="black"
                      mr={3}
                    />
                  }
                >
                  <Text
                    style={{ fontFamily: 'Lato_400Regular' }}
                    fontWeight={500}
                    color="black"
                    bold
                  >
                    Close
                  </Text>
                </Actionsheet.Item>
              </Actionsheet.Content>
            </Actionsheet>
          </Box>
        )}
      </ScrollView>
    </Box>
  );
};
export default TweakGameStandalone;
