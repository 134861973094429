import React from 'react';

import { HStack, Icon, Factory, VStack, Text, Pressable } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';

import MyShare from './common/Share';
import CountFormatComponent from './CountFormatComponent';

const SpoofQuoteActionPanelPlain = (props) => {
  const {
    likeFun,
    dislikeFun,
    likeStatus,
    quoteText,
    quoteURL,
    likeCount,
    dislikeCount,
    deleteFun,
  } = props;
  return (
    <HStack
      {...props}
      bg="coolGray.100"
      justifyContent="space-around"
      alignItems="flex-start"
    >
      {deleteFun && (
        <VStack alignItems="center">
          <Icon
            onPress={deleteFun}
            m={1}
            as={<FontAwesome name="trash" />}
            color="red.800"
            size="md"
          />
        </VStack>
      )}
      {likeFun && (
        <VStack alignItems="center">
          <Pressable
            onPress={likeFun}
            hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
            zIndex={1}
          >
            <Icon
              m={1}
              as={
                likeStatus > 0 ? (
                  <FontAwesome name="thumbs-up" />
                ) : (
                  <FontAwesome name="thumbs-o-up" />
                )
              }
              color="black"
              size="md"
            />
          </Pressable>
          <CountFormatComponent fontSize="2xs" count={likeCount} />
        </VStack>
      )}
      {dislikeFun && (
        <VStack alignItems="center">
          <Pressable
            onPress={dislikeFun}
            hitSlop={{ top: 12, bottom: 12, left: 12, right: 12 }}
            zIndex={1}
          >
            <Icon
              m={1}
              as={
                likeStatus < 0 ? (
                  <FontAwesome name="thumbs-down" />
                ) : (
                  <FontAwesome name="thumbs-o-down" />
                )
              }
              color="black"
              size="md"
            />
          </Pressable>
          <CountFormatComponent fontSize="2xs" count={dislikeCount} />
        </VStack>
      )}

      <MyShare
        subject="Enjoy this quote remix"
        message={quoteText}
        url={quoteURL}
      />
    </HStack>
  );
};

const SpoofQuoteActionPanel = Factory(SpoofQuoteActionPanelPlain);
export default SpoofQuoteActionPanel;
