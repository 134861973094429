import { configureStore } from '@reduxjs/toolkit';
import AsyncStorage from '@react-native-async-storage/async-storage';

import ShareStateReducer from './reducers/ShareState';
import AuthStateReducer from './reducers/AuthState';
import RevealConfirmState from './reducers/RevealConfirmState';
import InterestState from './reducers/InterestState';
import ScoreState from './reducers/ScoreState';
import BadgeState from './reducers/BadgeState';
import PopularityState from './reducers/PopularityState';
import NxnState from './reducers/NxnState';
import GameFontState from './reducers/GameFontState';

import { updateUserCategoryInterest } from '../db/Query';

// convert object to string and store in localStorage
const saveToLocalStorage = async (state) => {
  try {
    const serialisedState = JSON.stringify(state);
    await AsyncStorage.setItem('@goqState', serialisedState);
  } catch (e) {
    console.warn(e);
  }
};

const store = configureStore({
  reducer: {
    shareState: ShareStateReducer,
    authState: AuthStateReducer,
    revealConfirmState: RevealConfirmState,
    interestState: InterestState,
    scoreState: ScoreState,
    badgeState: BadgeState,
    popularityState: PopularityState,
    nxnState: NxnState,
    gameFontState: GameFontState,
  },
});

store.subscribe(() => saveToLocalStorage(store.getState()));
/* now we are instead updating from Root once and when it changes in settings
store.subscribe(() => {
  const state = store.getState();
  const user = state.authState.userToken;
  if (user && user.fbUID) {
    updateUserCategoryInterest(user.fbUID, state.interestState.value);
  }
});
*/
export default store;
