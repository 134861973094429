import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Divider,
  Flex,
  Spacer,
  Button,
  Image,
  FlatList,
  Spinner,
  View,
  useDisclose,
  Modal,
  HStack,
} from 'native-base';

import {
  getQuotesLikedByUser,
  getTweaksByUser,
  withdrawTweakFromReview,
  deleteRejectedTweakByUser,
} from './db/Query';
import { Pressable } from 'react-native';
import { useNavigation } from '@react-navigation/native';

export default function PCSP({ userID }) {
  const selections = ['Likes', 'Tweaks'];
  const [selected, setSelected] = useState(0);

  const wrapHText = (i) => (
    <Button
      colorScheme="indigo"
      variant={selected == i ? 'solid' : 'outline'}
      onPress={() => setSelected(i)}
      rounded="md"
      alignItems="center"
      minW={16}
      minH={10}
    >
      {selections[i]}
    </Button>
  );
  return (
    <Flex alignItems="center" width="90%">
      <Flex direction="row" minH={10} p="2">
        {wrapHText(0)}
        <Divider bg="indigo.600" thickness="4" mx="8" orientation="vertical" />
        {wrapHText(1)}
      </Flex>
      {selected === 0 && <LikesScreen userID={userID} />}
      {selected === 1 && <TweaksScreen userID={userID} />}
    </Flex>
  );
}

const LikesScreen = ({ userID }) => {
  const navigation = useNavigation();
  const renderItem = ({ item }) => {
    if (!!!item.quote) {
      return null;
    }
    return (
      <Pressable
        onPress={() => {
          navigation.push('Reveal', {
            quoteID: item.ID,
            quote: item.quote,
            by: item.by,
            partialData: true,
            screenTitle: 'Liked Quote',
          });
        }}
      >
        <Box
          bg="gray.100"
          shadow={2}
          rounded="lg"
          width="90%"
          maxWidth="90%"
          alignItems="center"
          alignSelf="center"
          m={2}
          p={2}
        >
          <Text fontSize="md" italic>
            {item.quote}
          </Text>
          <Text
            px={1}
            rounded="md"
            highlight
            fontSize="xs"
            alignSelf="flex-end"
          >
            {item.by}
          </Text>
        </Box>
      </Pressable>
    );
  };
  const [likedQuotes, setLikedQuotes] = useState(null);
  useEffect(() => {
    getQuotesLikedByUser(userID, setLikedQuotes);
  }, []);

  const likedQuotes1 = [
    { ID: 1, quote: 'Quote 1', by: 'Einstein' },
    {
      ID: 2,
      quote:
        '2 Slighly longer quote that can take some more spave to see how the card looks like',
      by: 'me',
    },
    {
      ID: 3,
      quote:
        '3 Even longer quote that can take some more space to see how the card looks like and the text si even  more longet than before',
      by: 'me',
    },
    { ID: 4, quote: '4 Quote 1', by: 'Einstein' },
    {
      ID: 5,
      quote:
        '5 Slighly longer quote that can take some more spave to see how the card looks like',
      by: 'me',
    },
    {
      ID: 6,
      quote:
        '6 Even longer quote that can take some more space to see how the card looks like and the text si even  more longet than before',
      by: 'me',
    },
    {
      ID: 7,
      quote:
        '7 Slighly longer quote that can take some more spave to see how the card looks like',
      by: 'me',
    },
    {
      ID: 8,
      quote:
        '8 Even longer quote that can take some more space to see how the card looks like and the text si even  more longet than before',
      by: 'me',
    },
  ];

  if (!likedQuotes) {
    return <Spinner size="lg" />;
  }
  if (likedQuotes.length === 0) {
    return (
      <>
        <Text my={5}>You haven't liked any quote so far!</Text>
        <Image
          source={require('./assets/broken_heart.png')}
          alt="Broken heart"
          size={'lg'}
          alignSelf="center"
        />
        <Spacer />
        <Text my={5}>Your liked quotes shall show here</Text>
      </>
    );
  } else {
    return (
      <Flex alignSelf="center" h={'96'}>
        <FlatList
          data={likedQuotes}
          renderItem={renderItem}
          keyExtractor={(item) => item.ID}
          scrollEnabled={true}
          showsVerticalScrollIndicator={false}
          style={{ flexGrow: 0 }}
          contentContainerStyle={{ flexGrow: 1 }}
        />
      </Flex>
    );
  }
};

const TweaksScreen = ({ userID }) => {
  const navigation = useNavigation();

  const renderItem = ({ item }) => {
    if (!!!item.tweak) {
      return null;
    }
    let topBgColor;
    let topText;
    let fun;
    switch (item.approved) {
      case 'approved':
        topBgColor = 'success.500';
        topText = 'Approved';
        fun = () => {
          navigation.push('Reveal', {
            quoteID: item.quoteID,
            partialData: true,
            screenTitle: 'Quote',
          });
        };
        break;
      case 'not-approved':
        topBgColor = 'error.500';
        topText = 'Not Approved';
        fun = () => {
          setNotAprovedReason(item.reason);
          naOnOpen();
        };
        break;
      default:
        topText = 'Under Review';
        topBgColor = 'indigo.400';
        fun = () => {
          urOnOpen();
        };
    }
    return (
      <Pressable onPress={fun}>
        <Box
          bg="gray.200"
          shadow={3}
          rounded="lg"
          width="90%"
          maxWidth="90%"
          alignItems="center"
          alignSelf="center"
          m={2}
        >
          <Flex direction="row" w="100%" roundedTop="lg" bg={topBgColor}>
            <Text color="white" fontSize="xs" ml={2} fontWeight="bold">
              {topText}
            </Text>
          </Flex>
          <Text fontSize="md" italic p={1} mt={2}>
            {item.tweak}
          </Text>
          {item.approved === 'under-review' && (
            <HStack flex="1" justifyContent="flex-end" alignSelf="flex-end">
              <Button
                size="sm"
                variant="link"
                colorScheme="secondary"
                onPress={() => {
                  withdrawTweakFromReview(userID, item.ID);
                  setTweaks(tweaks.filter((t) => t.ID != item.ID));
                }}
              >
                WITHDRAW
              </Button>
            </HStack>
          )}
          {item.approved === 'not-approved' && (
            <HStack flex="1" justifyContent="flex-end" alignSelf="flex-end">
              <Button
                size="sm"
                variant="link"
                colorScheme="secondary"
                onPress={() => {
                  deleteRejectedTweakByUser(userID, item.ID);
                  setTweaks(tweaks.filter((t) => t.ID != item.ID));
                }}
              >
                DELETE
              </Button>
            </HStack>
          )}
        </Box>
      </Pressable>
    );
  };

  const [tweaks, setTweaks] = useState(null);
  const [notApprovedReason, setNotAprovedReason] = useState('');

  // not approved modal controls
  const {
    isOpen: naIsOpen,
    onOpen: naOnOpen,
    onClose: naOnClose,
  } = useDisclose();

  // under review modal controls
  const {
    isOpen: urIsOpen,
    onOpen: urOnOpen,
    onClose: urOnClose,
  } = useDisclose();

  useEffect(() => {
    getTweaksByUser(userID, setTweaks);
  }, []);
  // Tweaks, here
  // ID: refers to the tweak ID(which will be used in both main collections)
  // quoteID: is the original quote of which this tweak about
  // approved: is the status (approved | not-approved | under-review)
  // reason: only populated for not-approved status
  // { ID, quoteID, approved, reason }
  const tweaks1 = [
    {
      ID: 'yGFfM62A8YFMFJUZeMIM',
      approved: 'approved',
      quoteID: 'h6fHPuTI7iEF1Ytf59nT',
      reason: 'Inappropriate content',
      tweak:
        'Try love everywhere you go Let no one ever come to you without leaving happier',
    },
    {
      ID: 2,
      tweak:
        '2 Slighly longer quote that can take some more spave to see how the card looks like',
      approved: 'not-approved',
      reason: 'This is not appropriate',
    },
    {
      ID: 3,
      tweak:
        '3 Even longer quote that can take some more space to see how the card looks like and the text si even  more longet than before',
      approved: 'under-review',
    },
    { ID: 4, quote: '4 Quote 1', by: 'Einstein' },
    {
      ID: 5,
      tweak:
        '5 Slighly longer quote that can take some more spave to see how the card looks like',
      approved: 'approved',
    },
    {
      ID: 6,
      tweak:
        '6 Even longer quote that can take some more space to see how the card looks like and the text si even  more longet than before',
      approved: 'not-approved',
      reason:
        'This is a common tweak and a version like this already appears as a tweak.',
    },
    {
      ID: 7,
      tweak:
        '7 Slighly longer quote that can take some more spave to see how the card looks like',
      approved: 'under-review',
    },
    {
      ID: 8,
      tweak:
        '8 Even longer quote that can take some more space to see how the card looks like and the text si even  more longet than before',
      approved: 'under-review',
    },
  ];

  if (!tweaks) {
    return <Spinner size="lg" />;
  }
  //const tweaks = [];
  if (tweaks.length === 0) {
    return (
      <>
        <Text my={5}>You haven't tweaked any quote so far!</Text>
        <Image
          source={require('./assets/shrug.png')}
          alt="Shrug"
          size={'lg'}
          alignSelf="center"
        />
        <Spacer />
        <Text my={5}>Your tweaks shall show here</Text>
      </>
    );
  } else {
    return (
      <Flex alignSelf="center" h="96">
        <FlatList
          data={tweaks}
          renderItem={renderItem}
          keyExtractor={(item) => item.ID}
          scrollEnabled={true}
          showsVerticalScrollIndicator={false}
          style={{ flexGrow: 0 }}
        />
        <Modal isOpen={naIsOpen} onClose={naOnClose}>
          <Modal.Content>
            <Modal.Header
              bg="error.600"
              _text={{
                fontSize: 'md',
                fontWeight: 'medium',
                color: 'warmGray.50',
                letterSpacing: 'lg',
              }}
            >
              Tweak not approved
            </Modal.Header>
            <Modal.Body>
              Unfortunately this tweak was not approved because :{' '}
              {notApprovedReason}
            </Modal.Body>
            <Modal.Footer>
              <Button colorScheme="error" onPress={naOnClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
        <Modal isOpen={urIsOpen} onClose={urOnClose}>
          <Modal.Content>
            <Modal.Header
              bg="indigo.500"
              _text={{
                fontSize: 'md',
                fontWeight: 'medium',
                color: 'warmGray.50',
                letterSpacing: 'lg',
              }}
            >
              Tweak under review
            </Modal.Header>
            <Modal.Body>
              This tweak is under review for content moderation, when approved
              it will automatically be added as a tweak.
            </Modal.Body>
            <Modal.Footer>
              <Button colorScheme="indigo" onPress={urOnClose}>
                OK
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      </Flex>
    );
  }
};
