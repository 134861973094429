import { createSlice } from '@reduxjs/toolkit';

export const shareStateSlice = createSlice({
  name: 'shareState',
  initialState: {
    value: true,
  },
  reducers: {
    setShareState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setShareState } = shareStateSlice.actions;

export default shareStateSlice.reducer;
