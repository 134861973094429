const nointernetquotes = [
  {
    id: 1,
    quote:
      'If you do not change direction, you may end up where you are heading',
    by: 'Lao Tzu',
  },
  {
    id: 2,
    quote: 'Life is a progress, and not a station',
    by: 'Ralph Waldo Emerson',
  },
  {
    id: 3,
    quote:
      'Happiness is like those palaces in fairytales whose gates are guarded by dragons: We must fight in order to conquer it',
    by: 'Alexander Dumas',
  },
  {
    id: 4,
    quote:
      'Learn from yesterday, live for today, hope for tomorrow. The important thing is not to stop questioning',
    by: 'Albert Einstein',
  },
  {
    id: 5,
    quote:
      'The best thing about the future is that it only comes one day at a time',
    by: 'Abraham Lincoln',
  },
  {
    id: 6,
    quote:
      'The only way to discover the limits of the possible is to go beyond them into the impossible',
    by: 'Ayn Rand',
  },
  {
    id: 7,
    quote:
      "The world is a dangerous place to live, not because of the people who are evil, but because of the people who don't do anything about it.",
    by: 'Albert Einstein',
  },
  {
    id: 8,
    quote:
      'Science is the great antidote to the poison of enthusiasm and superstition.',
    by: 'Adam Smith',
  },
  {
    id: 9,
    quote: "Don't cry because it's over, smile because it happened.",
    by: 'Dr. Seuss',
  },
  {
    id: 10,
    quote: 'Action is the foundational key to all success',
    by: 'Pablo Picasso',
  },
];

export default nointernetquotes;
