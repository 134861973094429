import { useNavigation } from '@react-navigation/native';
import { HStack, Icon } from 'native-base';

import { MaterialIcons } from '@expo/vector-icons';

const SearchLink = () => {
  const navigation = useNavigation();
  return (
    <HStack mr={2} ml={4} justifyContent="center" alignItems="center">
      <Icon
        as={MaterialIcons}
        name="search"
        color="white"
        size="lg"
        alignSelf="center"
        onPress={() => navigation.push('SearchScreen', {})}
      />
    </HStack>
  );
};

export default SearchLink;
