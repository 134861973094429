import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import {
  Text,
  Modal,
  Input,
  IconButton,
  Icon,
  CheckCircleIcon,
  Pressable,
  Row,
  Button,
} from 'native-base';
import { useSpring, animated, config } from 'react-spring';
import { FontAwesome5, FontAwesome } from '@expo/vector-icons';
import { useSelector } from 'react-redux';
import { GlobalXStateMachineContext } from './App';

const punctRE =
  /[\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-.\/:;<=>?@\[\]^_`{|}~]/g;
const spaceRE = /\s+/g;

const Quote = (props) => {
  const {
    setResultComposite,
    initQuote,
    resultComposite,
    userid,
    quoteId,
    forceReset,
    clearResult,
    mode,
  } = props;
  const [cleanedQuote, setCleanedQuote] = useState('');
  const [punctuatedQuote, setPunctuatedQuote] = useState('');
  const [modalVisible, setModalVisible] = useState(-1);
  const [focusIndex, setFocusIndex] = useState(-1);
  const [inputValue, setInputValue] = useState('');
  const [userTextMap, setUserTextMap] = useState({});
  const [blankCount, setBlankCount] = useState(0);
  const [blankVer, setBlankVer] = useState(1);
  const initialFocusRef = useRef(null);
  const { send } = useContext(GlobalXStateMachineContext).scoreService;
  const smSend = send;
  const SRow = animated(Row);
  const [sprops, api] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  const { showResult, showSuccessImage } = resultComposite;
  const checkButtonText = mode === 'game' ? 'Check Answer' : 'Submit';
  useEffect(() => {
    if (initQuote.quote) {
      clearResult();
      setPunctuatedQuote(usableQuote());
      const cleaned = cleanQuote(usableQuote());
      //if (cleanedQuote != cleaned) {
      setCleanedQuote(cleaned);
      const count = (cleaned.match(/qqqq/g) || []).length;
      setBlankCount(count);
      setUserTextMap({});
      //}
      smSend({ type: 'RESET', userid });
      api.start({
        from: { opacity: 0 },
        to: { opacity: 1 },
        config: { duration: 500 },
      });
    }
  }, [initQuote.quote, forceReset, blankVer]);

  useEffect(() => {
    setBlankVer(1);
  }, [initQuote.quote]);

  useEffect(() => {
    if (modalVisible == -1 && focusIndex == -1 && blankCount > 0) {
      checkResult();
    }
    if (modalVisible > -1) {
      clearResult();
    }
  }, [modalVisible, focusIndex]);

  const gameFontSize = useSelector((state) => state.gameFontState.value);

  const checkResult = () => {
    if (Object.keys(userTextMap).length === blankCount) {
      let resultQuote = makeResult();
      if (
        cleanQuote(resultQuote).toLowerCase() ==
        cleanQuote(initQuote.quote).toLowerCase()
      ) {
        // The correct reporting is now moved to Home as we do not
        // need to increment any score from anywhere other than Home
        //smSend({ type: 'CORRECT', userid, quote_id: quoteId });
        setResultComposite({
          ...resultComposite,
          showSuccessImage: true,
          showResult: true,
          userQuote: resultQuote,
        });
      } else {
        smSend({ type: 'WRONG', userid });
        setResultComposite({
          ...resultComposite,
          showSuccessImage: false,
          showResult: true,
          userQuote: resultQuote,
        });
      }
    }
  };

  const cleanQuote = (quote) =>
    quote.replace(punctRE, '').replace(spaceRE, ' ');

  const v2Quote = useMemo(() => {
    const quoteArr = initQuote.quote.split(' ');
    const qlength = quoteArr.length;
    const randomElement = Math.floor(Math.random() * qlength);
    let firstSwappableElement = randomElement;
    if (initQuote.v1.split(' ')[randomElement] === 'qqqq') {
      if (randomElement > 0) {
        firstSwappableElement = randomElement - 1;
      } else {
        firstSwappableElement = randomElement + 1;
      }
    }
    let secondSwappableElement = Math.floor(Math.random() * qlength);
    if (secondSwappableElement === firstSwappableElement) {
      for (let i = 0; i < qlength; i++) {
        secondSwappableElement = Math.floor(Math.random() * qlength);
        if (secondSwappableElement != firstSwappableElement) break;
      }
    }
    return quoteArr
      .map((e, i) => {
        if (i === firstSwappableElement || i === secondSwappableElement)
          return 'qqqq';
        else return e;
      })
      .join(' ');
  }, [initQuote.quote, blankVer]);

  // TODO this just returns any version, later we may use this as a
  // degree of difficulty
  const usableQuote = () => {
    if (initQuote) {
      if (blankVer == 1) return initQuote.v1;
      else return v2Quote;
    } else {
      return '';
    }
  };

  const makeResult = () => {
    // the keys here are the index of qqqq in the quote and the value if present
    // is the user entry for that placeholder.
    // Also at any given time modlaVisible var holds the index of word being edited
    let quoteArr = cleanedQuote.split(' ');
    return quoteArr
      .map((s, eIndex) => {
        if (s === 'qqqq') {
          if (userTextMap[eIndex]) {
            if (eIndex === modalVisible) {
              return '[' + userTextMap[eIndex] + ']';
            } else {
              return userTextMap[eIndex];
            }
          } else {
            if (eIndex === modalVisible) {
              return '[-------]';
            } else {
              return '..........';
            }
          }
        } else {
          return s;
        }
      })
      .join(' ');
  };

  const valueFn = (myIndex) => {
    //console.log('In valueFn myIndex = ', myIndex, ' focusIndex = ', focusIndex);
    if (myIndex === focusIndex) {
      return inputValue;
    } else {
      if (userTextMap[myIndex]) {
        return userTextMap[myIndex].trim();
      } else {
        return '';
      }
    }
  };
  const quoteBlankParser = (quote) => {
    let quoteArr = quote.split(' ');
    const qam = quoteArr.map((s, eIndex) => {
      if (s.indexOf('qqqq') >= 0) {
        if (userTextMap[eIndex]) {
          const fibWord = s.replace(/qqqq/, userTextMap[eIndex]);
          return (
            <Pressable
              onPress={() => {
                setModalVisible(eIndex);
              }}
              _pressed={{ opacity: 0.5 }}
              key={eIndex}
            >
              <Text
                style={{ fontFamily: 'IndieFlower' }}
                fontWeight={100}
                fontStyle="normal"
                fontSize={gameFontSize}
                underline
              >
                {fibWord}
              </Text>
            </Pressable>

            /*
            <Input
              value={valueFn(eIndex)}
              style={{ fontFamily: 'IndieFlower' }}
              variant="underlined"
              fontWeight={100}
              w="20"
              maxW="80%"
              fontStyle="normal"
              fontSize="3xl"
              onChangeText={setInputValue}
              onSubmitEditing={() => submitFn(eIndex)}
              onFocus={() => setFocusIndex(eIndex)}
              //onBlur={() => submitFn(eIndex)}
              key={eIndex}
            />
            */
          );
        }
        return fibWordBlankGen(s, eIndex);
      } else {
        return (
          <Text
            style={{ fontFamily: 'IndieFlower' }}
            fontWeight={100}
            fontStyle="normal"
            fontSize={gameFontSize}
            key={eIndex}
          >
            {s}
          </Text>
        );
      }
    });
    //console.log(qam);
    return qam;
  };

  const fibWordBlankGen = (s, eIndex) => {
    //const fibWordArr = s.split(/(?=[.,:;\"\'])|(?<=[.,:;\"\'])/g);
    const fibWordArr = s.split(/(?=[.,:;\"\'\?])/g);
    // https://medium.com/@shemar.gordon32/how-to-split-and-keep-the-delimiter-s-d433fb697c65
    //console.log('fibWordArr is ', fibWordArr);
    const retVal = [];
    for (var i = 0; i < fibWordArr.length; i++) {
      if (fibWordArr[i] != 'qqqq') {
        retVal.push(
          <Text
            style={{ fontFamily: 'IndieFlower' }}
            fontWeight={100}
            fontStyle="normal"
            fontSize="3xl"
          >
            {fibWordArr[i]}
          </Text>
        );
      } else {
        retVal.push(
          <Input
            style={{
              fontFamily: 'IndieFlower',
              borderBottomWidth: 1,
              //borderStyle: 'dashed',  //not working in iOS
              borderBottomColor: 'indigo',
            }}
            maxLength={100}
            variant="unstyled"
            autoCapitalize="none"
            autoCorrect={false}
            fontWeight={100}
            fontStyle="normal"
            fontSize="3xl"
            size="xs"
            w="30%"
            maxW="80%"
            p="0"
            value={valueFn(eIndex)}
            onFocus={() => setFocusIndex(eIndex)}
            onChangeText={setInputValue}
            ref={initialFocusRef}
            //maxLength={30}
            onBlur={() => submitFn(eIndex)}
            key={eIndex}
            onSubmitEditing={() => submitFn(eIndex)}
          />
        );
      }
    }
    //console.log(retVal);
    return retVal;
  };

  const submitFn = (eIndex) => {
    setUserTextMap({
      ...userTextMap,
      [eIndex]: inputValue.trimEnd(),
    });
    setFocusIndex(-1);
    setModalVisible(-1);
    setInputValue('');
  };

  const submitFnFromModal = () => {
    setUserTextMap({
      ...userTextMap,
      [modalVisible]: inputValue.trimEnd(),
    });
    setModalVisible(-1);
    setInputValue('');
  };

  const readyToCheck = () => {
    return (
      (focusIndex >= 0 && Object.keys(userTextMap).length + 1 === blankCount) ||
      Object.keys(userTextMap).length === blankCount
    );
  };

  return (
    <>
      <SRow
        space={3}
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
        p={3}
        alignSelf="center"
        style={sprops}
      >
        {initQuote && quoteBlankParser(punctuatedQuote)}
      </SRow>
      {readyToCheck() && (!showResult || (showResult && !showSuccessImage)) && (
        <Button
          backgroundColor="indigo.700"
          alignSelf="flex-end"
          size="md"
          borderRadius="3xl"
          mt="5"
          leftIcon={
            <Icon
              as={FontAwesome5}
              name="flag-checkered"
              size="sm"
              color="white"
            />
          }
          onPress={() => {
            clearResult();
            checkResult();
          }}
        >
          {checkButtonText}
        </Button>
      )}

      <Button
        backgroundColor="amber.400"
        _text={{ color: 'indigo.700', fontFamily: 'Lato_700Bold' }}
        alignSelf="flex-end"
        size="md"
        borderRadius="3xl"
        mt="5"
        leftIcon={
          <Icon
            as={FontAwesome}
            name="rotate-left"
            size="sm"
            color="indigo.700"
          />
        }
        onPress={() => {
          if (blankVer == 1) setBlankVer(2);
          if (blankVer == 2) setBlankVer(1);
        }}
      >
        Flip Blanks
      </Button>
      <Modal
        isOpen={modalVisible > -1}
        onClose={() => setModalVisible(-1)}
        avoidKeyboard
        initialFocusRef={initialFocusRef}
      >
        <Modal.Content>
          <Modal.Body>
            {makeResult()}
            <Input
              mt={4}
              autoCapitalize="none"
              autoCorrect={false}
              placeholder="Complete the quote [--------]"
              value={inputValue}
              onChangeText={setInputValue}
              ref={initialFocusRef}
              maxLength={30}
              onSubmitEditing={submitFnFromModal}
            />
          </Modal.Body>
          <Modal.Footer>
            <IconButton
              onPress={submitFnFromModal}
              icon={<CheckCircleIcon size="sm" color="green.600" />}
            />
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default Quote;
