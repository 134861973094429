import React from 'react';
import { TouchableWithoutFeedback, View } from 'react-native';

import { FontAwesome5 } from '@expo/vector-icons';

const ExpandableView = (props) => {
  const { expanded, onTapped, size, color } = props;
  return (
    <View {...props}>
      <TouchableWithoutFeedback
        onPress={() => {
          if (onTapped) onTapped(!expanded);
        }}
        hitSlop={{ top: 5, bottom: 5, left: 5, right: 10 }}
      >
        {expanded ? (
          <FontAwesome5
            name="chevron-up"
            size={size || 24}
            color={color || 'white'}
          />
        ) : (
          <FontAwesome5
            name="chevron-down"
            size={size || 24}
            color={color || 'white'}
          />
        )}
      </TouchableWithoutFeedback>
      {props.children}
    </View>
  );
};
export default ExpandableView;
