import { createSlice } from '@reduxjs/toolkit';

export const nxnStateSlice = createSlice({
  name: 'nxnState',
  initialState: {
    all: true,
    qod: true,
    tclive: true,
    tcup: true,
  },
  reducers: {
    setAllState: (state, action) => {
      state.all = action.payload;
      state.qod = action.payload;
      state.tclive = action.payload;
      state.tcup = action.payload;
    },

    setQod: (state, action) => {
      state.qod = action.payload;
    },

    setTcLive: (state, action) => {
      state.tclive = action.payload;
    },

    setTcUp: (state, action) => {
      state.tcup = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAllState, setQod, setTcLive, setTcUp } =
  nxnStateSlice.actions;

export default nxnStateSlice.reducer;
