import React from 'react';
import { Text } from 'native-base';

const CountFormatComponent = ({ fontSize, count }) => {
  let displayCount = count > 0 ? count : 0;
  if (count >= 1000 && count < 10000) {
    let x = Number.parseFloat(count / 1000.0).toPrecision(2);
    displayCount = x + 'k';
  } else if (count > 10000) {
    let x = Number.parseFloat(count / 1000.0).toPrecision(3);
    displayCount = x + 'k';
  }
  return <Text fontSize={fontSize}>{displayCount}</Text>;
};

export default CountFormatComponent;
