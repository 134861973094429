import React, { useEffect, useState, useContext } from 'react';

import { useSelector } from 'react-redux';

import { HStack, VStack, Icon, Factory, Text } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';

import MyShare from './common/Share';
import { toggleLikeQuote, getUserLikeStatusForAQuote } from './db/Query';
import CountFormatComponent from './CountFormatComponent';
import { GlobalXStateMachineContext } from './App.js';

const QuoteActionPanelPlain = (props) => {
  const { quoteID, quoteText, quoteURL, initLikeCount, quote, by } = props;

  const user = useSelector((state) => state.authState.userToken);
  const avatarKey = useSelector((state) => state.authState.avatarKey);
  const [likeCount, setLikeCount] = useState(0);
  const [likeStatus, setLikeStatus] = useState(false);
  const { send } = useContext(GlobalXStateMachineContext).scoreService;
  const smSend = send;

  useEffect(() => {
    getUserLikeStatusForAQuote(quoteID, user.fbUID, setLikeStatus);
    setLikeCount(initLikeCount);
    return () => setLikeCount(0);
  }, [quoteID, initLikeCount]);

  return (
    <HStack
      {...props}
      bg="white"
      justifyContent="space-around"
      alignItems="flex-start"
    >
      <VStack alignItems="center">
        <Icon
          m={1}
          onPress={() => {
            if (likeStatus) {
              setLikeCount(likeCount - 1);
              setLikeStatus(false);
            } else {
              setLikeCount(likeCount + 1);
              setLikeStatus(true);
              //send the LIKED_QUOTE event from here
              smSend({
                type: 'LIKED_QUOTE',
                liked_quote_id: quoteID,
                userid: user.fbUID,
              });
            }
            toggleLikeQuote(
              quoteID,
              user.fbUID,
              user.nickname,
              avatarKey,
              quote,
              by
            );
          }}
          as={
            likeStatus ? (
              <FontAwesome name="heart" />
            ) : (
              <FontAwesome name="heart-o" />
            )
          }
          color="red.600"
          size="md"
        />
        <CountFormatComponent fontSize="xs" count={likeCount} />
      </VStack>

      <MyShare subject="Enjoy this Quote" message={quoteText} url={quoteURL} />
    </HStack>
  );
};

const QuoteActionPanel = Factory(QuoteActionPanelPlain);
export default QuoteActionPanel;
