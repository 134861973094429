/* Auth States
isLoading - We set this to true when we're trying to check if we already have a token saved in SecureStore
isSignout - We set this to true when user is signing out, otherwise set it to false
userToken - The token for the user. This is a json object that is same as locally store user
i.e. { fbUID: firebaseUID, nickname: nickname, avatarKey: "a1|a2..|"", type: "anon"|"email"}
*/

import { createSlice } from '@reduxjs/toolkit';

export const authStateSlice = createSlice({
  name: 'authState',
  initialState: {
    isLoading: true,
    isSignout: false,
    userToken: 'token',
    avatarKey: 'a0',
  },
  reducers: {
    setLoadingState: (state, action) => {
      state.isLoading = action.payload;
    },

    setSignoutState: (state, action) => {
      state.isSignout = action.payload;
    },

    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },

    setAvatarKey: (state, action) => {
      state.avatarKey = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoadingState, setSignoutState, setUserToken, setAvatarKey } =
  authStateSlice.actions;

export default authStateSlice.reducer;
