import { firestore, firebase, firebaseApp, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import {
  signInAnonymously,
  createUserWithEmailAndPassword,
  linkWithCredential,
  EmailAuthProvider,
} from 'firebase/auth';
import dayjs from 'dayjs';
import md5 from 'blueimp-md5';

import Scores, { increment, decrement } from './ScoreConstants';

const weightedInterest = (userInterests) => {
  const tagWeights = {
    tag_motivational: 10,
    tag_inspirational: 10,
    tag_loveromance: 10,
    tag_political: 3,
    tag_funny: 5,
    tag_satire: 1,
    tag_contemporary: 5,
    tag_classic: 8,
    tag_insightful: 8,
    tag_health_related: 1,
    tag_spiritual: 1,
  };
  const userInterestSum = userInterests.reduce(
    (prev, curr) => prev + tagWeights[curr],
    0
  );
  //console.log('weighted sum is ', userInterestSum);
  const randomInRange = Math.floor(Math.random() * userInterestSum);
  //console.log('random in range ', randomInRange);
  let selectedTag;
  for (var i = 0, sum = 0; i < userInterests.length; i++) {
    selectedTag = userInterests[i];
    sum += tagWeights[selectedTag];
    if (randomInRange <= sum) break;
  }
  //console.log('selected tag is ', selectedTag);
  return selectedTag;
};

/*
 * Using the spreadsheet https://docs.google.com/spreadsheets/d/1ONL2_r3QLaMR6Jz2mgJ6Pqz74sGWc6AP3ve-p8lg4kY/edit#gid=524218558
 * We decided to have
 * vhigh = 2000  Cutoff: 0.00956700956700956 (31390 ie 33390-2000)
 * high  = 3000  Cutoff: 0.00372000372000372 (28390 ie 33390-5000)
 * med =  12000  Cutoff :0.000294000294000294 (16390 i.e. 33390-17000)
 * low = 8000    Cutoff: 0.000122000122000122 (83390 i.e 33390-25000)
 * vlow = 8000   Cutoff: 0
 *
 * PopularityValue is from 0-100
 */
const getPopLevel = (popularityValue) => {
  if (popularityValue >= 90) return 'vhigh';
  if (popularityValue >= 70) {
    let sum = 5000; // 2000+3000
    const randomInRange = Math.floor(Math.random() * sum);
    if (randomInRange <= 2000) return 'vhigh';
    else return 'high';
  }
  if (popularityValue >= 50) {
    let sum = 17000; // 2000+3000+12000
    const randomInRange = Math.floor(Math.random() * sum);
    if (randomInRange <= 2000) return 'vhigh';
    else if (randomInRange <= 5000) return 'high';
    else return 'med';
  }
  if (popularityValue >= 25) {
    let sum = 25000; // 2000+3000+12000+8000
    const randomInRange = Math.floor(Math.random() * sum);
    if (randomInRange <= 2000) return 'vhigh';
    else if (randomInRange <= 5000) return 'high';
    else if (randomInRange <= 17000) return 'med';
    else return 'low';
  } else {
    let sum = 28000; // 2000+3000+12000+8000+3000
    const randomInRange = Math.floor(Math.random() * sum);
    if (randomInRange <= 2000) return 'vhigh';
    else if (randomInRange <= 5000) return 'high';
    else if (randomInRange <= 17000) return 'med';
    else if (randomInRange <= 25000) return 'low';
    else return 'vlow';
  }
};

const quoteQuery = (
  quotesSeen,
  userInterests,
  popularityValue,
  setInitQuote
) => {
  //console.log(userInterests);
  if (quotesSeen.length === 0) {
    getQuoteDocbyID('1', setInitQuote);
    return;
  }

  if (quotesSeen.length > 10) {
    quotesSeen.splice(0, quotesSeen.length - 10); // remove the first elements, leave 10
  }

  // next you should have some weighted random selection of quotes.
  // also note that you need at least 11 quotes of a certain tag type.
  let randomInterest = 'tag_motivational';
  if (userInterests && userInterests.length > 0) {
    randomInterest = weightedInterest(userInterests);
  }
  console.log('Random insterest is ', randomInterest);
  const popLevel = getPopLevel(popularityValue);
  console.log(
    'popLevel : ',
    popLevel,
    ' for popularityValue ',
    popularityValue
  );
  const quotesRef = firestore.collection('quotes');
  //const key = quotesRef.doc().id.toLowerCase(); // generate a random ID
  const key = md5(Math.random(10000000));
  quotesRef
    .where(firebase.firestore.FieldPath.documentId(), '>=', key)
    .where(firebase.firestore.FieldPath.documentId(), 'not-in', quotesSeen)
    //.where('tags', 'array-contains-any', userInterests)
    //.where('tags', 'array-contains', randomInterest)
    .where(randomInterest, '==', true)
    .where('popLevel', '==', popLevel)
    .limit(1)
    .get()
    .then((docs) => {
      if (docs.size > 0) {
        docs.forEach((doc) => {
          setInitQuote(doc);
        });
      } else {
        quotesRef
          .where(firebase.firestore.FieldPath.documentId(), '<', key)

          .where(
            firebase.firestore.FieldPath.documentId(),
            'not-in',
            quotesSeen
          )
          //.where('tags', 'array-contains-any', userInterests)
          //.where('tags', 'array-contains', randomInterest)
          .where(randomInterest, '==', true)
          .where('popLevel', '==', popLevel)
          .limit(1)
          .get()
          .then((docs) => {
            if (docs.size > 0) {
              docs.forEach((doc) => {
                setInitQuote(doc);
              });
            } else {
              console.log('No fresh quotes for tag ', randomInterest);
              // may show something else.
              getQuoteDocbyID('2', setInitQuote);
            }
          })
          .catch((err) => {
            console.log('Error getting documents', err);
          });
      }
    })
    .catch((err) => {
      console.log('Error getting documents', err);
    });
};

const getQuotebyID = (quoteID, setQuoteData) => {
  const docRef = firestore.collection('quotes').doc(quoteID);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        setQuoteData({ quoteID, ...doc.data(), partialData: false });
      } else {
        console.log('quote with ID not found from query', quoteID);
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      console.log('quote with ID not found from query', quoteID);
    });
};

const getQuoteDocbyID = (quoteID, setInitQuote) => {
  const docRef = firestore.collection('quotes').doc(quoteID);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        setInitQuote(doc);
      } else {
        console.log('quote with ID not found from query', quoteID);
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      console.log('quote with ID not found from query', quoteID);
    });
};

const getFirstNQuotesByAuthor = (
  authorName,
  limit,
  setQuotes,
  setLastSeenRef
) => {
  const respArray = [];
  const first = firestore
    .collection('quotes')
    .where('by', '==', authorName)
    .orderBy('likes', 'desc')
    .limit(limit);
  first.get().then((documentSnapshots) => {
    documentSnapshots.forEach((doc) => {
      respArray.push({
        id: doc.id,
        quote: doc.data().quote,
        likeCount: doc.data().likes,
        by: doc.data().by,
      });
    });
    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    setQuotes(respArray);
    setLastSeenRef(lastVisible);
  });
};

const getNextNQuotes = (
  authorName,
  limit,
  addQuotes,
  lastSeenRef,
  setLastSeenRef
) => {
  console.log(
    'get next N called with lastSeenRef ',
    lastSeenRef ? 'valid' : 'invalid'
  );
  const respArray = [];
  const next = firestore
    .collection('quotes')
    .where('by', '==', authorName)
    .orderBy('likes', 'desc')
    .startAfter(lastSeenRef)
    .limit(limit);
  next.get().then((documentSnapshots) => {
    documentSnapshots.forEach((doc) => {
      respArray.push({
        id: doc.id,
        quote: doc.data().quote,
        likeCount: doc.data().likes,
        by: doc.data().by,
      });
    });
    // Get the last visible document
    const lastVisible =
      documentSnapshots.docs[documentSnapshots.docs.length - 1];
    addQuotes(respArray);
    setLastSeenRef(lastVisible);
  });
};

/* migrated to v9 below
const userSeenQuery = async (userID, quotesSeen, setQuoteSeen) => {
  const docRef = firestore.collection('users').doc(userID);
  try {
    const qs = await docRef.get();
    console.log('IN USERSEENQUERY QUERY with userID ', userID);
    const quotesSeenArr = quotesSeen || [];
    console.log('QS.EXISTS ', qs.exists);
    console.log('qs.data() ', qs.data());
    if (qs.exists && qs.data().quotes_seen) {
      console.log(qs.data().quotes_seen);
      setQuoteSeen([...quotesSeenArr, ...qs.data().quotes_seen]);
    } else {
      setQuoteSeen(quotesSeenArr);
    }
  } catch (e) {
    console.error(e);
  }
};
*/
const userSeenQuery = async (userID, quotesSeen, setQuoteSeen) => {
  const quotesSeenArr = quotesSeen || [];
  const v9docRef = doc(firestore, 'users', userID);
  let docSnap;
  try {
    docSnap = await getDoc(v9docRef);
  } catch (e) {
    console.log('Error getting user data:', e);
  }
  if (docSnap.exists()) {
    const { quotes_seen } = docSnap.data();
    if (quotes_seen && quotes_seen.length > 0) {
      setQuoteSeen([...quotesSeenArr, ...quotes_seen]);
      return;
    }
  }
  setQuoteSeen(quotesSeenArr);
};

const getShowAdsFlags = async (setShowAdsFlags) => {
  const docRef = doc(firestore, 'adflags', 'flags');
  let docSnap;
  try {
    docSnap = await getDoc(docRef);
  } catch (e) {
    console.log('Error getting ads flag data:', e);
  }
  if (docSnap.exists()) {
    const { web, mobile } = docSnap.data();
    setShowAdsFlags({ web, mobile });
  }
};

const getLeaderboard = async (setLeaderboard) => {
  const docRef = firestore.collection('leaderboard').doc('leaderlist');
  docRef
    .get()
    .then((qs) => {
      if (qs.exists && qs.data().top10) {
        setLeaderboard({ lb: qs.data().top10, ts: qs.data().created });
      } else {
        setLeaderboard({ lb: [], ts: 0 });
      }
    })
    .catch((error) => {
      console.error(error);
      setLeaderboard({ lb: [], ts: 0 });
    });
};

const signInAnonymouslyIfNicknameAvailable = (name, interests, fun) => {
  firestore
    .collection('takennicknames')
    .where('nickname', '==', name)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        signInAnonymously(auth)
          .then(({ user }) => {
            // Signed in. Promise returns UserCredentials. user is firebase.User
            // console.log('Anonymous User created ', user.uid);
            addNewUserInDb(user.uid, name, 'a0', 'anon', null, interests);
            let fbUser = {
              fbUID: user.uid,
              nickname: name,
              type: 'anon',
              email: null,
            };
            fun(true, fbUser, 'a0');
          })
          .catch((error) => {
            fun(false);
          });
      } else {
        console.log('nickname is taken');
        fun(false);
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });
};

const signUpIfNicknameAvailable = (email, password, name, interests, fun) => {
  firestore
    .collection('takennicknames')
    .where('nickname', '==', name)
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        createUserWithEmailAndPassword(auth, email, password)
          .then(({ user }) => {
            // Signed in. Promise returns UserCredentials. user is firebase.User
            // console.log('Email User created ', user.uid);
            addNewUserInDb(user.uid, name, 'a0', 'email', email, interests);
            let fbUser = {
              fbUID: user.uid,
              nickname: name,
              type: 'email',
              email,
            };
            fun(0, fbUser);
          })
          .catch((error) => {
            console.log(error);
            fun(-1, null, error.message);
          });
      } else {
        console.log('nickname is taken');
        fun(-2, null, 'This nickname is already taken.');
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
      fun(-3, null, error.message);
    });
};

const signUpAndLink = (email, password, name, fun) => {
  const cred = EmailAuthProvider.credential(email, password);
  linkWithCredential(auth.currentUser, cred)
    .then((usercred) => {
      var user = usercred.user;
      updateUserInDbAfterLink(user.uid, 'email', email);
      console.log('Anonymous account successfully upgraded', user);
      let fbUser = { fbUID: user.uid, nickname: name, type: 'email', email };
      fun(0, fbUser);
    })
    .catch((error) => {
      console.log('Error upgrading anonymous account', error);
      fun(-1, null, error.message);
    });
};

const updateUserInDbAfterLink = (userID, type, email) => {
  firestore
    .collection('users')
    .doc(userID)
    .set({ type, email }, { merge: true });
};

const addNewUserInDb = (
  userID,
  nickname,
  avatarKey,
  type,
  email,
  interests
) => {
  firestore
    .collection('users')
    .doc(userID)
    .set(
      {
        nickname,
        score: Scores.DEFAULT_SCORE,
        avatarKey,
        type,
        email,
        joined: dayjs().format('DD-MMM-YYYY'),
        signedIn: true,
        interests,
      },
      { merge: true }
    )
    .catch((e) => console.log(e));
  firestore
    .collection('takennicknames')
    .doc(userID)
    .set({ nickname })
    .catch((e) => console.log(e));
};

const getUserDetailsFromDb = async (userID, fun) => {
  const v9docRef = doc(firestore, 'users', userID);
  let docSnap;
  try {
    docSnap = await getDoc(v9docRef);
  } catch (e) {
    console.log('Error getting document:', e);
    fun(-2, null, null, null, error.message);
    return;
  }
  if (docSnap.exists()) {
    console.log(docSnap.data());
    const { avatarKey, nickname, interests, type, email } = docSnap.data();
    let fbUser = { fbUID: userID, nickname: nickname, type, email };
    fun(0, fbUser, avatarKey || 'a0', interests);
  } else {
    console.log('user with ID not found from query', userID);
    fun(
      -1,
      null,
      null,
      null,
      'User not found in database, this is embarrasing'
    );
  }
};

const updateAvatar = (userID, avatarKey) => {
  firestore.collection('users').doc(userID).set({ avatarKey }, { merge: true });
};

const updateQuoteSeen = (userID, newQuotesSeen) => {
  // limit only to last 20 seen, only in database. In realtime app the user seen list can keep growing
  if (newQuotesSeen.length > 20) {
    newQuotesSeen.splice(0, newQuotesSeen.length - 20);
  }
  const docRef = firestore.collection('users').doc(userID);
  docRef
    .set({ quotes_seen: newQuotesSeen }, { merge: true })
    .then(() => {
      console.log('New seen quotes successfully written!');
    })
    .catch((error) => {
      console.error('Error writing new quotes: ', error);
    });
};

// The workflow for a Tweak is
// 1. Add the new tweak to a Temp collection and generate the tweak ID.
//    (add - AutoID, quoteID, tweak, userID, nickname)
// 2. Also add the tweak under the user -> tweaks collection -> tweakID (new ID from above)
//    (approved: 'under-review', quoteID, tweak)
// Call above from Home.js where tweak is submitted for review

// Then when through a new app when the tweak is reviewed
// 1. If approved add the tweak under the quotes using "addUerHofQuote" function below
// 2. Update status under user -> tweaks
// 3. Delete from temp collection

const addTweakForReview = (quoteID, userID, nickname, avatarKey, tweak) => {
  var newTweakRef = firestore.collection('tweaksUnderReview').doc();
  const tweakID = newTweakRef.id;
  newTweakRef
    .set({
      quoteID,
      userID,
      nickname,
      avatarKey,
      tweak,
      submitted: Date.now(),
    })
    .then(() => {
      console.log(
        'written tweak to temp collection, now adding to user collection'
      );
      firestore
        .collection('users')
        .doc(userID)
        .collection('tweaks')
        .doc(tweakID)
        .set({
          quoteID,
          tweak,
          approved: 'under-review',
          submitted: Date.now(),
        })
        .catch((error) => {
          console.error('Error writing tweak to user collection: ', error);
        });
    })
    .catch((error) => {
      console.error('Error writing tweak to temp collection: ', error);
    });
};

const withdrawTweakFromReview = (userID, tweakID) => {
  firestore
    .collection('tweaksUnderReview')
    .doc(tweakID)
    .delete()
    .catch((error) => {
      console.error('Error deleting tweak from underReview: ', error);
    });
  firestore
    .collection('users')
    .doc(userID)
    .collection('tweaks')
    .doc(tweakID)
    .delete()
    .catch((error) => {
      console.error('Error deleting tweak from user tweaks: ', error);
    });
};

const deleteRejectedTweakByUser = (userID, tweakID) => {
  firestore
    .collection('users')
    .doc(userID)
    .collection('tweaks')
    .doc(tweakID)
    .delete()
    .catch((error) => {
      console.error('Error deleting tweak from user tweaks: ', error);
    });
};

/**  Not used from here. The same function is used from admin app **
const addUserHofQuote = (quoteID, userID, nickname, funQuote) => {
  firestore
    .collection('quotes')
    .doc(quoteID)
    .collection('user_attempts')
    .doc(userID)
    .set({ nickname }, { merge: true })
    .then(() => {
      firestore
        .collection('quotes')
        .doc(quoteID)
        .collection('user_attempts')
        .doc(userID)
        .collection('fun_attempts')
        .doc()
        .set({
          quote: funQuote,
          likes: 0,
          dislikes: 0,
          //approved: false,
        })
        .catch((error) => {
          console.log('Error writing document: ', error);
        });
    })
    .catch((e) => console.log(e));
};
**/

const deleteUserTweakFromQuote = (quoteID, userID, tweakID) => {
  firestore
    .collection('quotes')
    .doc(quoteID)
    .collection('user_attempts')
    .doc(userID)
    .collection('fun_attempts')
    .doc(tweakID)
    .delete()
    .catch((error) => {
      console.error('Error deleting tweak from quote: ', error);
    });
};

const deleteUserTweakFromUser = (userID, tweakID) => {
  firestore
    .collection('users')
    .doc(userID)
    .collection('tweaks')
    .doc(tweakID)
    .delete()
    .catch((error) => {
      console.error('Error deleting tweak from users: ', error);
    });
};

const getUserAttemptsForQuote = (quoteID, spoofListCallback) => {
  let respArray = [];
  firestore
    .collection('quotes')
    .doc(quoteID)
    .collection('user_attempts')
    .get()
    .then((querySnapshot) => {
      if (querySnapshot.empty) {
        spoofListCallback([]);
        return;
      }
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        firestore
          .collection('quotes')
          .doc(quoteID)
          .collection('user_attempts')
          .doc(doc.id)
          .collection('fun_attempts')
          .get()
          .then((qs) => {
            qs.forEach((spoofQuotes) => {
              // doc.data() is never undefined for query doc snapshots
              //console.log(spoofQuotes.data());
              const { quote, likes, dislikes, createTime } = spoofQuotes.data();
              respArray.push({
                id: spoofQuotes.id,
                by: {
                  authId: doc.id,
                  authNickname: doc.data().nickname || 'nn',
                  authAvatarKey: doc.data().avatarKey || 'a0',
                },
                quote,
                likeCount: likes,
                dislikeCount: dislikes,
                createTime: createTime || parseInt(Math.random() * 100),
              });
            });
            //spoofListCallback(respArray);
          })
          .catch((e) => console.log(e));
      });
    })
    .catch((e) => console.log(e))
    .finally(() => setTimeout(() => spoofListCallback(respArray), 1500));
};

const getUserLikeStatusOfSpoofQuotes = (userID, mySpoofLikeStatusCb) => {
  let respArray = [];
  firestore
    .collection('users')
    .doc(userID)
    .collection('spoof_like_status')
    .get()
    .then((qs) => {
      qs.forEach((spoofQuote) => {
        respArray.push({
          id: spoofQuote.id,
          like: spoofQuote.data().like,
        });
      });
    })
    .catch((e) => console.log(e))
    .finally(() => setTimeout(() => mySpoofLikeStatusCb(respArray), 500));
};

const setUserLikeStatusOfSpoofQuote = (
  userID,
  spoofID,
  likeStatus,
  quoteID,
  creatorID,
  currentLikeState
) => {
  // Top score updater function
  // The top spoof is only changed when the top score of some other
  // spoof increases beyon the topSpoofScore. If the current
  // top spoof loses likes we do not change it because we cannot find
  // a new leader in this flow.
  let spoofQuote = '';
  let topSpoofScore = 0;
  let spoofScore = 0;
  const updateTopSpoof = () => {
    if (spoofScore >= topSpoofScore) {
      firestore.collection('quotes').doc(quoteID).update(
        {
          topSpoof: spoofQuote,
          topSpoofScore: spoofScore,
        },
        { merge: true }
      );
    }
  };

  // Lets find the current top spoof ID (of any in the quote)

  firestore
    .collection('quotes')
    .doc(quoteID)
    .get()
    .then((doc) => {
      if (doc.exists) {
        topSpoofScore = doc.data().topSpoofScore || 0;
      }
    });

  // Update the doc tree where we keep the count for
  // the creator or the spoof.
  const quoteUserFunAttemptRef = firestore
    .collection('quotes')
    .doc(quoteID)
    .collection('user_attempts')
    .doc(creatorID)
    .collection('fun_attempts')
    .doc(spoofID);

  quoteUserFunAttemptRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        const { likes, dislikes, quote } = doc.data();
        spoofScore = likes - dislikes;
        spoofQuote = quote;
        if (likeStatus > 0 && currentLikeState < 0) {
          quoteUserFunAttemptRef
            .update({
              likes: increment,
              dislikes: decrement,
            })
            .then(() => {
              spoofScore += 2;
              updateTopSpoof();
            })
            .catch((e) => console.log(e));
        } else if (likeStatus > 0 && currentLikeState == 0) {
          quoteUserFunAttemptRef
            .update({ likes: increment })
            .then(() => {
              spoofScore += 1;
              updateTopSpoof();
            })
            .catch((e) => console.log(e));
        }
        // for likeStatus and currentLikeStatus > 0 - do nothing
        else if (likeStatus == 0 && currentLikeState < 0) {
          quoteUserFunAttemptRef
            .update({ dislikes: decrement })
            .then(() => {
              spoofScore += 1;
              updateTopSpoof();
            })
            .catch((e) => console.log(e));
        } else if (likeStatus == 0 && currentLikeState > 0) {
          quoteUserFunAttemptRef
            .update({ likes: decrement })
            .then(() => {
              spoofScore -= 1;
              //updateTopSpoof();
            })
            .catch((e) => console.log(e));
        } else if (likeStatus < 0 && currentLikeState == 0) {
          quoteUserFunAttemptRef
            .update({ dislikes: increment })
            .then(() => {
              spoofScore -= 1;
              //updateTopSpoof();
            })
            .catch((e) => console.log(e));
        } else if (likeStatus < 0 && currentLikeState > 0) {
          quoteUserFunAttemptRef
            .update({
              likes: decrement,
              dislikes: increment,
            })
            .then(() => {
              spoofScore -= 2;
              //updateTopSpoof();
            })
            .catch((e) => console.log(e));
        }
      } else {
        console.log('The tweak may have been deleted');
      }
    })
    .catch((error) => {
      console.log('Error getting document:', error);
    });

  // In addition we also update the users spoof like status
  firestore
    .collection('users')
    .doc(userID)
    .collection('spoof_like_status')
    .doc(spoofID)
    .set({ like: likeStatus })
    .catch((e) => console.log(e));
};

const toggleLikeQuote = (quoteID, userID, nickname, avatarKey, quote, by) => {
  const quoteRef = firestore.collection('quotes').doc(quoteID);
  // As this is denormalized we also update the user doc
  const userRef = firestore.collection('users').doc(userID);

  quoteRef
    .collection('user_attempts')
    .doc(userID)
    .get()
    .then((doc) => {
      if (doc.exists) {
        const newLike = !doc.data().liked;
        doc.ref
          .update({ liked: newLike })
          .then(() => {
            if (newLike) {
              quoteRef
                .update({ likes: increment })
                .catch((e) => console.log(e));
              userRef
                .collection('liked')
                .doc(quoteID)
                .set({})
                .then(() =>
                  userRef
                    .collection('liked')
                    .doc(quoteID)
                    .set({ quote, by: by || '', likedOn: Date.now() })
                )
                .catch((e) => console.error(e));
            } else {
              quoteRef
                .update({ likes: decrement })
                .catch((e) => console.log(e));
              userRef
                .collection('liked')
                .doc(quoteID)
                .delete()
                .catch((e) => console.log(e));
            }
          })
          .catch((e) => console.log(e));
      } else {
        console.log('Doc not found');
        quoteRef
          .collection('user_attempts')
          .doc(userID)
          .set({ nickname, avatarKey, liked: true })
          .then(() => {
            quoteRef.update({ likes: increment });
            userRef
              .collection('liked')
              .doc(quoteID)
              .set({ quote, by: by || '', likedOn: Date.now() });
          })
          .catch((e) => console.log(e));
      }
    })
    .catch((e) => console.log(e));
};

// Even though the data is denormalized, we read it only from one place
// for the liked status. We could as well have read it from the users->user->liked

const getUserLikeStatusForAQuote = (quoteID, userID, setUserLikeStatusCb) => {
  firestore
    .collection('quotes')
    .doc(quoteID)
    .collection('user_attempts')
    .doc(userID)
    .get()
    .then((doc) => {
      const { liked } = doc.data();
      setUserLikeStatusCb(liked);
    })
    .catch((e) => {
      setUserLikeStatusCb(false);
    });
};

const subscribeToScore = (userID, func) => {
  return firestore.collection('users').doc(userID).onSnapshot(func);
};

// the INCR are the sentinel values
const addToUserScore = (userID, INCR) => {
  firestore
    .collection('users')
    .doc(userID)
    .update({ score: INCR })
    .catch((e) => console.log(e));
};

const setLastSeenScore = (userID, lastSeenScore) => {
  firestore
    .collection('users')
    .doc(userID)
    .set({ lastSeenScore }, { merge: true })
    .catch((e) => console.log(e));
};

const clearLastSeenNxn = (userID) => {
  firestore
    .collection('users')
    .doc(userID)
    .set({ lastUnseenNxn: null }, { merge: true })
    .catch((e) => console.log(e));
};

const setBadgeFromLastSeenState = (userID, setBadgeState) => {
  firestore
    .collection('users')
    .doc(userID)
    .get()
    .then((doc) => {
      const { lastSeenScore, score, lastUnseenNxn } = doc.data();
      setBadgeState(!(score === lastSeenScore) || lastUnseenNxn);
    })
    .catch((e) => console.log(e));
};

const getProfileData = (userID, setProfileData) => {
  firestore
    .collection('users')
    .doc(userID)
    .get()
    .then((doc) => {
      const {
        nickname,
        score,
        joined,
        avatarKey,
        lastSeenScore,
        lastUnseenNxn,
      } = doc.data();
      setProfileData({
        nickname,
        score,
        joined,
        avatarKey,
        lastSeenScore,
        lastUnseenNxn,
      });
    })
    .catch((e) => console.log(e));
};

const getLastUnseenNxn = (userID, setLastUnseenNxn) => {
  firestore
    .collection('users')
    .doc(userID)
    .get()
    .then((doc) => {
      const { lastUnseenNxn } = doc.data();
      setLastUnseenNxn(lastUnseenNxn);
    });
};

const getAuthorImageURI = (authorHash, setAuthorImageURI) => {
  firestore
    .collection('authors')
    .doc(authorHash)
    .get()
    .then((doc) => {
      const { image } = doc.data();
      setAuthorImageURI(
        image ||
          'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Quill_and_ink_Wikipedia.svg/152px-Quill_and_ink_Wikipedia.svg.png'
      );
    })
    .catch((e) => console.log(e));
};

const getAuthorsByNameSearch = (searchTerm, setAuthors) => {
  const names = searchTerm.split(' ').map((e) => e.toLowerCase());
  const respArray = [];
  firestore
    .collection('authors')
    .where('namearr', 'array-contains-any', names)
    .get()
    .then((qs) => {
      qs.forEach((author) => {
        respArray.push({
          name: author.data().name,
          url:
            author.data().image ||
            'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d8/Quill_and_ink_Wikipedia.svg/152px-Quill_and_ink_Wikipedia.svg.png',
        });
      });
    })
    .catch((e) => console.error(e))
    .finally(() => setAuthors(respArray));
};

const getQuotesLikedByUser = (userID, setLikedQuotes) => {
  let respArray = [];
  firestore
    .collection('users')
    .doc(userID)
    .collection('liked')
    .orderBy('likedOn', 'desc')
    .get()
    .then((qs) => {
      qs.forEach((quote) => {
        respArray.push({
          ID: quote.id,
          quote: quote.data().quote,
          by: quote.data().by,
        });
      });
    })
    .catch((e) => console.log(e))
    .finally(() => setLikedQuotes(respArray));
};

const getTweaksByUser = (userID, setTweaks) => {
  let respArray = [];
  firestore
    .collection('users')
    .doc(userID)
    .collection('tweaks')
    .orderBy('submitted', 'desc')
    .get()
    .then((qs) => {
      qs.forEach((tweak_info) => {
        const { quoteID, tweak, approved, reason } = tweak_info.data();
        respArray.push({
          ID: tweak_info.id,
          quoteID,
          tweak,
          approved,
          reason,
        });
      });
    })
    .catch((e) => console.log(e))
    .finally(() => setTweaks(respArray));
};

const getApprovedTweaksByUser = (userID, setTweaks) => {
  let respArray = [];
  firestore
    .collection('users')
    .doc(userID)
    .collection('tweaks')
    .where('approved', '==', 'approved')
    .get()
    .then((qs) => {
      qs.forEach((tweak_info) => {
        const { quoteID, tweak } = tweak_info.data();
        respArray.push({
          ID: tweak_info.id,
          quoteID,
          tweak,
        });
      });
    })
    .catch((e) => console.log(e))
    .finally(() => setTweaks(respArray));
};

const updateUserCategoryInterest = async (userID, interests) => {
  const docRef = doc(firestore, 'users', userID);
  try {
    await setDoc(docRef, { interests }, { merge: true });
    console.log('User interests successfully written!');
  } catch (error) {
    console.error('Error writing user inetersts: ', error);
  }
};

const updateUserNxnStatus = (userID, nxnState) => {
  const docRef = firestore.collection('users').doc(userID);
  docRef
    .set({ nxnState }, { merge: true })
    .then(() => {
      console.log('Nxn state successfully written!');
    })
    .catch((error) => {
      console.error('Error writing nxnState: ', error);
    });
};

const updateNxnToken = async (
  userID,
  nxnToken,
  deviceName,
  setNxnTokenUpdated
) => {
  const docRef = firestore.collection('users').doc(userID);
  try {
    await docRef.set(
      { deviceName: deviceName, nxnToken: nxnToken, signedIn: true },
      { merge: true }
    );
    console.log('Device token successfully written!');
  } catch (error) {
    console.error('Error writing deviceToken: ', error);
  } finally {
    setNxnTokenUpdated(true);
  }
};

const updateLastUsedTimestamp = async (userID, timestamp, setLutsUpdated) => {
  const docRef = firestore.collection('users').doc(userID);
  try {
    await docRef.set({ luts: timestamp }, { merge: true });
    console.log('LU Timestamp successfully written!');
  } catch (error) {
    console.error('Error writing LU timestamp: ', error);
  } finally {
    setLutsUpdated(true);
  }
};

const updateSignInStatus = async (userID, signedIn) => {
  const docRef = firestore.collection('users').doc(userID);
  try {
    await docRef.set({ signedIn }, { merge: true });
    console.log('SignedIn status successfully written!');
  } catch (error) {
    console.error('Error writing SignedInStatus: ', error);
  }
};

export {
  quoteQuery,
  getQuotebyID,
  getQuoteDocbyID,
  userSeenQuery,
  updateQuoteSeen,
  //addUserHofQuote,
  toggleLikeQuote,
  getUserAttemptsForQuote,
  getUserLikeStatusOfSpoofQuotes,
  setUserLikeStatusOfSpoofQuote,
  signInAnonymouslyIfNicknameAvailable,
  subscribeToScore,
  getProfileData,
  getUserLikeStatusForAQuote,
  deleteUserTweakFromQuote,
  deleteUserTweakFromUser,
  getQuotesLikedByUser,
  getTweaksByUser,
  getApprovedTweaksByUser,
  addTweakForReview,
  withdrawTweakFromReview,
  updateUserCategoryInterest,
  addToUserScore,
  deleteRejectedTweakByUser,
  updateAvatar,
  getLeaderboard,
  getFirstNQuotesByAuthor,
  getNextNQuotes,
  signUpIfNicknameAvailable,
  getUserDetailsFromDb,
  signUpAndLink,
  getAuthorImageURI,
  setLastSeenScore,
  setBadgeFromLastSeenState,
  getAuthorsByNameSearch,
  updateUserNxnStatus,
  updateNxnToken,
  updateLastUsedTimestamp,
  updateSignInStatus,
  clearLastSeenNxn,
  getLastUnseenNxn,
  getShowAdsFlags,
};
