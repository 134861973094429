import { createSlice } from '@reduxjs/toolkit';

// Interest is an array of interests (see Settings.js for details)

export const interestStateSlice = createSlice({
  name: 'interestState',
  initialState: {
    value: [
      'tag_inspirational',
      'tag_classic',
      'tag_motivational',
      'tag_loveromance',
      'tag_insightful',
      'tag_funny',
      'tag_contemporary',
      'tag_political',
      'tag_health_related',
      'tag_spiritual',
      'tag_satire',
    ],
  },
  reducers: {
    setInterestState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setInterestState } = interestStateSlice.actions;

export default interestStateSlice.reducer;
