import React, { useEffect } from 'react';
import { Text, Box, Stack, Icon } from 'native-base';
import { FontAwesome } from '@expo/vector-icons';
import { useSpring, animated, config } from 'react-spring';

const NoNetworkQuote = ({ quote, by, size }) => {
  const SText = animated(Text);
  const [tprops, api] = useSpring(() => ({
    from: { opacity: 0 },
  }));

  useEffect(() => {
    api.start({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 1000 },
    });
  }, [quote]);

  return (
    <Box
      bg="white"
      shadow={2}
      rounded="lg"
      width="90%"
      maxWidth="90%"
      alignItems="center"
      alignSelf="center"
      m={5}
    >
      <Stack space={4} p={[4, 4, 8]} justifyContent="center" width="100%">
        <Icon
          alignSelf="center"
          color="warmGray.500"
          size={size == 'md' ? 6 : 10}
          as={<FontAwesome name="quote-left" />}
        />
        <SText
          fontSize={size == 'md' ? 'xl' : '2xl'}
          p={5}
          style={{ ...tprops, fontFamily: 'Lato_400Regular_Italic' }}
          fontWeight={500}
          fontStyle="italic"
          color="warmGray.500"
        >
          {quote}
        </SText>

        <Text
          position="absolute"
          left={1}
          bottom={2}
          style={{ fontFamily: 'Lato_400Regular' }}
          fontSize="md"
          fontWeight={500}
          color="gray.600"
          maxW="60%"
          zIndex={1}
        >
          {by}
        </Text>
      </Stack>
    </Box>
  );
};

export default NoNetworkQuote;
