import React from 'react';
import { Text, Box, useTheme, Stack, Badge } from 'native-base';

const TopSpoofView = ({ quote }) => {
  return (
    <Box
      bg="coolGray.100"
      shadow={2}
      width="90%"
      maxWidth="90%"
      alignItems="center"
      alignSelf="center"
      my={1}
    >
      <Stack space={4} p={[4, 4, 4]} justifyContent="center" width="100%">
        <Text
          fontSize={'md'}
          p={2}
          mt={2}
          style={{ fontFamily: 'Lato_400Regular_Italic' }}
          fontWeight={400}
          fontStyle="italic"
          color="warmGray.500"
        >
          {quote}
        </Text>
        <Badge
          position="absolute"
          top={2}
          right={2}
          variant="subtle"
          colorScheme="warning"
        >
          Top Tweak
        </Badge>
      </Stack>
    </Box>
  );
};

export default TopSpoofView;
