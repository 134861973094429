import React, { useState, useEffect } from 'react';
import {
  Text,
  HStack,
  VStack,
  Avatar,
  Box,
  Row,
  Center,
  Divider,
  AddIcon,
  Flex,
  useSafeArea,
  Slide,
  IconButton,
  CloseIcon,
  Stack,
  Heading,
} from 'native-base';
import { Pressable } from 'react-native';
import { useSelector, useDispatch } from 'react-redux';
import { StackActions } from '@react-navigation/native';

import { avatarMap } from './App';
import PCSP from './ProfileContentScreenPager';
import OUP from './OtherUserProfile';
import { getProfileData, setLastSeenScore, clearLastSeenNxn } from './db/Query';
import { setBadgeState } from './redux/reducers/BadgeState.js';
import SlideNxn from './common/SlideNxn';

/*
Profile page (nickname, 
  score, 
  member since, 
  comments, 
  tweaks, 
  thoughts (new self quotes), 
  likes, 
  follows) 
  Note that only some part of this is store in local storage namely
  { fbUID: firebaseUID, nickname: nickname, type: "anon"|"email"}
  Rest of the data is retrieved from users collection
*/
export default function ProfileScreen({ route, navigation }) {
  const appUserID = useSelector((state) => state.authState.userToken).fbUID;
  const userID =
    route.params && route.params.userID ? route.params.userID : appUserID;

  const dispatch = useDispatch();
  const [profileData, setProfileData] = useState({
    nickname: 'Name',
    score: 0,
    joined: '01/01/2022',
    avatarKey: 'a1',
    lastSeenScore: 0,
    lastUnseenNxn: null, //{ type, id, text }
  });

  const { nickname, joined, avatarKey, lastSeenScore, lastUnseenNxn } =
    profileData;

  const appUserProfileMode = userID === appUserID;
  const [showNotif, setShowNotif] = useState(false);

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      getProfileData(userID, setProfileData);
    });
    // Return the function to unsubscribe from the event so it gets removed on unmount
    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (appUserProfileMode && profileData.score > 0) {
      setLastSeenScore(appUserID, profileData.score);
      dispatch(setBadgeState(false));
    }
  }, [profileData.score]);

  useEffect(() => {
    if (appUserProfileMode && profileData.lastUnseenNxn) {
      setShowNotif(true);
      dispatch(setBadgeState(false));
      clearLastSeenNxn(appUserID);
    }
  }, [profileData.lastUnseenNxn]);

  // Use profile score for other user, for seeing self profile we use the state score
  // as it is subscribed and may change in real time.
  const score = appUserProfileMode
    ? useSelector((state) => state.scoreState.value)
    : profileData.score;

  const safeAreaProps = useSafeArea({
    safeAreaTop: true,
  });
  return (
    <Flex alignItems="center" width="100%" {...safeAreaProps}>
      <VStack alignItems="center" width="100%">
        <HStack
          space={4}
          justifyContent="flex-start"
          alignItems="center"
          width="100%"
          my={2}
        >
          {appUserProfileMode && (
            <>
              <Pressable onPress={() => navigation.navigate('AvatarSelection')}>
                <Avatar
                  mx={4}
                  bg="indigo.600"
                  alignSelf="center"
                  size="md"
                  source={avatarMap[avatarKey]}
                  borderColor="indigo.600"
                  borderWidth={0.5}
                  key={avatarKey}
                >
                  <Avatar.Badge bg="green.500">
                    <AddIcon size="3" alignSelf="center" color="white" />
                  </Avatar.Badge>
                </Avatar>
              </Pressable>
              <SlideNxn
                showNotif={showNotif}
                setShowNotif={setShowNotif}
                lastUnseenNxn={lastUnseenNxn}
              />
            </>
          )}
          {!appUserProfileMode && (
            <Avatar
              mx={4}
              bg="indigo.600"
              alignSelf="center"
              size="md"
              source={avatarMap[avatarKey]}
              borderColor="indigo.600"
              borderWidth={0.5}
              key={avatarKey}
            />
          )}
          <VStack width="80%">
            <Row width="80%">
              <Text bold italic flex="2">
                Nickname
              </Text>
              <Text flex="3">{nickname}</Text>
            </Row>
            <Row width="80%">
              <Text bold italic flex="2">
                Score
              </Text>
              <Text flex="3">
                {score}{' '}
                {appUserProfileMode &&
                  lastSeenScore &&
                  score > lastSeenScore &&
                  ' (+' + (score - lastSeenScore) + ')'}
              </Text>
            </Row>
            {appUserProfileMode && (
              <Row width="80%">
                <Text bold italic flex="2">
                  Joined
                </Text>
                <Text flex="3">{joined}</Text>
              </Row>
            )}
          </VStack>
        </HStack>
      </VStack>
      <Divider thickness={2} alignSelf="center" width="90%" my={4} />
      {appUserProfileMode && <PCSP userID={userID} />}
      {!appUserProfileMode && <OUP userID={userID} nickname={nickname} />}
    </Flex>
  );
}
