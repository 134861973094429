import * as React from 'react';
import { useState, useEffect } from 'react';
import { Modal, Button, Text, Image, useMediaQuery } from 'native-base';

const ScreenSizeModal = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [isLargeScreen] = useMediaQuery({
    minWidth: 821, // iPad Air is w=820
  });

  useEffect(() => {
    if (isLargeScreen) {
      setModalVisible(true);
    }
  }, []);

  return (
    <>
      <Modal
        isOpen={modalVisible}
        onClose={() => setModalVisible(false)}
        avoidKeyboard
        justifyContent="center"
        bottom="4"
        size="lg"
      >
        <Modal.Content>
          <Modal.CloseButton />
          <Modal.Body>
            <Text alignSelf="center" fontFamily="Lato_700Bold">
              Game of Quotes is best viewed on a smaller screen.
            </Text>
            <Text alignSelf="center" fontFamily="Lato_700Bold">
              If you'd like you can scan this QR code from your phone or tablet
            </Text>
            <Text alignSelf="center" fontFamily="Lato_700Bold" fontSize="lg">
              Or go directly to gameofquotes.app
            </Text>
            <Image
              source={require('../assets/qrcode.png')}
              alt="QR"
              size={'2xl'}
              alignSelf="center"
              my={4}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              bg="indigo.500"
              _text={{ color: 'white', fontFamily: 'Lato_700Bold' }}
              onPress={() => {
                setModalVisible(false);
              }}
            >
              Proceed anyway
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </>
  );
};

export default ScreenSizeModal;
