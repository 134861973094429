import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Pressable, Toast, Icon, useTheme, useToast } from 'native-base';
import { MaterialIcons } from '@expo/vector-icons';

import { setGameFontState } from '../redux/reducers/GameFontState';

const FontControl = () => {
  const dispatch = useDispatch();
  const gameFontSize = useSelector((state) => state.gameFontState.value);
  const { colors } = useTheme();
  const toast = useToast();
  const fontToastId = 'font-toast-id';
  return (
    <Row position="absolute" top="1" right="4" zIndex={1}>
      <Pressable
        onPress={() => {
          if (gameFontSize === 'xl') {
            if (!toast.isActive(fontToastId)) {
              toast.show({
                id: fontToastId,
                title: 'Already at smallest size',
                status: 'info',
                style: {
                  backgroundColor: colors.amber['400'],
                },
              });
            }
          }
          if (gameFontSize === '2xl') dispatch(setGameFontState('xl'));
          if (gameFontSize === '3xl') dispatch(setGameFontState('2xl'));
          if (gameFontSize === '4xl') dispatch(setGameFontState('3xl'));
        }}
        mr={4}
      >
        <Icon as={MaterialIcons} name="zoom-out" size={7} />
      </Pressable>
      <Pressable
        onPress={() => {
          if (gameFontSize === 'xl') dispatch(setGameFontState('2xl'));
          if (gameFontSize === '2xl') dispatch(setGameFontState('3xl'));
          if (gameFontSize === '3xl') dispatch(setGameFontState('4xl'));
          if (gameFontSize === '4xl') {
            if (!toast.isActive(fontToastId)) {
              toast.show({
                id: fontToastId,
                title: 'Already at largest size',
                status: 'info',
                style: {
                  backgroundColor: colors.amber['400'],
                },
              });
            }
          }
        }}
      >
        <Icon as={MaterialIcons} name="zoom-in" size={7} />
      </Pressable>
    </Row>
  );
};

export default FontControl;
