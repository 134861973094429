import { createSlice } from '@reduxjs/toolkit';

export const badgeStateSlice = createSlice({
  name: 'badgeState',
  initialState: {
    value: false,
  },
  reducers: {
    setBadgeState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBadgeState } = badgeStateSlice.actions;

export default badgeStateSlice.reducer;
