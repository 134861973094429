import React, { useState, useEffect, useCallback } from 'react';
import { Keyboard } from 'react-native';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import {
  FlatList,
  Text,
  Center,
  Pressable,
  Row,
  Avatar,
  Input,
  Icon,
  HStack,
  Spacer,
  Button,
  VStack,
  Image,
  Skeleton,
} from 'native-base';
import { AntDesign } from '@expo/vector-icons';

import { getAuthorsByNameSearch } from './db/Query';

export default function SearchScreen() {
  const navigation = useNavigation();
  const [authors, setAuthors] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSkeleton, setShowSkeleton] = useState(false);

  const doSearch = () => {
    if (searchTerm === '') return;
    Keyboard.dismiss();
    setShowSkeleton(true);
    getAuthorsByNameSearch(searchTerm, setAuthors);
  };

  useEffect(() => {
    setShowSkeleton(true);
    setAuthors([
      {
        name: 'Albert Einstein',
        url: 'https://upload.wikimedia.org/wikipedia/commons/d/d3/Albert_Einstein_Head.jpg',
      },
      {
        name: 'Mark Twain',
        url: 'https://upload.wikimedia.org/wikipedia/commons/0/0c/Mark_Twain_by_AF_Bradley.jpg',
      },
      {
        name: 'Abraham Lincoln',
        url: 'https://upload.wikimedia.org/wikipedia/commons/a/ab/Abraham_Lincoln_O-77_matte_collodion_print.jpg',
      },
      {
        name: 'Napoleon Hill',
        url: 'https://upload.wikimedia.org/wikipedia/commons/7/75/Napoleon_Hill_headshot.jpg',
      },
      {
        name: 'Mahatma Gandhi',
        url: 'https://upload.wikimedia.org/wikipedia/commons/7/7a/Mahatma_Gandhi_Ghp.jpg',
      },
      {
        name: 'Barack Obama',
        url: 'https://upload.wikimedia.org/wikipedia/commons/e/e9/Official_portrait_of_Barack_Obama.jpg',
      },
      {
        name: 'Lao Tzu',
        url: 'https://upload.wikimedia.org/wikipedia/commons/3/3a/Lao_Tzu_-_Project_Gutenberg_eText_15250.jpg',
      },
      {
        name: 'Aristotle',
        url: 'https://upload.wikimedia.org/wikipedia/commons/0/05/Head_of_Aristotle.jpg',
      },
      {
        name: 'Douglas Adams',
        url: 'https://upload.wikimedia.org/wikipedia/commons/c/c0/Douglas_adams_portrait_cropped.jpg',
      },
      {
        name: 'Robert Frost',
        url: 'https://upload.wikimedia.org/wikipedia/commons/3/36/Robert_Frost%2C_1910s.jpg',
      },
    ]);
  }, []);

  useEffect(() => {
    setShowSkeleton(false);
  }, [authors]);

  const renderItem = ({ item }) => {
    return (
      <Center width="100%" flex={1} key={item.name}>
        <Pressable
          flex={1}
          width="100%"
          onPress={() => {
            navigation.push('AuthorScreen', {
              authorName: item.name,
              screenTitle: item.name,
            });
          }}
        >
          <Row
            bg="gray.100"
            alignSelf="center"
            flex={1}
            width="100%"
            //justifyContent="space-evenly"
            my={1}
            rounded="md"
            borderColor="indigo.600"
            borderWidth={0.5}
          >
            <Avatar
              mx={2}
              my={1}
              bg="indigo.600"
              alignSelf="center"
              size="md"
              source={{ uri: item.url }}
            />

            <Text
              fontSize="sm"
              p={1}
              alignSelf="center"
              bold
              color="indigo.700"
            >
              {item.name}
            </Text>
          </Row>
        </Pressable>
      </Center>
    );
  };

  return (
    <VStack m={2} flex={1} alignItems="center">
      <HStack alignItems="center" justifyContent="center" mx={4} my={2}>
        <Input
          w={{
            base: '75%',
            md: '25%',
          }}
          placeholder="Search Authors"
          background="indigo.500"
          borderColor="indigo.600"
          value={searchTerm}
          onChangeText={setSearchTerm}
          onSubmitEditing={doSearch}
        />
        <Button
          size="sm"
          bg="indigo.500"
          _text={{ color: 'white', fontFamily: 'Lato_700Bold' }}
          endIcon={<Icon as={AntDesign} name="search1" size="sm" />}
          onPress={doSearch}
          mx={2}
        >
          {' Search '}
        </Button>
      </HStack>
      {showSkeleton && (
        <>
          <HStack space="2" alignItems="center" my={4}>
            <Skeleton size="5" rounded="full" />
            <Skeleton h="3" flex="2" rounded="full" />
          </HStack>
          <HStack space="2" alignItems="center" my={4}>
            <Skeleton size="5" rounded="full" />
            <Skeleton h="3" flex="2" rounded="full" />
          </HStack>
          <HStack space="2" alignItems="center" my={4}>
            <Skeleton size="5" rounded="full" />
            <Skeleton h="3" flex="2" rounded="full" />
          </HStack>
          <HStack space="2" alignItems="center" my={4}>
            <Skeleton size="5" rounded="full" />
            <Skeleton h="3" flex="2" rounded="full" />
          </HStack>
        </>
      )}
      {authors.length > 0 && !showSkeleton && (
        <FlatList
          width="90%"
          maxWidth="90%"
          mt={4}
          data={authors}
          renderItem={renderItem}
          keyExtractor={(item) => item.name}
          scrollEnabled={true}
          showsVerticalScrollIndicator={false}
        />
      )}
      {authors.length === 0 && !showSkeleton && (
        <>
          <Spacer />
          <Text>No author found by the name {searchTerm}</Text>
          <Text>Try writing full first or last name</Text>
          <Image
            source={require('./assets/broken_heart.png')}
            alt="Shrug"
            size={'lg'}
            alignSelf="center"
          />
          <Spacer />
        </>
      )}
    </VStack>
  );
}
