import { createSlice } from '@reduxjs/toolkit';

// possible values are 'GAME', 'QUOTE' to toggle between two modes.
export const gameModeStateSlice = createSlice({
  name: 'gameModeState',
  initialState: {
    value: null,
  },
  reducers: {
    setGameModeState: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setGameModeState } = gameModeStateSlice.actions;

export default gameModeStateSlice.reducer;
