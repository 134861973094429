import { extendTheme } from 'native-base';

const theme = extendTheme({
  fontConfig: {
    Lato: {
      100: {
        normal: 'Lato_100Thin',
        italic: 'Lato_100Thin_Italic',
      },
      200: {
        normal: 'Lato_300Light',
        italic: 'Lato_300Light_Italic',
      },
      300: {
        normal: 'Lato_300Light',
        italic: 'Lato_300Light_Italic',
      },
      400: {
        normal: 'Lato_400Regular',
        italic: 'Lato_400Regular_Italic',
      },
      500: {
        normal: 'Lato_400Regular',
        italic: 'Lato_400Regular_Italic',
      },
      600: {
        normal: 'Lato_400Regular',
        italic: 'Lato_400Regular_Italic',
      },
      700: {
        normal: 'Lato_400Regular',
        bold: 'Lato_700Bold',
        italic: 'Lato_700Bold_Italic',
      },
      800: {
        normal: 'Lato_400Regular',
        bold: 'Lato_700Bold',
        italic: 'Lato_700Bold_Italic',
      },
      900: {
        normal: 'Lato_900Black',
        italic: 'Lato_900Black_Italic',
      },
    },
    Indie: {
      100: {
        normal: 'IndieFlower',
      },
    },
  },
  components: {
    Toast: {
      baseStyle: {
        _title: {
          color: 'indigo.600',
          fontWeight: 700,
        },
        _description: {
          color: 'indigo.600',
          fontWeight: 400,
        },
      },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: 'Lato',
    body: 'Lato',
    quote: 'Indie',
  },
});

export default theme;